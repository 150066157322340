import { Layout } from 'antd';
import { useEffect } from 'react';

import { useNavigate } from 'react-router';

import { isMobile } from 'react-device-detect';

import { HtmlMeta } from '@/components/HtmlMeta';

import { openInNewTab } from '@/utils/helpers/app';

import { IPageBaseProps } from '@/interfaces';

interface IProps extends IPageBaseProps {}

export const SponsorChecklistFour: React.FC<IProps> = () => {
  const navigate = useNavigate();

  useEffect(() => {
    openInNewTab(
      'https://midnight-revolve-64c.notion.site/4-Integrate-More-Happi-into-team-ways-of-working-7038bfd35384444e8d1749be77fda345',
    );

    if (isMobile) return;

    return navigate(-1);
  }, []);

  return (
    <Layout>
      <HtmlMeta title="Onboarding Checklist - Integrate More Happi into team ways of working" />
    </Layout>
  );
};
