import { createUrl } from './createUrl';

export const getIframe = (ycmbUrl?: string, queryStringParams?: any) => {
  if (!ycmbUrl) return;

  const fullUrl = createUrl(ycmbUrl, queryStringParams || {});

  const iframe = `
    <iframe
      src='${ycmbUrl}'
      id='${fullUrl}'
      style='width:100%;height:1000px;border:0px;background-color:white'
      frameborder='0'
      allowtransparency='true'
      sandbox='allow-same-origin allow-top-navigation allow-scripts allow-forms'
    ></iframe>`;

  return iframe;
};
