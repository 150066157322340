import { Row, Col, Form, Input, Button, Space, Divider, message } from 'antd';
import ImgCrop from 'antd-img-crop';

import { useCompany } from '@/state/company';

import { IPageBaseProps } from '@/interfaces';

interface IProps extends IPageBaseProps {}

export const CompanyNameSettings = (props: IProps) => {
  const { currentCompany, update } = useCompany();

  const onUpdateCompany = async (values: any) => {
    try {
      update({
        name: values.name,
      });

      message.success('Company profile updated succesfully');
    } catch (err) {
      message.error('Failed to update company profile. Please try again');
    }
  };

  return (
    <Form
      layout="vertical"
      name="userInfo"
      onFinish={onUpdateCompany}
      initialValues={{
        name: currentCompany?.name,
      }}
    >
      <Form.Item name="name" label="Company Name">
        <Input size="large" placeholder="Company Name" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" size="large" block>
          Save changes
        </Button>
      </Form.Item>
    </Form>
  );
};
