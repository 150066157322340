export const ROUTE_PATHS = {
  LOGIN: '/auth/login',
  REGISTER: '/auth/register',
  VERIFY: '/auth/verify',
  RESEND_REGISTRATION_LINK: '/auth/resend-registration',
  RESET_PASSWORD: '/auth/reset-password',
  CHANGE_PASSWORD: '/auth/change-password',
  CONFIRM_RESET_PASSWORD: '/auth/confirm-password-reset',
  // Protected Routes
  DASHBOARD: '/',
  SUPPORT_GUIDES: {
    MAIN: '/support-guides',
    SPONSOR_CHECKLIST: {
      MAIN: '/support-guides/checklist',
      ONE: '/support-guides/checklist/step-one',
      TWO: '/support-guides/checklist/step-two',
      THREE: '/support-guides/checklist/step-three',
      FOUR: '/support-guides/checklist/step-four',
      FIVE: '/support-guides/checklist/step-five',
      SIX: '/support-guides/checklist/step-six',
    },
    WHAT_IS_COACHING: '/support-guides/what-is-coaching',
    HOW_TO_USE: '/support-guides/how-to-use',
    ONBOARDING_CALL: {
      LEADERS: '/support-guides/onboarding-call/leaders',
      LEADERS_CONFIRMED: '/support-guides/onboarding-call/leaders/confirmed',
      TEAM_MEMBERS: '/support-guides/onboarding-call/team-members',
      TEAM_MEMBERS_CONFIRMED:
        '/support-guides/onboarding-call/team-members/confirmed',
    },
  },
  TOOLS: {
    MAIN: '/tools',
    HABIT_TRACKER: '/tools/habit-tracker',
    GOAL_PLANNER: '/tools/goal-planner',
  },
  MY_ACCOUNT: '/my-account',
  CONTACT_US: '/contact',
  SESSIONS: {
    COACHES: '/coaches',
    COACH: '/coach/:coachHandle',
    BROWSE: '/book-session',
    NEXT_AVAILABLE: '/next-available-coach',
    CATEGORY: '/coaches/:category',
    WHERE_TO_START: '/where-to-start',
    BOOKING_SUCCESS: '/booking-confirmed',
  },
  USER: {
    SETTINGS: '/account',
  },
  COMPANY: {
    BILLING: '/company/billing',
    SETTINGS: '/company/settings',
    MANAGE_TEAM: '/company/manage-team',
    DATA: '/company/data',
  },
};
