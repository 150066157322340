import { Link } from 'react-router-dom';

import { useUser } from '@/state/user';
import { useCompany } from '@/state/company';
import { useCompanySubscription } from '@/state/company-subscription';

import { FlashNotice } from '@/components/FlashNotice';

export const SubscriptionNotice = () => {
  const user = useUser();
  const company = useCompany();
  const companySubscription = useCompanySubscription();

  const canRender =
    user.currentUser?.isAdmin &&
    !company?.currentCompany?.isEnterprise &&
    !company.isLoading;

  if (
    canRender &&
    !companySubscription.state.isActive &&
    !companySubscription.state.isCancelled
  ) {
    return (
      <FlashNotice>
        Hey there! Your team requires an active subscription to book unlimited
        coaching <Link to="/company/billing">Create Subscription</Link>
      </FlashNotice>
    );
  }

  if (canRender && companySubscription.state.isCancelled) {
    return (
      <FlashNotice>
        Your subscription has been cancelled. Your companies access to More
        Happi coaching ends on {companySubscription.state.cancellingAt}{' '}
        <Link to="/company/billing">Resume Subscription</Link>
      </FlashNotice>
    );
  }

  return null;
};
