import { isAfter } from 'date-fns';

import {
  CHARGEBEE_OLD_STARTER_PRICING_DATE,
  CHARGEBEE_ITEM_PRICE_ID_STARTER,
  CHARGEBEE_ITEM_PRICE_ID_STARTER_OLD,
} from '@/configs/app.config';

import {
  tablePricingData,
  tablePricingDataOld,
} from '@/utils/table_data/pricingTierTableInfo';

export const getItemPriceId = (company: any) => {
  return isAfter(
    new Date(company.createdAt),
    new Date(CHARGEBEE_OLD_STARTER_PRICING_DATE),
  )
    ? CHARGEBEE_ITEM_PRICE_ID_STARTER
    : CHARGEBEE_ITEM_PRICE_ID_STARTER_OLD;
};

export const getTablePricingData = (company: any) => {
  return isAfter(
    new Date(company.createdAt),
    new Date(CHARGEBEE_OLD_STARTER_PRICING_DATE),
  )
    ? tablePricingData
    : tablePricingDataOld;
};
