import Auth from '@aws-amplify/auth';
import cookieCutter from 'cookie-cutter';

import {
  JWT_LOCALSTORAGE_KEY,
  IDENTITY_LOCALSTORAGE_KEY,
} from '@/configs/app.config';

/**
 * Refreshes the JWT token if it has expired.
 *
 * returns a CognitoUserSession containing a new token
 */
export async function refreshToken() {
  try {
    const session = (await (Auth.currentSession() as unknown)) as {
      idToken: {
        jwtToken: string;
      };
    };

    cookieCutter.set(JWT_LOCALSTORAGE_KEY, session.idToken.jwtToken);

    return {
      token: session.idToken.jwtToken,
    };
  } catch (err) {
    cookieCutter.set(JWT_LOCALSTORAGE_KEY, '', { expires: new Date(0) });
    cookieCutter.set(IDENTITY_LOCALSTORAGE_KEY, '', {
      expires: new Date(0),
    });

    return {
      token: null,
    };
  }
}

export function getUserToken() {
  if (typeof window !== 'undefined') {
    return cookieCutter.get(JWT_LOCALSTORAGE_KEY);
  }

  return null;
}

export async function logoutUser() {
  if (typeof window !== 'undefined') {
    await Auth.signOut();
    cookieCutter.set(JWT_LOCALSTORAGE_KEY, '', { expires: new Date(0) });
    cookieCutter.set(IDENTITY_LOCALSTORAGE_KEY, '', {
      expires: new Date(0),
    });
    window.location.reload();
  }
}
