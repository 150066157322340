import { useState } from 'react';
import { Row, Col, Button, message, Modal, Form } from 'antd';

import { ManualInviteForm } from './ManualInviteForm';
import { CsvImport } from './CsvImport';

import {
  UserRole,
  useInviteTeamMembersMutation,
  CreateTeamMemberFromInviteInput,
} from '@/graphql/types.generated';

import { DATA_TEST_IDS } from '@/elements.config';

import { Mixpanel } from '@/utils/mixpanel';

interface IProps {
  companyId: string;
  isInviteModalOpen: boolean;
  onToggleInviteModel: () => void;
  refetchTeamMembers?: () => Promise<any>;
}

interface RawUser {
  email?: string;
  firstName?: string;
  lastName?: string;
  accountType?: string;
  isManager?: boolean;
}

export const InviteTeamMemberModal = ({
  companyId,
  isInviteModalOpen,
  onToggleInviteModel,
  refetchTeamMembers,
}: IProps) => {
  const [formType, setFormType] = useState('addEmails');
  const [manualInviteForm] = Form.useForm();

  const [csvData, setCsvData] = useState([]);
  const [csvError, setCsvError] = useState([]);
  const [csvInviteNumbers, setCsvInviteNumbers] = useState(0);

  const reset = () => {
    setCsvData([]);
    setCsvError([]);
    setCsvInviteNumbers(0);
  };

  const onClose = () => {
    reset();
    setFormType('addEmails');
    manualInviteForm.resetFields();
    onToggleInviteModel();
  };

  const inviteTeamMembers = useInviteTeamMembersMutation({
    onError: () => {
      message.error(
        'There was a problem inviting Humans to your team. Please contact support.',
      );

      return onClose();
    },
    onSuccess: (data, { input }) => {
      if (!data.inviteTeamMembers?.success || !data.inviteTeamMembers?.failed) {
        return onClose();
      }

      const { success, failed } = data.inviteTeamMembers;

      if (success.length > 0) {
        message.success(
          `${success.length} humans have been invited. They will receive a temporary password via email to setup their account.`,
        );
      }

      if (failed.length > 0) {
        message.error(`${failed.length} humans were not invited succesfully.`);
      }

      if (refetchTeamMembers) {
        refetchTeamMembers();
      }

      Mixpanel.track('Team Members Invited', {
        companyId: input.companyId,
        count: success,
      });

      if (input?.teamMembers?.length) {
        for (const teamMember of input.teamMembers) {
          Mixpanel.track('Team Member Invited', {
            companyId: input.companyId,
            ...teamMember,
          });
        }
      }

      return onClose();
    },
  });

  const formatInviteInput = (
    inviteData: RawUser[],
  ): CreateTeamMemberFromInviteInput[] => {
    // @ts-ignore
    return inviteData.map((rawUser: RawUser) => ({
      email: rawUser.email?.toLowerCase(),
      firstName: rawUser.firstName,
      lastName: rawUser.lastName,
      role: rawUser.accountType
        ? rawUser.accountType?.toLowerCase()
        : UserRole.TeamMember,
      isManager: !!rawUser.isManager,
    }));
  };

  const onSendInvites = async () => {
    setCsvError([]);

    if (formType === 'addEmails') {
      const values = await manualInviteForm.validateFields();

      if (values) {
        const teamMembers = formatInviteInput(csvData);

        setCsvInviteNumbers(teamMembers.length);

        inviteTeamMembers.mutate({
          input: {
            companyId,
            teamMembers: formatInviteInput(values.users),
          },
        });
      }
    }

    if (formType === 'importEmails') {
      if (csvData) {
        const teamMembers = formatInviteInput(csvData);

        setCsvInviteNumbers(teamMembers.length);

        inviteTeamMembers.mutate({
          input: {
            companyId,
            teamMembers,
          },
        });
      }
    }
  };

  return (
    <Modal
      centered
      visible={isInviteModalOpen}
      width="1000px"
      onOk={onSendInvites}
      onCancel={() => onClose()}
      okText={
        inviteTeamMembers.isLoading ? `Sending Invites...` : `Send Invites`
      }
      confirmLoading={inviteTeamMembers.isLoading}
      closable={!inviteTeamMembers.isLoading}
      destroyOnClose
    >
      <div data-cy={DATA_TEST_IDS.TEAMS.IMPORT.MODAL.BODY}>
        <Row gutter={16}>
          <Col span={18}>
            <h2>Invite Humans</h2>
            <p>
              Any additional Humans invited to the team will be reflected on the
              next bill.
            </p>
          </Col>

          <Col span={6}>
            {formType === 'addEmails' && (
              <Button
                shape="round"
                className="mr-md invite-team-members-btn"
                onClick={() => {
                  setFormType('importEmails');
                  reset();
                }}
                data-cy={DATA_TEST_IDS.TEAMS.IMPORT.MODAL.BUTTONS.CSV_IMPORT}
              >
                CSV Import
              </Button>
            )}
          </Col>
        </Row>

        <div
          style={{
            opacity: inviteTeamMembers.isLoading ? '0.5' : 'none',
            pointerEvents: inviteTeamMembers.isLoading ? 'none' : 'inherit',
          }}
        >
          <div className="mb-lg">
            <div className="mt-s">
              {formType === 'importEmails' ? (
                <CsvImport
                  setCsvData={setCsvData}
                  setCsvInviteNumbers={setCsvInviteNumbers}
                  csvData={csvData}
                  csvInviteNumbers={csvInviteNumbers}
                  csvError={csvError}
                  setCsvError={setCsvError}
                  reset={reset}
                />
              ) : (
                <ManualInviteForm manualInviteForm={manualInviteForm} />
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
