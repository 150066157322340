import React from 'react';
import { Layout, PageHeader, Space } from 'antd';

import { HtmlMeta } from '@/components/HtmlMeta';
import { UserEmailSettings } from '@/components/Settings/UserEmail';
import { UserNameSettings } from '@/components/Settings/UserName';
import { UserPasswordSettings } from '@/components/Settings/UserPassword';

import { useScrollTo } from '@/hooks';

import { IPageBaseProps } from '@/interfaces';
import { ROUTE_PATHS } from '@/configs/routes';
import { WEB_ASSETS_URL } from '@/configs/app.config';
import { useUser } from '@/state/user';

interface IProps extends IPageBaseProps {}

export const UserSettings: React.FC<IProps> = () => {
  useScrollTo();

  return (
    <Layout>
      <HtmlMeta title="Account Settings" />

      <Layout.Content className="site-layout-content thin">
        <PageHeader ghost className="site-page-header" title="Settings" />

        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <UserEmailSettings />
          <UserNameSettings />
          <UserPasswordSettings />
        </Space>
      </Layout.Content>
    </Layout>
  );
};
