/* eslint-disable max-len */

import { __env__ } from './env'

if (!process.env.REACT_APP_ENV) {
  throw new Error(
    'No environment variable set run with either development or production'
  )
}

export const JWT_LOCALSTORAGE_KEY =
  __env__.REACT_APP_ENV === 'development' ? 'mh_id_token_dev':'mh_id_token'
export const IDENTITY_LOCALSTORAGE_KEY =
  __env__.REACT_APP_ENV === 'development'
    ? 'mh_identity_id_dev'
    :'mh_identity_id'

export const WEB_ASSETS_URL = `https://d2re4krkd7ad9u.cloudfront.net/web`

export const CHARGEBEE_OLD_STARTER_PRICING_DATE = '2022-04-02 00:00:00.000'
export const CHARGEBEE_ITEM_PRICE_ID_STARTER =
  __env__.REACT_APP_ENV === 'development'
    ? 'New-plan-GBP-Monthly'
    :'2022-Monthly-GBP-Monthly'
export const CHARGEBEE_ITEM_PRICE_ID_STARTER_OLD =
  __env__.REACT_APP_ENV === 'development'
    ? 'Monthly-GBP-Monthly'
    :'More-Happi-Humans-GBP-Monthly'

export const app = {
  __DEV__: process.env.NODE_ENV !== 'production',
  //
  ENV: __env__.REACT_APP_ENV,
  PAGINATION_COUNT_TAKE_DEFAULT: 10000,
  API_BASE_URL: __env__.REACT_APP_API_BASE_URL,
  GOOGLE_ANALYTICS_ID: __env__.REACT_APP_GOOGLE_ANALYTICS_ID,
  GOOGLE_OPTIMIZE_ID: __env__.REACT_APP_GOOGLE_OPTIMIZE_ID,
  INTERCOM_APP_ID: __env__.REACT_APP_INTERCOM_APP_ID,
  JWT_LOCALSTORAGE_KEY,
  IDENTITY_LOCALSTORAGE_KEY,
  COACHING_URLS: {
    RELATIONSHIPS: process.env.REACT_APP_RELATIONSHIPS_URL,
    PURPOSE: process.env.REACT_APP_PURPOSE_URL,
    SELF: process.env.REACT_APP_SELF_URL,
    PARENTING: process.env.REACT_APP_PARENTING_URL,
    WORK: process.env.REACT_APP_WORK_URL,
    NEXT_AVAILABLE: process.env.REACT_APP_NEXT_AVAILABLE_URL
  },
  NEXT_AVAILABLE_URL: process.env.REACT_APP_NEXT_AVAILABLE_URL,
  CLOUDFRONT: {
    USER_ASSETS: process.env.REACT_APP_USER_ASSETS_CLOUDFRONT
  }
}
