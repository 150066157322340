import { Navigate, useLocation } from 'react-router-dom';

import { MasterLayout } from '@/layouts/MasterLayout';
import { PublicLayout } from '@/layouts/PublicLayout';

import { useAuth } from '@/state/auth';

import { ROUTE_PATHS } from '@/configs/routes';

const PrivateRoute = ({ children }: any) => {
  const auth = useAuth();

  if (auth.state.isAuthenticated) {
    return <MasterLayout>{children}</MasterLayout>;
  }

  return <Navigate to={ROUTE_PATHS.LOGIN} />;
};

const PublicRoute = ({ children }: any) => {
  const auth = useAuth();

  const location = useLocation();

  if (auth.state.isAuthenticated) {
    return <Navigate to={ROUTE_PATHS.DASHBOARD} state={{ from: location }} />;
  }

  return <PublicLayout>{children}</PublicLayout>;
};

export { PrivateRoute, PublicRoute };
