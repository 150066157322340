import React, { useEffect } from 'react';
import { Layout, Menu, Image, Divider, Button, Drawer } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import {
  MenuOutlined,
  CommentOutlined,
  ClockCircleOutlined,
  HomeOutlined,
  RightCircleOutlined,
  SolutionOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { useUser } from '@/state/user';
import { useCompany } from '@/state/company';
import { ROUTE_PATHS } from '@/configs/routes';
import { WEB_ASSETS_URL } from '@/configs/app.config';
import { AccountMenu } from '@/components/ContextMenus/Account';
import { useIsMobile } from '@/hooks';

const { Header } = Layout;

const BrowseIcon = () => (
  <span role="img" className="anticon anticon-solution ant-menu-item-icon">
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.62988 17.4375C14.2898 17.4375 18.0674 13.6599 18.0674 9C18.0674 4.3401 14.2898 0.5625 9.62988 0.5625C4.96998 0.5625 1.19238 4.3401 1.19238 9C1.19238 13.6599 4.96998 17.4375 9.62988 17.4375Z"
        fill="#F7C421"
      />
      <path
        d="M14.1299 9H5.12988M9.62988 4.5V13.5V4.5Z"
        stroke="#1E1E1E"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

const ContactIcon = () => (
  <span role="img" className="anticon anticon-solution ant-menu-item-icon">
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.61728 10.0967C8.48672 10.5573 8.46482 11.0419 8.55331 11.5123C8.6418 11.9828 8.83826 12.4263 9.12723 12.808C9.4162 13.1896 9.7898 13.499 10.2186 13.7118C10.6475 13.9246 11.1198 14.035 11.5985 14.0342H13.2579L15.8173 16.5936V13.9217C16.5398 13.7214 17.165 13.2656 17.5766 12.6389C17.9882 12.0123 18.1582 11.2574 18.0551 10.5148C17.9519 9.77221 17.5826 9.09231 17.0158 8.60155C16.449 8.11079 15.7233 7.84255 14.9735 7.84673H14.7767"
        fill="#769BB9"
      />
      <path
        d="M11.0361 1.65918H5.41113C4.42177 1.66686 3.46658 2.02201 2.71254 2.66256C1.9585 3.30311 1.45358 4.1883 1.28603 5.1634C1.11849 6.1385 1.29899 7.14146 1.79598 7.99696C2.29297 8.85247 3.07482 9.50608 4.00488 9.84355V13.1904L7.09863 10.0967H11.0361C12.155 10.0967 13.2281 9.65221 14.0192 8.86104C14.8104 8.06987 15.2549 6.99681 15.2549 5.87793C15.2549 4.75905 14.8104 3.68599 14.0192 2.89482C13.2281 2.10365 12.155 1.65918 11.0361 1.65918Z"
        fill="#3A5276"
      />
    </svg>
  </span>
);

const GuidesIcon = () => (
  <span role="img" className="anticon anticon-solution ant-menu-item-icon">
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.72363 0.5625V17.4375H15.5361V5.0625L11.0361 0.5625H3.72363Z"
        fill="white"
      />
      <path d="M11.0361 0.5625V5.0625H15.5361" fill="#769BB9" />
      <path
        d="M5.87988 9.5625H13.3799M5.87988 11.8125H13.3799M5.87988 14.0625H9.25488"
        stroke="#1E1E1E"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

const ToolsIcon = () => (
  <span role="img" className="anticon anticon-solution ant-menu-item-icon">
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.87988 3.9375C3.81186 3.9375 4.56738 3.18198 4.56738 2.25C4.56738 1.31802 3.81186 0.5625 2.87988 0.5625C1.9479 0.5625 1.19238 1.31802 1.19238 2.25C1.19238 3.18198 1.9479 3.9375 2.87988 3.9375Z"
        fill="#F7C421"
        stroke="#F7C421"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3799 3.9375C17.3119 3.9375 18.0674 3.18198 18.0674 2.25C18.0674 1.31802 17.3119 0.5625 16.3799 0.5625C15.4479 0.5625 14.6924 1.31802 14.6924 2.25C14.6924 3.18198 15.4479 3.9375 16.3799 3.9375Z"
        fill="#F65800"
        stroke="#F65800"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.62988 3.9375C10.5619 3.9375 11.3174 3.18198 11.3174 2.25C11.3174 1.31802 10.5619 0.5625 9.62988 0.5625C8.6979 0.5625 7.94238 1.31802 7.94238 2.25C7.94238 3.18198 8.6979 3.9375 9.62988 3.9375Z"
        fill="#F7A000"
        stroke="#F7A000"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.87988 17.4375C3.81186 17.4375 4.56738 16.682 4.56738 15.75C4.56738 14.818 3.81186 14.0625 2.87988 14.0625C1.9479 14.0625 1.19238 14.818 1.19238 15.75C1.19238 16.682 1.9479 17.4375 2.87988 17.4375Z"
        fill="#3A5276"
        stroke="#3A5276"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3799 17.4375C17.3119 17.4375 18.0674 16.682 18.0674 15.75C18.0674 14.818 17.3119 14.0625 16.3799 14.0625C15.4479 14.0625 14.6924 14.818 14.6924 15.75C14.6924 16.682 15.4479 17.4375 16.3799 17.4375Z"
        fill="#93B31B"
        stroke="#93B31B"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.62988 17.4375C10.5619 17.4375 11.3174 16.682 11.3174 15.75C11.3174 14.818 10.5619 14.0625 9.62988 14.0625C8.6979 14.0625 7.94238 14.818 7.94238 15.75C7.94238 16.682 8.6979 17.4375 9.62988 17.4375Z"
        fill="#769BB9"
        stroke="#769BB9"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.87988 10.6875C3.81186 10.6875 4.56738 9.93198 4.56738 9C4.56738 8.06802 3.81186 7.3125 2.87988 7.3125C1.9479 7.3125 1.19238 8.06802 1.19238 9C1.19238 9.93198 1.9479 10.6875 2.87988 10.6875Z"
        fill="#FF9394"
        stroke="#FF9394"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3799 10.6875C17.3119 10.6875 18.0674 9.93198 18.0674 9C18.0674 8.06802 17.3119 7.3125 16.3799 7.3125C15.4479 7.3125 14.6924 8.06802 14.6924 9C14.6924 9.93198 15.4479 10.6875 16.3799 10.6875Z"
        fill="#006A39"
        stroke="#006A39"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.62988 10.6875C10.5619 10.6875 11.3174 9.93198 11.3174 9C11.3174 8.06802 10.5619 7.3125 9.62988 7.3125C8.6979 7.3125 7.94238 8.06802 7.94238 9C7.94238 9.93198 8.6979 10.6875 9.62988 10.6875Z"
        fill="#93541C"
        stroke="#93541C"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

const MainMenu = () => (
  <>
    <Menu.Item key="1" icon={<BrowseIcon />}>
      <NavLink to={ROUTE_PATHS.SESSIONS.BROWSE}>Book a session</NavLink>
    </Menu.Item>
    <Menu.Item key="2" icon={<ToolsIcon />}>
      <NavLink to={ROUTE_PATHS.TOOLS.MAIN}>Tools</NavLink>
    </Menu.Item>
    <Menu.Item key="3" icon={<GuidesIcon />}>
      <NavLink to={ROUTE_PATHS.SUPPORT_GUIDES.MAIN}>Support Guides</NavLink>
    </Menu.Item>
    <Menu.Item key="4" icon={<ContactIcon />}>
      <NavLink to={ROUTE_PATHS.CONTACT_US}>Contact Us</NavLink>
    </Menu.Item>
  </>
);

const MobileMenu = () => {
  const [open, setOpen] = React.useState(false);
  const location = useLocation();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen(false);

    if (window) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <>
      <MenuOutlined onClick={showDrawer} />
      <Drawer
        title={
          <Image
            className="logo"
            height={64}
            src={`${WEB_ASSETS_URL}/More_Happi_logo.svg`}
            alt="MoreHappi"
            preview={false}
          />
        }
        width="100%"
        placement="right"
        onClose={onClose}
        visible={open}
      >
        <Menu
          defaultSelectedKeys={[]}
          mode="vertical"
          theme="light"
          className="mh-menu"
          disabledOverflow
        >
          <MainMenu />
          <Divider />
          <AccountMenu mobile />
        </Menu>
      </Drawer>
    </>
  );
};

const NonMobileMenu = () => (
  <>
    <Menu
      defaultSelectedKeys={[]}
      mode="horizontal"
      theme="light"
      className="mh-menu"
      disabledOverflow
    >
      <MainMenu />
    </Menu>

    <AccountMenu />
  </>
);

export const Navigation = () => {
  const user = useUser();
  const company = useCompany();
  const isMobile = useIsMobile();

  return (
    <Header className="mh-nav-header">
      <NavLink to="/" className="mh-nav-logo">
        <Image
          className="logo"
          height={64}
          src={`${WEB_ASSETS_URL}/More_Happi_logo.svg`}
          alt="MoreHappi"
          preview={false}
        />
      </NavLink>
      {isMobile ? <MobileMenu /> : <NonMobileMenu />}
    </Header>
  );
};
