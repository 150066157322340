const TierPrice = ({ value }: { value: number }) => {
  <div className="pricingTierTdContainer">
    <p className="pricingTierTableInfoPricing">£{value} pp</p>
  </div>;
};

export const tablePricingData = [
  {
    tier1: (
      <span>
        £32.50
        <br />
        pp
      </span>
    ),
    tier2: (
      <span>
        £24.50
        <br />
        pp
      </span>
    ),
    tier3: (
      <span>
        £16.50
        <br />
        pp
      </span>
    ),
    tier4: (
      <span>
        £14.99
        <br />
        pp
      </span>
    ),
    tier5: (
      <span>
        £13.50
        <br />
        pp
      </span>
    ),
    tier6: (
      <span>
        £12.49
        <br />
        pp
      </span>
    ),
    tier7: (
      <span>
        £10.99
        <br />
        pp
      </span>
    ),
    key: '1',
  },
];

export const tablePricingDataOld = [
  {
    tier1: (
      <span>
        £32.50
        <br />
        pp
      </span>
    ),
    tier2: (
      <span>
        £24.50
        <br />
        pp
      </span>
    ),
    tier3: (
      <span>
        £16.50
        <br />
        pp
      </span>
    ),
    tier4: (
      <span>
        £10.86
        <br />
        pp
      </span>
    ),
    tier5: (
      <span>
        £8.05
        <br />
        pp
      </span>
    ),
    tier6: (
      <span>
        £7.90
        <br />
        pp
      </span>
    ),
    tier7: (
      <span>
        £5.90
        <br />
        pp
      </span>
    ),
    key: '2',
  },
];

const Title = ({ tier, range }: any) => (
  <div className="pricingTierTableInfoHeader">
    {tier} <br />
    <span style={{ fontWeight: '400', fontSize: '13px' }}>{range}</span>
  </div>
);

export const pricingTierTableColumns = [
  {
    title: () => <Title tier="Tier 1" range="(1 - 3)" />,
    dataIndex: 'tier1',
    key: 'tier1',
  },
  {
    title: () => <Title tier="Tier 2" range="(4 - 10)" />,
    dataIndex: 'tier2',
    key: 'tier2',
  },
  {
    title: () => <Title tier="Tier 3" range="(11 - 25)" />,
    dataIndex: 'tier3',
    key: 'tier 3',
  },
  {
    title: () => <Title tier="Tier 4" range="(26 - 50)" />,
    dataIndex: 'tier4',
    key: 'tier 4',
  },
  {
    title: () => <Title tier="Tier 5" range="(51 - 100)" />,
    dataIndex: 'tier5',
    key: 'tier 5',
  },
  {
    title: () => <Title tier="Tier 6" range="(101 - 200)" />,
    dataIndex: 'tier6',
    key: 'tier 5',
  },
  {
    title: () => <Title tier="Tier 7" range="(200+)" />,
    dataIndex: 'tier7',
    key: 'tier 5',
  },
];
