import React, { useState, useEffect } from 'react';
import { Auth } from '@aws-amplify/auth';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import qs from 'query-string';

import { Layout, Image, Input, Form, Button, Typography, Space } from 'antd';

import { useAuth } from '@/state/auth';

import { HtmlMeta } from '@/components/HtmlMeta';
import { Message } from '@/components/Message';

import { DATA_TEST_IDS } from '@/elements.config';

import { WEB_ASSETS_URL } from '@/configs/app.config';

import { Mixpanel } from '@/utils/mixpanel';

/**
 * Form Validation Schema
 */
type FormInputs = {
  password: string;
};

const defaultValues = {
  password: '',
};

export const ChangePasswordPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const query = qs.parse(location.search);

  const auth = useAuth();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [cognitoUser, setCognitoUser] = useState(null);

  const emailFromQuery = decodeURIComponent(String(query.email));
  const tempPasswordFromQuery = String(query.tempPassword);

  useEffect(() => {
    if (!emailFromQuery || !tempPasswordFromQuery) {
      return navigate('/auth/login');
    }

    if (emailFromQuery && tempPasswordFromQuery) {
      const getUser = async () => {
        try {
          const user = await Auth.signIn(
            emailFromQuery.toLowerCase(),
            tempPasswordFromQuery,
          );

          if (!user) {
            throw new Error(
              'No account exists please request an invite from your company admin.',
            );
          }

          setCognitoUser(user);
        } catch (err: any) {
          setError(err.message);
        }
      };

      if (!cognitoUser) {
        getUser();
      }
    }
  }, [query, cognitoUser]);

  const onSubmit = async (values: FormInputs) => {
    setError(null);

    if (!emailFromQuery) {
      navigate('/auth/reset-password');
      return;
    }

    try {
      setLoading(true);

      if (!cognitoUser) {
        return;
      }

      const user = await Auth.completeNewPassword(
        cognitoUser,
        values.password,
        {},
      );

      Mixpanel.track('Password Changed');

      if (!user) {
        throw new Error('Could not update password. Please try again');
      }

      auth.initializeUser();

      setLoading(false);

      window.location.reload();
    } catch (err: any) {
      // @ts-ignore
      setError(err.message);
      setLoading(false);
    }
  };

  const onSubmitFailed = (err: any) => setError(err.message);

  return (
    <Layout className="mh-layout-public">
      <HtmlMeta title="Reset Password" />

      <Layout.Content>
        <div className="site-login-content">
          <div className="login-wrap">
            <Image
              className="login-img"
              preview={false}
              src={`${WEB_ASSETS_URL}/More_Happi_logo.svg`}
              alt="logo"
            />

            <Space direction="vertical" size={32}>
              <Typography.Title level={1}>
                Change your password
              </Typography.Title>

              <Typography.Paragraph>
                Please use a secure new password and continue to your account.
              </Typography.Paragraph>

              <div className="auth-form-wrap">
                <Form
                  name="changePasswordForm"
                  initialValues={defaultValues}
                  layout="vertical"
                  onFinish={(values) => onSubmit(values)}
                  onFinishFailed={onSubmitFailed}
                >
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter a password',
                      },
                      {
                        min: 8,
                        message: 'Minimum of 8 characters',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const passwordFromInput = getFieldValue('password');

                          if (!passwordFromInput.match(/(?=.*\d)/)) {
                            return Promise.reject(
                              new Error('Contains atleast one number'),
                            );
                          }

                          if (!passwordFromInput.match(/(?=.*[A-Z])/)) {
                            return Promise.reject(
                              new Error(
                                'Contains atleast one uppercase character',
                              ),
                            );
                          }

                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input.Password data-cy={DATA_TEST_IDS.LOGIN.EMAIL.INPUT} />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={loading}
                      data-cy={DATA_TEST_IDS.LOGIN.SUBMIT.BUTTON}
                    >
                      {loading ? 'Updating...' : 'Change Password'}
                    </Button>
                  </Form.Item>
                </Form>

                {error && <Message type="error" items={[{ message: error }]} />}

                <Link to="/auth/login">Return to Sign In</Link>
              </div>
            </Space>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  );
};
