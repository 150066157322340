import { Row, Col, Form, Input, Typography } from 'antd';

import { useUser } from '@/state/user';

import { IPageBaseProps } from '@/interfaces';

interface IProps extends IPageBaseProps {}

const { Title } = Typography;

export const UserEmailSettings = (props: IProps) => {
  const user = useUser();

  return (
    <>
      <Title level={2}>Update Email</Title>

      <Form
        layout="vertical"
        name="userInfo"
        initialValues={{
          email: user?.currentUser?.email,
        }}
      >
        <Form.Item name="email" label="Email">
          <Input size="large" placeholder="Email" disabled />
        </Form.Item>
      </Form>
    </>
  );
};
