import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams, Link } from 'react-router-dom';
import { Layout, PageHeader } from 'antd';
import { decode } from 'html-entities';

import { useScrollTo } from '@/hooks';

import { HtmlMeta } from '@/components/HtmlMeta';
import { Iframe } from '@/components/Iframe';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { CoachAvatar } from '@/components/CoachAvatar';
import { CoachBio } from '@/components/CoachBio';
import { CoachAvailability } from '@/components/CoachAvailability';

import { useUser } from '@/state/user';
import { useCompany } from '@/state/company';
import { useCompanySubscription } from '@/state/company-subscription';

import { getYouCanBookMeParams } from '@/utils/getYouCanBookMeParams';
import { createUrl } from '@/utils/createUrl';

import { IPageBaseProps } from '@/interfaces';

import { useGetCoachQuery } from '@/graphql/types.generated';

import { Mixpanel } from '@/utils/mixpanel';

import styles from './styles.module.less';
import { BackIcon } from '@/icons';

interface IProps extends IPageBaseProps {}

const getIframe = (ycmbUrl: string, queryStringParams: any) => {
  const formattedUrl = decodeURIComponent(ycmbUrl);
  const fullUrl = createUrl(formattedUrl, queryStringParams);

  const iframe = `
    <iframe
      src='${fullUrl}'
      id='${formattedUrl}'
      style='width:100%;height:1000px;border:0px;background-color:white'
      frameborder='0'
      allowtransparency='true'
      sandbox='allow-same-origin allow-top-navigation allow-scripts allow-forms'
    ></iframe>`;

  return iframe;
};

export const Coach: React.FC<IProps> = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { coachHandle } = params;

  useScrollTo();

  const user = useUser();
  const company = useCompany();
  const companySubscription = useCompanySubscription();

  const canBookSession =
    companySubscription.state.isActive || company.currentCompany?.isEnterprise;

  const getCoachQuery = useGetCoachQuery({
    where: { handle: coachHandle },
  });

  useEffect(() => {
    if (!coachHandle || getCoachQuery.error) {
      return navigate('/coaches');
    }

    if (getCoachQuery?.data?.getCoach) {
      Mixpanel.track('Coach Profile Viewed', {
        handle: coachHandle,
      });
    }
  }, [coachHandle, getCoachQuery]);

  const ycbmParams = getYouCanBookMeParams({
    fullName: user?.currentUser?.fullName,
    companyName: company?.currentCompany?.name,
    email: user?.currentUser?.email,
  });

  const coach = getCoachQuery?.data?.getCoach;

  return (
    <Layout>
      <HtmlMeta title="Coach" />

      <Layout.Content className="site-layout-content">
        {getCoachQuery.isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <PageHeader
              ghost
              className="site-page-header"
              onBack={() => navigate('/coaches')}
              backIcon={<BackIcon style={{ fontSize: '40px' }} />}
              title="Book a session"
            />
            <div className={styles['coach-header']}>
              <div className={styles['coach-image-container']}>
                <CoachAvatar
                  imageKey={coach?.avatarKey}
                  displayName={coach?.displayName}
                />
              </div>
              <div className={styles['coach-headline-container']}>
                <h2>{coach?.displayName}</h2>
                <h4>{decode(coach?.headline)}</h4>
                {coach?.bio && <CoachBio bio={coach?.bio} />}
              </div>
            </div>
            {canBookSession ? (
              coach?.ycmbUrl && (
                <Iframe iframe={getIframe(coach.ycmbUrl, ycbmParams)} />
              )
            ) : (
              <div>
                <p>
                  Hey there! Your team requires an active subscription to book
                  unlimited coaching{' '}
                  <Link to="/company/billing">Create Subscription</Link>
                </p>
              </div>
            )}
            {/* {coach && <CoachAvailability coachId={coach.id}  />} */}
          </>
        )}
      </Layout.Content>
    </Layout>
  );
};
