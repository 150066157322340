import { useLayoutEffect } from 'react';

export const useScrollTo = () => {
  return useLayoutEffect(() => {
    // @ts-ignore
    document
      .querySelector('body')
      .scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
};
