import React from 'react';

import { Popover, Space, Button, notification } from 'antd';

import { useUser } from '@/state/user';

import { ICompBaseProps } from '@/interfaces';

import {
  UserRole,
  TeamMember,
  useResendTeamMemberInviteMutation,
} from '@/graphql/types.generated';

import { Mixpanel } from '@/utils/mixpanel';

interface IProps extends ICompBaseProps {
  teamMember: TeamMember;
  onRemoveTeamMember: (teamMember: TeamMember) => void;
  onChangeSponsor: (teamMember: TeamMember) => void;
}

export const TeamListActions: React.FC<IProps> = ({
  teamMember,
  onChangeSponsor,
  onRemoveTeamMember,
}) => {
  const user = useUser();

  const openNotification = () => {
    const args = {
      message: 'Temporary Password Sent',
      description: `An email is on its way to ${teamMember?.user?.email}. Please be aware that it may take a minute for the invitation to arrive. `,
      duration: 5,
      className: 'notification',
    };

    Mixpanel.track('Team Member Invite Resent');

    notification.open(args);
  };

  const resendTeamMemberInviteMutation = useResendTeamMemberInviteMutation({
    onSuccess: openNotification,
  });

  const canRemove =
    user?.currentUser?.id !== teamMember?.user?.id &&
    user?.currentUser?.isAdmin;

  const menu = (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {user.currentUser?.isSponsor && (
        <Button
          type="link"
          disabled={teamMember?.user?.role === UserRole.Sponsor}
          onClick={() => onChangeSponsor(teamMember)}
        >
          Make Sponsor
        </Button>
      )}
      <Button
        type="link"
        disabled={
          teamMember?.user?.role === UserRole.Sponsor ||
          teamMember?.user?.isActive ||
          teamMember.archivedAt
        }
        onClick={() => {
          resendTeamMemberInviteMutation.mutate({
            teamMemberId: teamMember.id,
          });
        }}
      >
        Re-send Invite
      </Button>
      <Button
        type="link"
        onClick={() => onRemoveTeamMember(teamMember)}
        disabled={!canRemove}
      >
        Remove
      </Button>
    </div>
  );

  return (
    <Space size="middle">
      <Popover
        content={menu}
        title={`${teamMember?.user?.firstName} ${teamMember?.user?.lastName}`}
      >
        <Button>Action</Button>
      </Popover>
    </Space>
  );
};
