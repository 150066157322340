import {
  createContext,
  useContext,
  useCallback,
  useMemo,
  useReducer,
} from 'react';

import {
  companySubscriptionReducer,
  CompanySubscriptionReducerAction,
  CompanySubscriptionReducerActionType,
  CompanySubscriptionState,
  initialState,
} from './company-subscription-reducer';

type CompanySubscriptionContextValue = [
  CompanySubscriptionState,
  React.Dispatch<CompanySubscriptionReducerAction>,
];

const CompanySubscriptionContext =
  createContext<CompanySubscriptionContextValue | null>(null);

const CompanySubscriptionProvider: React.FC = (props) => {
  const [state, dispatch] = useReducer(
    companySubscriptionReducer,
    initialState,
  );

  const value = useMemo(
    () => [state, dispatch],
    [state],
  ) as CompanySubscriptionContextValue;

  return <CompanySubscriptionContext.Provider value={value} {...props} />;
};

function useCompanySubscription() {
  const context = useContext(CompanySubscriptionContext);

  if (!context) {
    throw new Error(
      'useCompanySubscription must be used within an CompanySubscriptionProvider',
    );
  }

  const [state, dispatch] = context;

  const toggleUpdatingSubscription = useCallback(
    () =>
      dispatch({
        type: CompanySubscriptionReducerActionType.setUpdatingSubscription,
      }),
    [dispatch],
  );

  const setSubscriptionStatus = useCallback(
    ({
      active,
      status,
      cancellingAt,
    }: {
      active: boolean;
      status?: string;
      cancellingAt?: Date;
    }) => {
      return dispatch({
        type: CompanySubscriptionReducerActionType.updateSubscriptionStatus,
        status,
        active,
        cancellingAt,
      });
    },
    [dispatch],
  );

  const getChargebeeInstance = useCallback(() => {
    if ((window as any).Chargebee) {
      const chargebeeInstance = (window as any).Chargebee.getInstance();

      return chargebeeInstance;
    }
  }, []);

  return {
    state,
    dispatch,
    toggleUpdatingSubscription,
    setSubscriptionStatus,
    getChargebeeInstance,
  };
}

export { CompanySubscriptionProvider, useCompanySubscription };
