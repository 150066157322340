import { createContext, useContext, useEffect } from 'react';
import { format } from 'date-fns';

import { useQueryClient } from '@tanstack/react-query';

import { useUser } from '@/state/user';
import { useCompanySubscription } from '@/state/company-subscription';

import {
  useGetCompanyQuery,
  GetCompanyQuery,
  useUpdateCompanyMutation,
  useUpdateCompanyAdminMutation,
  CompanyUpdateInput,
  CompanyAdminUpdateInput,
  AccountType,
} from '@/graphql/types.generated';

import { Mixpanel } from '@/utils/mixpanel';

export type CurrentCompany = GetCompanyQuery['getCompany'] & {
  isEnterprise: boolean;
};

export enum CompanyFeaturePermissions {
  coachDirectory = 'coachDirectory',
}

export type CompanyPermissions = {
  coachDirectory: boolean;
};

export interface ICompanyContext {
  isLoading: boolean;
  isError: boolean;
  currentCompany?: CurrentCompany | null;
  update: any;
  updateAdmin: any;
  permissions: CompanyPermissions;
}

const CompanyContext = createContext<ICompanyContext>({
  isLoading: false,
  isError: false,
  currentCompany: undefined,
  update: undefined,
  updateAdmin: undefined,
  permissions: {
    coachDirectory: true,
  },
});

const withSelectors = (company: GetCompanyQuery['getCompany']) => {
  return {
    ...company,
    isEnterprise:
      company?.accountType === AccountType.Enterprise ||
      company?.accountType === AccountType.EnterpriseTrial,
  };
};

export const CompanyProvider = ({ children }: any) => {
  const queryClient = useQueryClient();

  const user = useUser();
  const companySubscription = useCompanySubscription();

  const where = {
    id: user.currentUser?.teamMember?.companyId || '',
  };

  const { isLoading, isError, data } = useGetCompanyQuery(
    { where },
    {
      enabled: !!user.currentUser?.teamMember?.companyId,
    },
  );

  const updateCompany = useUpdateCompanyMutation({
    onSuccess: () => {
      Mixpanel.track('Company Updated');

      return queryClient.invalidateQueries(['GetCompany', where]);
    },
  });

  const updateCompanyAdmin = useUpdateCompanyAdminMutation({
    onSuccess: () => {
      Mixpanel.track('Company Admin Updated');

      return queryClient.invalidateQueries(['GetCompany', where]);
    },
  });

  const update = (input: CompanyUpdateInput) =>
    updateCompany.mutate({
      where: { id: data?.getCompany?.id },
      data: input,
    });

  const updateAdmin = (input: CompanyAdminUpdateInput) =>
    updateCompanyAdmin.mutate({
      where: { companyId: data?.getCompany?.id },
      data: input,
    });

  useEffect(() => {
    if (data?.getCompany?.companyAdmin) {
      const {
        subscriptionActive,
        chargebeeSubscriptionStatus,
        chargebeeCurrentTermEnd,
      } = data.getCompany.companyAdmin;

      const isFree =
        data?.getCompany.accountType === 'free' && !subscriptionActive;
      const isEnterprise = data?.getCompany.accountType === 'enterprise';

      if (isFree) {
        return companySubscription.setSubscriptionStatus({
          active: false,
          status: undefined,
        });
      }

      // If the company is enterprise then there subscription is always active
      if (isEnterprise) {
        return companySubscription.setSubscriptionStatus({
          active: true,
          status: 'active',
        });
      }

      const isSubscriptionActive =
        subscriptionActive || chargebeeSubscriptionStatus === 'active';

      const cancellingAt =
        chargebeeCurrentTermEnd &&
        format(new Date(chargebeeCurrentTermEnd), 'MM/dd/yyyy');

      return companySubscription.setSubscriptionStatus({
        active: isSubscriptionActive,
        status: String(chargebeeSubscriptionStatus),
        cancellingAt,
      });
    }
  }, [data]);

  const currentCompany =
    data?.getCompany && (withSelectors(data?.getCompany) as CurrentCompany);

  const getPermissions = (): CompanyPermissions => {
    if (!currentCompany) {
      return {
        coachDirectory: true,
      };
    }

    return {
      coachDirectory: true,
    };
  };

  return (
    <CompanyContext.Provider
      value={{
        isLoading,
        isError,
        currentCompany,
        update,
        updateAdmin,
        permissions: getPermissions(),
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

export const useCompany = () => useContext(CompanyContext);
