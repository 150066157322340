import { __env__ } from './env';

const REGION = 'eu-west-1';

export const awsAuthConfig = {
  mandatorySignIn: false,
  region: REGION,
  userPoolId: process.env.REACT_APP_USERPOOL_ID,
  identityPoolId: process.env.REACT_APP_USERPOOL_IDENTITY_ID,
  userPoolWebClientId: process.env.REACT_APP_USERPOOL_CLIENT_ID,
};
