import { useState, useEffect } from 'react';
import ReactGA from 'react-ga';

import { pageView, initGA, initOptimize } from '@/utils/analytics';
import { useLocation } from 'react-router';

export const usePageTracking = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      initGA();
      initOptimize();
      // The very 1st time the page loads GA will call the function below
      // sending the very first page view, as otherwise React rendering is quicker than GA, and it doesn't send that pageview
      // All other pageviews are dealt outside of the function below. See docs:
      // https://developers.google.com/analytics/devguides/collection/analyticsjs/command-queue-reference#ready-callback
      ReactGA.ga(() => {
        pageView(location.pathname);
      });
    }

    setInitialized(true);
  }, [location]);

  useEffect(() => {
    if (typeof window !== 'undefined' && initialized) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [initialized, location]);
};
