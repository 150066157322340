import { useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import {
  Layout,
  Image,
  Input,
  Form,
  Button,
  Checkbox,
  Typography,
  Space,
} from 'antd';

import { HtmlMeta } from '@/components/HtmlMeta';
import { Message } from '@/components/Message';

import { DATA_TEST_IDS } from '@/elements.config';

import { WEB_ASSETS_URL } from '@/configs/app.config';

import { Mixpanel } from '@/utils/mixpanel';

/**
 * Form Validation Schema
 */
type FormInputs = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  companyName: string;
};

const defaultValues = {
  email: '',
  firstName: '',
  lastName: '',
  password: '',
  companyName: '',
};

export const Register = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const onSubmitRegistration = async (values: FormInputs) => {
    setError(null);

    try {
      setLoading(true);

      const email = values.email?.trim().toLowerCase();

      await Auth.signUp({
        username: email,
        password: values.password,
        attributes: {
          email,
          given_name: values.firstName,
          family_name: values.lastName,
          'custom:companyName': values.companyName,
          'custom:role': 'admin',
        },
      });

      Mixpanel.track('Company Created', {
        companyName: values.companyName,
      });

      setLoading(false);

      return navigate(`/auth/verify?email=${email}`);
    } catch (err: any) {
      setError(err.message);

      return setLoading(false);
    }
  };

  const onSubmitRegistrationFailed = (err: any) => setError(err.message);

  return (
    <Layout className="mh-layout-public">
      <HtmlMeta title="Sign Up" />

      <Layout.Content>
        <div className="site-login-content">
          <div className="login-wrap">
            <Image
              className="login-img"
              preview={false}
              src={`${WEB_ASSETS_URL}/More_Happi_logo.svg`}
              alt="logo"
            />

            <Space direction="vertical" size={32}>
              <>
                <Typography.Title level={1}>Sign Up</Typography.Title>

                <div className="m-lg">
                  <Typography.Title level={5}>
                    Take the first step towards unlimited coaching for your
                    team.
                  </Typography.Title>
                </div>

                <div className="auth-form-wrap">
                  <Form
                    name="signUp"
                    initialValues={defaultValues}
                    layout="vertical"
                    onFinish={(values) => onSubmitRegistration(values)}
                    onFinishFailed={onSubmitRegistrationFailed}
                  >
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        {
                          required: true,
                          type: 'email',
                          message: 'Please enter your email',
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        data-cy={DATA_TEST_IDS.SIGN_UP.EMAIL.ROOT}
                      />
                    </Form.Item>

                    <Form.Item
                      label="First Name"
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your first name',
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        data-cy={DATA_TEST_IDS.SIGN_UP.FIRST_NAME.ROOT}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Last Name"
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your last name',
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        data-cy={DATA_TEST_IDS.SIGN_UP.LAST_NAME.ROOT}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Company Name"
                      name="companyName"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter the company name',
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        data-cy={DATA_TEST_IDS.SIGN_UP.COMPANY_NAME.ROOT}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Password"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter a password',
                        },
                        {
                          min: 8,
                          message: 'Minimum of 8 characters',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const passwordFromInput = getFieldValue('password');

                            if (!passwordFromInput.match(/(?=.*\d)/)) {
                              return Promise.reject(
                                new Error('Contains atleast one number'),
                              );
                            }

                            if (!passwordFromInput.match(/(?=.*[A-Z])/)) {
                              return Promise.reject(
                                new Error(
                                  'Contains atleast one uppercase character',
                                ),
                              );
                            }

                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        size="large"
                        data-cy={DATA_TEST_IDS.LOGIN.PASSWORD.INPUT}
                      />
                    </Form.Item>

                    <Form.Item
                      name="tncs"
                      valuePropName="checked"
                      rules={[
                        {
                          required: true,
                          message: 'You have to agree to the terms to proceed',
                        },
                      ]}
                      data-cy={DATA_TEST_IDS.SIGN_UP.T_AND_C.ROOT}
                    >
                      <Checkbox
                        data-cy={DATA_TEST_IDS.SIGN_UP.T_AND_C.CHECKBOX}
                      >
                        I agree to these{' '}
                        <a
                          href="https://www.morehappi.com/terms-and-conditions"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          terms and conditions.
                        </a>
                      </Checkbox>
                    </Form.Item>

                    <Form.Item data-cy={DATA_TEST_IDS.SIGN_UP.SUBMIT.ROOT}>
                      <Button
                        size="large"
                        type="primary"
                        htmlType="submit"
                        disabled={loading}
                        data-cy={DATA_TEST_IDS.SIGN_UP.SUBMIT.BUTTON}
                      >
                        {loading ? 'Loading... ' : 'Create company account'}
                      </Button>
                    </Form.Item>
                  </Form>

                  {error && (
                    <Message type="error" items={[{ message: error }]} />
                  )}

                  <Link to="/auth/login">Already have an account? Sign In</Link>
                </div>
              </>
            </Space>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  );
};
