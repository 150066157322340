export const theme = {
  colors: {
    beige: '#F7EFE6',
    darkGrey: '#474645',
    lightGrey: '#F7F7F7',
    pink: '#FF9394',
    red: '#F34100',
    darkGreen: '#00592B',
    lightGreen: '#93C01F',
    yellow: '#F5C900',
    blue: '#6D94B4',
  },
};
