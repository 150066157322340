import ReactGA from 'react-ga';

import { app } from '@/configs/app.config';

export function initGA(options = null) {
  ReactGA.initialize(app.GOOGLE_ANALYTICS_ID, {
    debug: false,
  });
}

export function initOptimize() {
  ReactGA.ga('require', app.GOOGLE_OPTIMIZE_ID);
}

export const buttonClickEvent = (label: string) => {
  ReactGA.event({ category: 'Button Clicked', action: 'Click', label });
};

export const formSubmitted = (label: string) => {
  ReactGA.event({ category: 'Form Submitted', action: 'Submit', label });
};

export const pageView = (url: string) => {
  ReactGA.pageview(url);
};
