import { Layout, PageHeader, Row, Col, Typography, Button } from 'antd';

import { useNavigate } from 'react-router-dom';
import { HtmlMeta } from '@/components/HtmlMeta';
import { Mixpanel } from '@/utils/mixpanel';
import { IPageBaseProps } from '@/interfaces';
import { ROUTE_PATHS } from '@/configs/routes';
import { BackIcon } from '@/icons';

interface IProps extends IPageBaseProps {}

export const SponsorChecklistSix: React.FC<IProps> = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <HtmlMeta title="Onboarding Checklist - Book your first coaching session" />

      <Layout.Content className="site-layout-content thin">
        <PageHeader
          title="Book your first coaching session"
          ghost
          className="site-page-header"
          onBack={() =>
            navigate(ROUTE_PATHS.SUPPORT_GUIDES.SPONSOR_CHECKLIST.MAIN)
          }
          backIcon={<BackIcon style={{ fontSize: '40px' }} />}
        />

        <Typography.Paragraph>
          Try out More Happi coaching ahead of your team
        </Typography.Paragraph>

        <Typography.Paragraph>
          As the internal champion of More Happi in your company, it’s important
          that you experience More Happi coaching for yourself. That way you
          know the process and can share feedback and insights with the team. So
          when you’re ready, go ahead and book your first session!
        </Typography.Paragraph>

        <Typography.Paragraph>
          Not sure what to talk about in your first session?
        </Typography.Paragraph>

        <Typography.Paragraph>
          Why not consider some of the following:
        </Typography.Paragraph>

        <ul>
          <li>
            What does success look like for the integration of More Happi in my
            organisation?
          </li>
          <li>
            What differences do I want to see / hear / experience in my team as
            a result of them experiencing coaching?
          </li>
          <li>
            How will I know if my team is happier / more confident / more
            self-sufficient at problem-solving, etc?
          </li>
        </ul>

        <Typography.Paragraph>
          Our coaches can help you work through any of these questions (and
          more), and also help you prioritise what’s most important to you.
        </Typography.Paragraph>

        <Typography.Paragraph>
          Need more inspiration?{' '}
          <a
            rel="noreferrer"
            href="https://midnight-revolve-64c.notion.site/More-Happi-Goal-Planner-80d2046f54b141b5b02d5794ad08685c"
            target="_blank"
          >
            Try the More Happi Goal Planner
          </a>
          .
        </Typography.Paragraph>

        <Button
          type="primary"
          size="large"
          onClick={() => {
            Mixpanel.track('Sponsor Checklist Book A Session Clicked');
            navigate('/book-session');
          }}
        >
          Book a session
        </Button>
      </Layout.Content>
    </Layout>
  );
};
