import React from 'react';

import { Layout, Row, Col, PageHeader, Typography, Card } from 'antd';

import { IPageBaseProps } from '@/interfaces';
import { HtmlMeta } from '@/components/HtmlMeta';
import { ActionTile } from '@/components/ActionTile';
import { PageHeaderBlock } from '@/components/PageHeaderBlock';
import { useUser } from '@/state/user';
import { useCompany } from '@/state/company';
import { useScrollTo, useIsMobile } from '@/hooks';
import { theme } from '@/configs/theme';
import { ROUTE_PATHS } from '@/configs/routes';

interface IProps extends IPageBaseProps {}

export const Home: React.FC<IProps> = () => {
  const user = useUser();
  const company = useCompany();

  const welcomeMessage = `Hey ${user.currentUser?.firstName},`;

  const sponsorAdminCopy = () => (
    <>
      <Typography.Paragraph>
        Your team has <strong>unlimited</strong> access to coaching support
        every month. And so do you!
      </Typography.Paragraph>
      <Typography.Paragraph>
        <strong>This is not a benefit.</strong> This is an essential career
        growth and development resource to help your team solve problems,
        achieve goals, develop new skills, increase their self-awareness, and
        more!
      </Typography.Paragraph>
      <Typography.Paragraph>
        As the account owner, your role is to ensure that coaching is an{' '}
        <strong>integral part</strong> of your team&apos;s day-to-day, so they
        can start to benefit. Check out the checklist below to get started.
      </Typography.Paragraph>
    </>
  );

  const teamMemberCopy = () => (
    <>
      <Typography.Paragraph>
        You have unlimited access to coaching support every month!
      </Typography.Paragraph>
      <Typography.Paragraph>
        Coaching is an essential career growth and development resource to help
        you solve problems, achieve goals, develop new skills, increase
        self-awareness, and be your best self.
      </Typography.Paragraph>
      <Typography.Paragraph>
        But it is not just for work. More Happi coaches can support you in any
        life area. Think of them as your own personal growth champions, getting
        you closer to the things you want to be, do and have.
      </Typography.Paragraph>
      <Typography.Paragraph>
        Set yourself up for success and follow the steps below to get started.
      </Typography.Paragraph>
    </>
  );

  const mainCopy = user.currentUser?.isAdmin
    ? sponsorAdminCopy()
    : teamMemberCopy();

  useScrollTo();
  useIsMobile();

  const SPONSOR_SUB_NAVIGATION_ITEMS = [
    {
      id: 1,
      navTitle: 'Support Guides',
      title: 'Complete our sponsor checklist.',
      to: ROUTE_PATHS.SUPPORT_GUIDES.SPONSOR_CHECKLIST.MAIN,
      color: theme.colors.blue,
    },
    {
      id: 2,
      navTitle: 'Support Guides',
      title: 'Attend a live onboarding call: Intro to coaching for leaders.',
      to: ROUTE_PATHS.SUPPORT_GUIDES.ONBOARDING_CALL.LEADERS,
      color: theme.colors.blue,
    },
    {
      id: 3,
      navTitle: 'My Account',
      title: 'Manage my team.',
      to: ROUTE_PATHS.COMPANY.MANAGE_TEAM,
      color: theme.colors.yellow,
    },
    {
      id: 4,
      navTitle: 'Book a Session',
      title: 'Ready to book? Meet our coaches.',
      to: ROUTE_PATHS.SESSIONS.BROWSE,
      cta: true,
    },
  ];

  const TEAM_MEMBER_SUB_NAVIGATION_ITEMS = [
    {
      id: 1,
      navTitle: 'Support Guides',
      title: 'What is coaching?',
      href: 'https://midnight-revolve-64c.notion.site/What-is-coaching-877c000ec12645abba3101796bf65513',
      color: theme.colors.blue,
    },
    {
      id: 2,
      navTitle: 'Support Guides',
      title: 'How to use More Happi.',
      href: 'https://midnight-revolve-64c.notion.site/How-to-use-More-Happi-e17d42c0eb094e9abf78344e5bee0a46',
      color: theme.colors.blue,
    },
    {
      id: 3,
      navTitle: 'Support Guides',
      title: `${
        user.currentUser?.teamMember?.isManager
          ? `Attend a live onboarding call: Intro to coaching for leaders.`
          : `Attend a live onboarding call: Intro to coaching.`
      }`,
      to: user?.currentUser?.teamMember?.isManager
        ? ROUTE_PATHS.SUPPORT_GUIDES.ONBOARDING_CALL.LEADERS
        : ROUTE_PATHS.SUPPORT_GUIDES.ONBOARDING_CALL.TEAM_MEMBERS,
      color: theme.colors.blue,
    },
    {
      id: 4,
      navTitle: 'Book a Session',
      title: 'Ready to book? Meet our coaches.',
      to: ROUTE_PATHS.SESSIONS.BROWSE,
      cta: true,
    },
  ];

  const NAV_ITEMS = user.currentUser?.isAdmin
    ? SPONSOR_SUB_NAVIGATION_ITEMS
    : TEAM_MEMBER_SUB_NAVIGATION_ITEMS;

  return (
    <Layout>
      <HtmlMeta title="Home" />

      <Layout.Content className="site-layout-content">
        <PageHeader title={welcomeMessage} ghost className="site-page-header">
          <PageHeaderBlock
            image={{
              src: '/images/Landing_Page.svg',
              alt: 'Home',
            }}
          >
            {mainCopy}
          </PageHeaderBlock>
        </PageHeader>

        <Row
          className="mh-row-top-md"
          gutter={{ xs: 4, sm: 8, md: 16, lg: 24 }}
        >
          {NAV_ITEMS.map((item) => (
            <Col
              className="mh-vertical-spacer"
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={6}
            >
              <ActionTile {...item} />
            </Col>
          ))}
        </Row>
      </Layout.Content>
    </Layout>
  );
};
