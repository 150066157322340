import { Layout, PageHeader, Row, Col, Typography } from 'antd';

import { PageHeaderBlock } from '@/components/PageHeaderBlock';
import { HtmlMeta } from '@/components/HtmlMeta';
import { ActionTile } from '@/components/ActionTile';
import { useUser } from '@/state/user';
import { useCompany } from '@/state/company';
import { ROUTE_PATHS } from '@/configs/routes';
import { IPageBaseProps } from '@/interfaces';
import { theme } from '@/configs/theme';

interface IProps extends IPageBaseProps {}

export const MyAccount: React.FC<IProps> = () => {
  const user = useUser();
  const company = useCompany();

  const sponsorItems =
    user.currentUser?.isAdmin && !company.currentCompany?.isEnterprise
      ? [
          {
            id: 3,
            navTitle: 'My Account',
            title: `Billing.`,
            to: ROUTE_PATHS.COMPANY.BILLING,
            color: theme.colors.yellow,
          },
        ]
      : [];

  const SESSION_NAVIGATION_ITEMS = [
    {
      id: 1,
      navTitle: 'My Account',
      title: `Manage Team Members.`,
      to: ROUTE_PATHS.COMPANY.MANAGE_TEAM,
      color: theme.colors.yellow,
    },
    {
      id: 2,
      navTitle: 'My Account',
      title: `Team Data.`,
      to: ROUTE_PATHS.COMPANY.DATA,
      color: theme.colors.yellow,
    },
    ...sponsorItems,
  ];

  return (
    <Layout>
      <HtmlMeta title="My Account" />

      <Layout.Content className="site-layout-content">
        <PageHeader title="My Account" ghost className="site-page-header">
          <PageHeaderBlock
            image={{
              src: '/images/My_account.svg',
              alt: 'My Account',
            }}
          >
            <Typography.Paragraph>
              Your one-stop-shop to manage your account: add and remove users
              and see how the team is engaging with coaching.
            </Typography.Paragraph>
            <Typography.Paragraph>
              To discuss your subscription, please contact{' '}
              <a href="mailto:hey@morehappi.com">hey@morehappi.com</a>
            </Typography.Paragraph>
          </PageHeaderBlock>
        </PageHeader>

        <Row
          className="mh-row-top-md"
          gutter={{ xs: 4, sm: 8, md: 16, lg: 24 }}
        >
          {SESSION_NAVIGATION_ITEMS.map((item) => (
            <Col
              className="mh-vertical-spacer"
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={6}
            >
              <ActionTile {...item} />
            </Col>
          ))}
        </Row>
      </Layout.Content>
    </Layout>
  );
};
