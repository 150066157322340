import { Layout, PageHeader, Row, Col } from 'antd';
import { useEffect } from 'react';

import { useNavigate } from 'react-router';

import { HtmlMeta } from '@/components/HtmlMeta';

import { isMobile } from 'react-device-detect';

import { openInNewTab } from '@/utils/helpers/app';

import { IPageBaseProps } from '@/interfaces';

interface IProps extends IPageBaseProps {}

export const SponsorChecklistThree: React.FC<IProps> = () => {
  const navigate = useNavigate();

  useEffect(() => {
    openInNewTab(
      'https://midnight-revolve-64c.notion.site/3-Attend-a-live-onboarding-session-5292031902fd418695861f4b70a035e1',
    );

    if (isMobile) return;

    navigate(-1);
  }, []);

  return (
    <Layout>
      <HtmlMeta title="Onboarding Checklist - Attend a live onboarding call" />
    </Layout>
  );
};
