import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { Layout, Button, Row, Col, Image } from 'antd';
import qs from 'query-string';
import dayjs from 'dayjs';
import ReactGA from 'react-ga';

import { HtmlMeta } from '@/components/HtmlMeta';

import { useScrollTo } from '@/hooks';

import { useUser } from '@/state/user';

import { WEB_ASSETS_URL } from '@/configs/app.config';

import {
  // OrderByArg,
  // useListCoachesQuery,
  useCreateSessionMutation,
  // useListSessionsQuery,
  // SessionState,
} from '@/graphql/types.generated';

import { IPageBaseProps } from '@/interfaces';

import { Mixpanel } from '@/utils/mixpanel';

interface IProps extends IPageBaseProps {}

export const BookingSuccess: React.FC<IProps> = () => {
  const location = useLocation();

  useScrollTo();

  const user = useUser();

  const query = qs.parse(location.search);

  const date = dayjs(String(query?.start_at)).format('DD/MM/YYYY');
  const time = dayjs(String(query?.start_at)).format('HH:mm ');

  const coachEmail = decodeURIComponent(String(query.coachEmail));
  const sessionType = query.session_type;
  const googleLink = query.google_link;
  const googleMeet = query.google_meet;
  const googleLinkQueryString = `${googleLink}&text=Coaching+call+booked+with+${query.coach}&dates=${query.dates}&location=${googleMeet}`;
  const sessionUrl = `/browse`;

  const session = {
    coachEmail,
    date,
    time,
    sessionType,
    googleLink,
    googleMeet,
  };

  // const scheduledAt = new Date(String(query?.start_at)).toISOString();

  useEffect(() => {
    Mixpanel.track('Session Booked', {
      ...session,
    });
  }, []);

  // const createSession = useCreateSessionMutation({
  //   onSuccess: (data: any) => {
  //     ReactGA.event({
  //       category: 'Session Booked',
  //       action: 'session',
  //       label: data.id,
  //     });

  //     Mixpanel.track('Session Booked', {
  //       id: data.id,
  //       ...session,
  //     });
  //   },
  // });

  // const listCoachesQuery = useListCoachesQuery(
  //   {
  //     where: {
  //       user: {
  //         email: {
  //           equals: String(coachEmail),
  //         },
  //       },
  //     },
  //     take: 1,
  //     skip: 0,
  //     orderBy: {
  //       displayName: OrderByArg.Asc,
  //     },
  //   },
  //   {
  //     enabled: Boolean(coachEmail),
  //   },
  // );

  // @todo - temporary hack for avoid creating the same session twice from YCMB
  // useListSessionsQuery(
  //   {
  //     where: {
  //       scheduledAt: {
  //         equals: scheduledAt,
  //       },
  //     },
  //     take: 1,
  //     skip: 0,
  //     orderBy: {
  //       scheduledAt: OrderByArg.Asc,
  //     },
  //   },
  //   {
  //     onSuccess: async (data) => {
  //       if (data?.listSessions?.length) return;

  //       const coaches = await listCoachesQuery.refetch();

  //       const coach = coaches?.data?.listCoaches?.length
  //         ? coaches?.data.listCoaches[0]
  //         : undefined;

  //       if (!coach) return;

  //       return createSession.mutate({
  //         data: {
  //           coach: {
  //             connect: {
  //               id: coach.id,
  //             },
  //           },
  //           teamMember: {
  //             connect: {
  //               id: String(user?.currentUser?.teamMember?.id),
  //             },
  //           },
  //           scheduledAt,
  //           googleMeetUrl: String(session.googleMeet),
  //           state: SessionState.Confirmed,
  //         },
  //       });
  //     },
  //   },
  // );

  return (
    <Layout>
      <HtmlMeta title="Booking Confirmed" />
      <Layout.Content className="site-layout-content booking-success-container">
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={18}>
            <Image
              preview={false}
              src={`${WEB_ASSETS_URL}/booking_illustration.png`}
              alt="image"
              width={200}
            />
          </Col>
          <Col xs={24} sm={24} md={24}>
            <h2>Your session with {query.coach} is confirmed</h2>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={18} />
        </Row>
        <div className="mt-md">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col
              xs={{ span: 8 }}
              sm={{ span: 4 }}
              md={{ span: 3 }}
              lg={{ span: 3 }}
              xl={{ span: 2 }}
            >
              <p>Date: </p>
            </Col>
            <Col>
              <p> {date}</p>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col
              xs={{ span: 8 }}
              sm={{ span: 4 }}
              md={{ span: 3 }}
              lg={{ span: 3 }}
              xl={{ span: 2 }}
            >
              <p>Time: </p>
            </Col>
            <Col
              xs={{ span: 8 }}
              sm={{ span: 4 }}
              md={{ span: 3 }}
              lg={{ span: 3 }}
              xl={{ span: 2 }}
            >
              <p>{time}</p>
            </Col>
          </Row>
          {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col
              xs={{ span: 8 }}
              sm={{ span: 4 }}
              md={{ span: 3 }}
              lg={{ span: 3 }}
              xl={{ span: 2 }}
            >
              <p>Topic:</p>
            </Col>
            <Col
              xs={{ span: 8 }}
              sm={{ span: 4 }}
              md={{ span: 3 }}
              lg={{ span: 3 }}
              xl={{ span: 2 }}
            >
              <p>{sessionType}</p>
            </Col>
          </Row> */}
        </div>
        {sessionUrl && (
          <Row>
            <Col>
              <Link to={`${sessionUrl}`}>
                <Button className="mh-cylinder-btn-primary-action m-md">
                  Place Another Booking
                </Button>
              </Link>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <a
              target="blank"
              rel="noopener noreferrer"
              href={googleLinkQueryString}
            >
              <Button className="mh-cylinder-btn-success-page m-md">
                Add to Google Calendar
              </Button>
            </a>
          </Col>
        </Row>
        <Row>
          <Col>
            <Link to="/">
              <Button className="mh-cylinder-btn-success-page m-md">
                Go home
              </Button>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mt-md">
              <p>We have also sent all of the details to you at your email.</p>
              <p>Hope you are looking forward to the chat, your coach is.</p>
            </div>
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
};
