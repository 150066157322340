import mixpanel from 'mixpanel-browser';
import { pickBy } from 'lodash';

const canTrack = process.env.REACT_APP_MIXPANEL_ACTIVE;

if (canTrack && process.env.REACT_APP_MIXPANEL) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL);
}

const removeNullProperties = (props: any) => pickBy(props);

export const Mixpanel = {
  formatAmountForTracking: (amount: number) => {
    const formattedAmount = new Intl.NumberFormat('en-GB', {
      style: 'decimal',
    }).format(amount / 100);

    return Number(formattedAmount);
  },
  identify: (id?: string) => {
    if (canTrack || !id) mixpanel.identify(id);
  },
  alias: (email: string) => {
    if (canTrack) mixpanel.alias(email);
  },
  track: (name: string, props?: any) => {
    if (canTrack) mixpanel.track(name, removeNullProperties(props));
  },
  reset: () => {
    if (canTrack) mixpanel.reset();
  },
  people: {
    trackCharge: (amount: number, currency: string) => {
      if (canTrack) {
        mixpanel.people.track_charge(amount, {
          original_currency: currency,
        });
      }
    },
    increment: (property: string, value: number) => {
      if (canTrack) {
        mixpanel.people.increment(property, value);
      }
    },
    setOnce: (props: any) => {
      if (canTrack) mixpanel.people?.set(removeNullProperties(props));
    },
    set: (props: any) => {
      if (canTrack) mixpanel.people?.set(removeNullProperties(props));
    },
    setProp: (key: string, value: any) => {
      if (canTrack) mixpanel.people.set(key, value);
    },
  },
};
