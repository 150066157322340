export type CompanySubscriptionState = {
  isUpdating: boolean;
  status?: string | null;
  isActive: boolean;
  isCancelled: boolean;
  cancellingAt: Date | null;
};

export enum CompanySubscriptionReducerActionType {
  setUpdatingSubscription = 'SET_UPDATING_SUBSCRIPTION',
  updateSubscriptionStatus = 'UPDATE_SUBSCRIPTION_STATUS',
}

export type CompanySubscriptionReducerAction =
  | {
      type: CompanySubscriptionReducerActionType.setUpdatingSubscription;
    }
  | {
      type: CompanySubscriptionReducerActionType.updateSubscriptionStatus;
      active: boolean;
      status?: string;
      cancellingAt?: Date;
    };

export const initialState = {
  isUpdating: false,
  status: null,
  isActive: false,
  isCancelled: false,
  cancellingAt: null,
};

export function companySubscriptionReducer(
  state: CompanySubscriptionState,
  action: CompanySubscriptionReducerAction,
): CompanySubscriptionState {
  switch (action.type) {
    case 'SET_UPDATING_SUBSCRIPTION': {
      return {
        ...state,
        isUpdating: !state.isUpdating,
      };
    }
    case 'UPDATE_SUBSCRIPTION_STATUS': {
      return {
        ...state,
        isActive: action.active,
        status: action.status,
        isCancelled: action.status === 'not_renewing' || false,
        cancellingAt: action.cancellingAt || null,
      };
    }
    default: {
      throw new Error(`Unsupported action type: ${JSON.stringify(action)}`);
    }
  }
}
