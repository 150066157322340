import { isMobile } from 'react-device-detect';

export const openInNewTab = (href: string) => {
  if (isMobile) {
    return window.location.replace(href);
  }

  return Object.assign(document.createElement('a'), {
    target: '_blank',
    rel: 'noreferrer',
    href,
  }).click();
};
