import { Card } from 'antd';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { theme } from '@/configs/theme';

import { Mixpanel } from '@/utils/mixpanel';

import styles from './styles.module.less';

interface Props {
  id?: string | number;
  to?: string;
  href?: string;
  navTitle?: string;
  title?: string;
  description?: string;
  onClick?: () => void;
  color?: string;
  ctaSecondary?: boolean;
  cta?: boolean;
}

export const ActionTile = ({
  to,
  href,
  navTitle,
  title,
  description,
  onClick,
  color = 'inherit',
  cta = false,
  ctaSecondary = false,
}: Props) => {
  const getArrowImagePath = () => {
    switch (color) {
      case theme.colors.blue:
        return '/images/arrows/Arrow_blue.svg';
      case theme.colors.lightGrey:
        return '/images/arrows/Arrow_light_grey.svg';
      case theme.colors.pink:
        return '/images/arrows/Arrow_pink.svg';
      case theme.colors.lightGreen:
        return '/images/arrows/Arrow_light_green.svg';
      case theme.colors.darkGreen:
        return '/images/arrows/Arrow_dark_green.svg';
      case theme.colors.yellow:
        return '/images/arrows/Arrow_yellow.svg';
      case theme.colors.red:
        return '/images/arrows/Arrow_red.svg';
      default:
        return '/images/arrows/Arrow_light_grey.svg';
    }
  };

  const renderCard = () => (
    <Card
      className={cx(
        styles.container,
        cta && styles.cta,
        ctaSecondary && styles['cta-secondary'],
      )}
      onClick={onClick}
    >
      <div className={cx(styles.content)}>
        {navTitle && (
          <h5
            className={cx(
              styles.navTitle,
              cta && styles.cta,
              ctaSecondary && styles['cta-secondary'],
            )}
            style={{ color }}
          >
            {navTitle.toUpperCase()}
          </h5>
        )}
        {title && (
          <span
            className={cx(
              styles.title,
              cta && styles.cta,
              ctaSecondary && styles['cta-secondary'],
            )}
            style={{ color }}
          >
            {title}
          </span>
        )}
        {description && (
          <p
            className={cx(
              styles.description,
              cta && styles.cta,
              ctaSecondary && styles['cta-secondary'],
            )}
          >
            {description}
          </p>
        )}
      </div>
      <img
        src={getArrowImagePath()}
        alt="Action Tile Arrow"
        className={styles['arrow-button']}
      />
    </Card>
  );

  if (href) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
        onClick={() => Mixpanel.track(`${title} link clicked`)}
      >
        {renderCard()}
      </a>
    );
  }

  return (
    <Link to={to} onClick={() => Mixpanel.track(`${title} page clicked`)}>
      {renderCard()}
    </Link>
  );
};
