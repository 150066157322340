import { Layout, PageHeader, Row, Col, Typography } from 'antd';

import { HtmlMeta } from '@/components/HtmlMeta';
import { PageHeaderBlock } from '@/components/PageHeaderBlock';
import { ActionTile } from '@/components/ActionTile';
import { ROUTE_PATHS } from '@/configs/routes';
import { theme } from '@/configs/theme';
import { IPageBaseProps } from '@/interfaces';
import { useUser } from '@/state/user';

interface IProps extends IPageBaseProps {}

export const Tools: React.FC<IProps> = () => {
  const user = useUser();

  const sponsorItems = user.currentUser?.isAdmin
    ? [
        {
          id: 5,
          navTitle: 'Tools',
          title: `Team comms calendar.`,
          href: 'https://www.google.com/url?q=https://docs.google.com/spreadsheets/d/1dyt4p_JmnA7xmmB_dQ5bWdyhqWHTFBtnO8ELlNcx3gg/copy&sa=D&source=editors&ust=1675174531242301&usg=AOvVaw3CJCxIfFLYgeZLJFXELDrC',
          color: theme.colors.pink,
        },
        {
          id: 6,
          navTitle: 'Tools.',
          title: `Opportunity mapper: where to integrate More Happi`,
          href: 'https://docs.google.com/spreadsheets/d/1hjWecccZtFpXcTmzXhJrRcZHJHmC3fLeUdhp5rh1t1U/copy',
          color: theme.colors.pink,
        },
      ]
    : [];

  const SESSION_NAVIGATION_ITEMS = [
    {
      id: 1,
      navTitle: 'Tools',
      title: `More Happi Goal Planner.`,
      href: 'https://midnight-revolve-64c.notion.site/More-Happi-Goal-Planner-80d2046f54b141b5b02d5794ad08685c',
      color: theme.colors.pink,
    },
    {
      id: 2,
      navTitle: 'Tools',
      title: `Habit tracker.`,
      href: 'https://midnight-revolve-64c.notion.site/More-Happi-Habit-tracker-be763e67f5654e408b31b8c1a9cc0126',
      color: theme.colors.pink,
    },
    {
      id: 3,
      navTitle: 'Tools',
      title: `Blob Tree.`,
      href: 'https://midnight-revolve-64c.notion.site/Team-tool-Blob-Tree-f43bb759c63e41cd9b686f2f332c2db9',
      color: theme.colors.pink,
    },
    {
      id: 4,
      navTitle: 'Tools',
      title: `The Emotions Wheel.`,
      href: 'https://midnight-revolve-64c.notion.site/Team-tool-The-Emotions-Wheel-0844545abd4e4cdaab362d611018d9ee',
      color: theme.colors.pink,
    },
    ...sponsorItems,
  ];

  return (
    <Layout>
      <HtmlMeta title="Tools" />

      <Layout.Content className="site-layout-content">
        <PageHeader title="Tools" ghost className="site-page-header">
          <PageHeaderBlock
            image={{
              src: '/images/Tools.svg',
              alt: 'Book a session',
            }}
          >
            {user?.currentUser?.isAdmin ? (
              <Typography.Paragraph>
                Self-led coaching tools and practical aids to help you enhance
                your performance, track and monitor your progress and build a
                coaching culture in the team.
              </Typography.Paragraph>
            ) : (
              <Typography.Paragraph>
                Self-led coaching tools and practical aids to help you enhance
                your performance and track and monitor your progress.
              </Typography.Paragraph>
            )}
          </PageHeaderBlock>
        </PageHeader>

        <Row
          className="mh-row-top-md"
          gutter={{ xs: 4, sm: 8, md: 16, lg: 24 }}
        >
          {SESSION_NAVIGATION_ITEMS.map((item: any) => (
            <Col
              className="mh-vertical-spacer"
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={6}
            >
              <ActionTile {...item} />
            </Col>
          ))}
        </Row>
      </Layout.Content>
    </Layout>
  );
};
