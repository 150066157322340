import { Layout, PageHeader, Row, Col, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

import { PageHeaderBlock } from '@/components/PageHeaderBlock';
import { HtmlMeta } from '@/components/HtmlMeta';
import { ActionTile } from '@/components/ActionTile';
import { useCompany } from '@/state/company';
import { useUser } from '@/state/user';
import { useRoleRedirect } from '@/hooks/useRoleRedirect';
import { ROUTE_PATHS } from '@/configs/routes';
import { theme } from '@/configs/theme';
import { IPageBaseProps } from '@/interfaces';
import { UserRole } from '@/graphql/types.generated';
import { BackIcon } from '@/icons';

interface IProps extends IPageBaseProps {}

export const SponsorChecklist: React.FC<IProps> = () => {
  const navigate = useNavigate();
  const user = useUser();
  const company = useCompany();

  useRoleRedirect([UserRole.Admin, UserRole.Sponsor, UserRole.Superadmin]);

  const enterpriseItems = company.currentCompany?.isEnterprise
    ? [
        {
          id: 5,
          navTitle: 'How To Guides',
          title: `5. Book your integration call.`,
          to: ROUTE_PATHS.SUPPORT_GUIDES.SPONSOR_CHECKLIST.FIVE,
          color: theme.colors.blue,
        },
      ]
    : [];

  const SESSION_NAVIGATION_ITEMS = [
    {
      id: 1,
      navTitle: 'How To Guides',
      title: `1. Tell your team about More Happi.`,
      to: ROUTE_PATHS.SUPPORT_GUIDES.SPONSOR_CHECKLIST.ONE,
      color: theme.colors.blue,
    },
    {
      id: 2,
      navTitle: 'How To Guides',
      title: `2. Invite your team to the platform.`,
      to: ROUTE_PATHS.SUPPORT_GUIDES.SPONSOR_CHECKLIST.TWO,
      color: theme.colors.blue,
    },
    {
      id: 3,
      navTitle: 'How To Guides',
      title: `3. Attend a live onboarding call: Intro to coaching for leaders.`,
      to:
        user.currentUser?.isAdmin || user?.currentUser?.teamMember?.isManager
          ? ROUTE_PATHS.SUPPORT_GUIDES.ONBOARDING_CALL.LEADERS
          : ROUTE_PATHS.SUPPORT_GUIDES.ONBOARDING_CALL.TEAM_MEMBERS,
      color: theme.colors.blue,
    },
    {
      id: 4,
      navTitle: 'How To Guides',
      title: `4. Integrate More Happi into team ways of working.`,
      to: ROUTE_PATHS.SUPPORT_GUIDES.SPONSOR_CHECKLIST.FOUR,
      color: theme.colors.blue,
    },
    ...enterpriseItems,
    {
      id: 6,
      navTitle: 'How To Guides',
      title: `${
        company.currentCompany?.isEnterprise ? '6' : '5'
      }. Book your first coaching session.`,
      to: ROUTE_PATHS.SUPPORT_GUIDES.SPONSOR_CHECKLIST.SIX,
      color: theme.colors.blue,
    },
  ];

  return (
    <Layout>
      <HtmlMeta title="Sponsor Checklist" />

      <Layout.Content className="site-layout-content">
        <PageHeader
          title="Complete our sponsor checklist"
          ghost
          className="site-page-header"
          onBack={() => navigate(ROUTE_PATHS.SUPPORT_GUIDES.MAIN)}
          backIcon={<BackIcon style={{ fontSize: '40px' }} />}
        >
          <PageHeaderBlock
            image={{
              src: '/images/Sponsor_checklist.svg',
              alt: 'Complete our sponsor checklist',
            }}
          >
            <Typography.Title level={5}>
              Set your team up for success with More Happi
            </Typography.Title>
            <Typography.Paragraph>
              As the account owner (and/or admin), your role is to champion More
              Happi in your organisation.
            </Typography.Paragraph>
            <Typography.Paragraph>
              That means launching it to the team, banging the drum for coaching
              and embedding it into the things you already do for their learning
              and development.
            </Typography.Paragraph>
            <Typography.Paragraph>
              Follow our{' '}
              {SESSION_NAVIGATION_ITEMS.length === 6 ? 'six' : 'five'}-step
              checklist to create your foundations for success.
            </Typography.Paragraph>
          </PageHeaderBlock>
        </PageHeader>

        <Row
          className="mh-row-top-md"
          gutter={{ xs: 4, sm: 8, md: 16, lg: 24 }}
        >
          {SESSION_NAVIGATION_ITEMS.map((item: any) => (
            <Col
              className="mh-vertical-spacer"
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={6}
            >
              <ActionTile {...item} />
            </Col>
          ))}
        </Row>
      </Layout.Content>
    </Layout>
  );
};
