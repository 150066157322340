import { Layout, PageHeader, Row, Col, Typography } from 'antd';

import { HtmlMeta } from '@/components/HtmlMeta';

import { Iframe } from '@/components/Iframe';

import { IPageBaseProps } from '@/interfaces';

interface IProps extends IPageBaseProps {}

export const SponsorChecklistFive: React.FC<IProps> = () => {
  const iframe = `
    <iframe
      src='https://morehappiintegration.youcanbook.me/?noframe=true&skipHeaderFooter=true'
      id='ycbmiframemorehappimanageronboarding'
      style='width:100%;height:1000px;border:0px;background-color:white'
      frameborder='0'
      allowtransparency='true'
      sandbox='allow-same-origin allow-top-navigation allow-scripts allow-forms'
    ></iframe>`;

  return (
    <Layout>
      <HtmlMeta title="Onboarding Checklist - Book your integration call" />

      <Layout.Content className="site-layout-content">
        <PageHeader
          title="Book your integration call"
          ghost
          className="site-page-header"
        />

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={18}>
            <Typography.Paragraph>
              Not sure where to start with integrating More Happi into your
              company’s ways of working? Help is at hand!
            </Typography.Paragraph>
            <Typography.Paragraph>
              Book your 30 minute integration call with our Customer Happiness
              team to discuss ideas and strategies for integrating More Happi
              into your teams’ day-to-day. Your team can get the most out of
              coaching and you move closer to your goals - win-win!
            </Typography.Paragraph>
          </Col>
        </Row>
        <Iframe iframe={iframe} />
      </Layout.Content>
    </Layout>
  );
};
