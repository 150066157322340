import { Select } from 'antd';

import { useListCoachAudienceExperiencesQuery } from '@/graphql/types.generated';

interface Props {
  onChange: (value: string) => void;
  selected: any;
}

export const AudienceExperienceFilter = ({ onChange, selected }: Props) => {
  const { isLoading, error, data } = useListCoachAudienceExperiencesQuery();

  const options = data?.listCoachAudienceExperiences?.map((category: any) => ({
    label: category.label,
    value: category.value,
  }));

  return (
    <Select
      onChange={onChange}
      placeholder="Has coached"
      loading={isLoading}
      disabled={isLoading || !!error}
      options={options}
      defaultValue={selected}
      mode="multiple"
      allowClear
      showSearch
      size="middle"
      maxTagCount={1}
      style={{
        width: '100%',
      }}
    />
  );
};
