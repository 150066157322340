import React, { useEffect } from 'react';
import { Layout } from 'antd';

import { useUser } from '@/state/user';
import { useCompany } from '@/state/company';
import { Navigation } from '@/components/Navigation';
import { useIntercom, useIsMobile, usePageTracking } from '@/hooks';
import { Mixpanel } from '@/utils/mixpanel';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { SubscriptionNotice } from '@/components/SubscriptionNotice';
import { ImpersonationNotice } from '@/components/ImpersonationNotice';

export interface IProps {
  children: React.ReactNode;
  disableHeader?: boolean;
  disableFooter?: boolean;
  page?: string;
}

export const MasterLayout: React.FC<IProps> = (props) => {
  usePageTracking();
  useIntercom();

  useEffect(() => {
    Mixpanel.track(`${props.page} viewed`);
  }, [props.page]);

  const user = useUser();
  const company = useCompany();
  const isMobile = useIsMobile();

  if (!user.currentUser?.id || !company.currentCompany?.id) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      {user.currentUser.isImpersonating && <ImpersonationNotice />}
      <SubscriptionNotice />
      <Layout>
        <Navigation />
        {props.children}
      </Layout>
    </>
  );
};
