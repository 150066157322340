import { Layout } from 'antd';
import { useEffect } from 'react';

import { useNavigate } from 'react-router';

import { isMobile } from 'react-device-detect';

import { HtmlMeta } from '@/components/HtmlMeta';

import { openInNewTab } from '@/utils/helpers/app';

export const HabitTracker: React.FC<any> = () => {
  const navigate = useNavigate();

  useEffect(() => {
    openInNewTab(
      'https://midnight-revolve-64c.notion.site/More-Happi-Habit-tracker-be763e67f5654e408b31b8c1a9cc0126',
    );

    if (isMobile) return;

    navigate(-1);
  }, []);

  return (
    <Layout>
      <HtmlMeta title="Tool - Habit Tracker" />
    </Layout>
  );
};
