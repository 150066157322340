import { useNavigate } from 'react-router';

import { useCompany } from '@/state/company';
import { useCompanySubscription } from '@/state/company-subscription';

import { SubscriptionRedirectModal } from '@/components/Modals/SubscriptionRedirectModal';

import { ROUTE_PATHS } from '@/configs/routes';

export const SubscriptionPermission = ({
  required,
  children,
}: {
  required: boolean;
  children: any;
}) => {
  const navigate = useNavigate();

  const company = useCompany();
  const companySubscription = useCompanySubscription();

  const onCloseRedirectModal = () => navigate(ROUTE_PATHS.DASHBOARD);
  const onRedirectModalOk = () => navigate(ROUTE_PATHS.DASHBOARD);

  const isOpen =
    !company?.currentCompany?.isEnterprise &&
    !companySubscription.state.isActive;

  return (
    <>
      {children}
      {!company.isLoading && required && (
        <SubscriptionRedirectModal
          isOpen={isOpen}
          onOk={onRedirectModalOk}
          onClose={onCloseRedirectModal}
        />
      )}
    </>
  );
};
