import React from 'react';
import { useNavigate } from 'react-router';
import { Layout, PageHeader } from 'antd';

import { HtmlMeta } from '@/components/HtmlMeta';
import { Iframe } from '@/components/Iframe';
import { SubscriptionPermission } from '@/components/SubscriptionPermission';

import { getIframe } from '@/utils/iframe';

import { IPageBaseProps } from '@/interfaces';

import { app } from '@/configs/app.config';
import { BackIcon } from '@/icons';

interface IProps extends IPageBaseProps {}

export const NextAvailable: React.FC<IProps> = () => {
  const navigate = useNavigate();

  return (
    <SubscriptionPermission required>
      <Layout>
        <HtmlMeta title="Next available coach" />
        <Layout.Content>
          <PageHeader
            ghost
            className="site-page-header"
            onBack={() => navigate('/coaches')}
            backIcon={<BackIcon style={{ fontSize: '40px' }} />}
            title="Next available"
          />

          <Layout.Content className="coaches-layout-content">
            <Iframe iframe={getIframe(app?.COACHING_URLS?.NEXT_AVAILABLE)} />
          </Layout.Content>
        </Layout.Content>
      </Layout>
    </SubscriptionPermission>
  );
};
