import { HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';
import { Spin } from 'antd';
import { BrowserRouter } from 'react-router-dom';

import { IntercomProvider } from 'react-use-intercom';

import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { AuthProvider } from '@/state/auth';
import { UserProvider } from '@/state/user';
import { CompanyProvider } from '@/state/company';
import { CompanySubscriptionProvider } from '@/state/company-subscription';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { ErrorBoundary } from '@/components/ErrorBoundary';

import { Pages } from '@/pages';

import { queryClient } from '@/utils/query-client';

import { app } from '@/configs/app.config';

Spin.setDefaultIndicator(<LoadingSpinner />);

export const App = () => {
  return (
    <ErrorBoundary>
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <CompanySubscriptionProvider>
              <UserProvider>
                <CompanyProvider>
                  <IntercomProvider appId={app.INTERCOM_APP_ID}>
                    <BrowserRouter>
                      <Pages />
                      <Toaster position="top-right" />
                      <ReactQueryDevtools initialIsOpen />
                    </BrowserRouter>
                  </IntercomProvider>
                </CompanyProvider>
              </UserProvider>
            </CompanySubscriptionProvider>
          </AuthProvider>
        </QueryClientProvider>
      </HelmetProvider>
    </ErrorBoundary>
  );
};
