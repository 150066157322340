// @ts-nocheck

import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';

import {
  Table,
  Tag,
  Space,
  Input,
  notification,
  Row,
  Col,
  Button,
  Select,
  Typography,
  Divider,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { useUser } from '@/state/user';
import { useCompany } from '@/state/company';
import { useCompanySubscription } from '@/state/company-subscription';

import { ErrorMessage } from '@/components/ErrorMessage';
import { InviteTeamMemberModal } from '@/components/Modals/InviteTeamMemberModal';
import { ConfirmRoleChangeModal } from '@/components/Modals/ConfirmRoleChangeModal';
import { ConfirmTeamMemberRemovalModal } from '@/components/Modals/ConfirmTeamMemberRemovalModal';

import { TeamListActions } from './components/TeamListActions';

import { ROUTE_PATHS } from '@/configs/routes';

import {
  UserRole,
  useListTeamMembersQuery,
  useUpdateTeamMemberMutation,
} from '@/graphql/types.generated';

import { ICompBaseProps } from '@/interfaces';

import { Mixpanel } from '@/utils/mixpanel';

interface IProps extends ICompBaseProps {}

export const TeamList: React.FC<IProps> = (props) => {
  const user = useUser();
  const company = useCompany();
  const companySubscription = useCompanySubscription();

  const updateTeamMember = useUpdateTeamMemberMutation({
    onSuccess: () => {
      return Mixpanel.track('Team Member Updated');
    },
  });

  const { isLoading, isError, data, error, refetch } = useListTeamMembersQuery({
    where: {
      companyId: {
        equals: company?.currentCompany.id,
      },
      archivedAt: null,
    },
    skip: 0,
    // @todo - handle performant pagination
    take: 500,
    orderBy: {
      createdAt: 'ASC',
    },
  });

  const [isInviteModalOpen, setIsInviteModalOpen] = useState<boolean>(false);

  const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [isRemoveModalOpen, setRemoveModalOpen] = useState<boolean>(false);
  const [confirmTeamMember, setConfirmTeamMember] =
    useState<TeamMemberModelType | null>(null);
  const [confirmRoleUpdatedValue, setConfirmRoleUpdatedValue] =
    useState<string>('');

  const searchInput = useRef(null);

  const onToggleInviteModel = () => {
    setIsInviteModalOpen(!isInviteModalOpen);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
    setSearchedColumn(null);
  };

  const onRemoveTeamMember = (teamMember: TeamMemberModelType) => {
    setConfirmTeamMember(teamMember);
    setRemoveModalOpen(true);
  };

  const onChangeSponsor = (teamMember: TeamMemberModelType) => {
    setConfirmRoleUpdatedValue(UserRole.Sponsor, teamMember.user.id);
    setConfirmTeamMember(teamMember);
    setConfirmModalOpen(true);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div className="p-md">
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          className="search-input-table-ant-d"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            className="search-button-table-ant-d"
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            className="search-button-table-ant-d"
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined className={filtered ? 'search-outlined-color' : null} />
    ),
    onFilter: (value, teamMember: TeamMemberModelType) => {
      const formattedValue = value.toLowerCase();

      if (dataIndex === 'firstName' || dataIndex === 'lastName') {
        return (
          teamMember.user.firstName.toLowerCase().includes(formattedValue) ||
          teamMember.user.lastName.toLowerCase().includes(formattedValue) ||
          ''
        );
      }

      if (dataIndex === 'email') {
        return (
          teamMember.user.email.toLowerCase().includes(formattedValue) || ''
        );
      }

      return teamMember.user[dataIndex].includes(formattedValue) || '';
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (_, teamMember: TeamMemberModelType) => teamMember.user[dataIndex],
  });

  const openManagerNotification = ({ name }: { name: string }) => {
    const args = {
      message: 'Manager Status Changed',
      description: `You have changed the managerial status of ${name}. `,
      duration: 5,
      className: 'notification',
    };

    notification.open(args);
  };

  const invitedUsersColumns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (text, teamMember: TeamMemberModelType) =>
        teamMember.user.firstName,
      ...getColumnSearchProps('firstName'),
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      render: (text, teamMember: TeamMemberModelType) =>
        teamMember.user.lastName,
      ...getColumnSearchProps('lastName'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (_, teamMember: TeamMemberModelType) => teamMember.user.email,
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Status',
      dataIndex: 'activationState',
      key: 'activationState',
      render: (_, teamMember) => {
        const color =
          teamMember.user.activationState === 'activated' ||
          teamMember.user.activationState === 'pending'
            ? 'green'
            : 'default';

        const label =
          teamMember.user.activationState === 'activated' ||
          teamMember.user.activationState === 'pending'
            ? 'Joined'
            : 'Invited';

        return (
          <Tag color={color} className="accountTypeColumn">
            {label}
          </Tag>
        );
      },
      // filters: [
      //   {
      //     text: 'Joined',
      //     value: 'activated',
      //   },
      //   {
      //     text: 'Invited',
      //     value: 'invited',
      //   },
      // ],
      // onFilter: (filter, teamMember: TeamMemberModelType) => {
      //   return filter === "activated" || filter === "pending" ? teamMember
      // }
    },
    {
      title: 'Account type',
      dataIndex: 'role',
      key: 'role',
      render: (text, teamMember: TeamMemberModelType) => (
        <span className="accountTypeColumn">
          {teamMember.user.role === 'team_member'
            ? 'Team Member'
            : teamMember.user.role}
        </span>
      ),
      filters: [
        {
          text: 'Sponsor',
          value: 'sponsor',
        },
        {
          text: 'Admin',
          value: 'admin',
        },
        {
          text: 'Team Member',
          value: 'team_member',
        },
      ],
      onFilter: (value, teamMember: TeamMemberModelType) =>
        teamMember.user.role === value,
    },
    {
      title: 'Change Role',
      dataIndex: 'changePrivileges',
      key: 'changePrivileges',
      render: (_, teamMember: TeamMemberModelType) => {
        const onChangeRole = (role: string) => {
          setConfirmRoleUpdatedValue(role);
          setConfirmTeamMember(teamMember);
          setConfirmModalOpen(true);
        };

        return (
          <Select
            defaultValue={teamMember.user.role}
            value={teamMember.user.role}
            style={{ width: 136 }}
            onChange={(role) => onChangeRole(role)}
            disabled={
              teamMember.archivedAt || teamMember?.user.role === 'sponsor'
            }
          >
            <Select.Option key="team_member" value="team_member">
              Team Member
            </Select.Option>
            <Select.Option key="admin" value="admin">
              Admin
            </Select.Option>
          </Select>
        );
      },
    },
    {
      title: 'Manager',
      dataIndex: 'isManager',
      key: 'isManager',
      filters: [
        {
          text: 'Yes',
          value: true,
        },
        {
          text: 'No',
          value: false,
        },
      ],
      onFilter: (value, teamMember: TeamMemberModelType) =>
        teamMember.isManager === value,
      render: (_, teamMember: TeamMemberModelType) => {
        return (
          <Select
            defaultValue={teamMember.isManager}
            value={teamMember.isManager}
            style={{ width: 120 }}
            onChange={() => {
              updateTeamMember.mutate(
                {
                  where: { id: teamMember.id },
                  data: { isManager: !teamMember.isManager },
                },
                {
                  onSuccess: () => {
                    Mixpanel.track('Team Member Manager Status Changed');

                    openManagerNotification({
                      name: `${teamMember.user.firstName} ${teamMember.user.lastName}`,
                    });

                    refetch();
                  },
                },
              );
            }}
            disabled={teamMember.archivedAt}
          >
            <Select.Option key={0} value={true}>
              Yes
            </Select.Option>
            <Select.Option key={1} value={false}>
              No
            </Select.Option>
          </Select>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, teamMember) => (
        <TeamListActions
          onRemoveTeamMember={onRemoveTeamMember}
          onChangeSponsor={onChangeSponsor}
          teamMember={teamMember}
          refetchTeamMembers={refetch}
        />
      ),
    },
  ];

  const canInvite =
    !company?.currentCompany.isEnterprise &&
    !companySubscription.state.isActive;

  return (
    <div style={{ height: '100%' }}>
      {canInvite && (
        <div>
          <Typography.Paragraph>
            To get going, first create your subscription, then you can send your
            invites. Give your team a heads up that they will hear from us and
            we will do the rest{' '}
            <Link to={ROUTE_PATHS.COMPANY.BILLING}>
              <Button type="primary">Create Subscription</Button>
            </Link>
          </Typography.Paragraph>

          <Divider />
        </div>
      )}

      <Row gutter={16} justify="space-between">
        <Col>
          <Typography.Title level={5}>
            {data?.listTeamMembers?.length} humans
          </Typography.Title>
        </Col>
        {!user?.currentUser?.isTeamMember && (
          <Col>
            <Button
              type="default"
              className="invite-team-members-btn"
              onClick={() => onToggleInviteModel()}
              disabled={canInvite}
              // data-cy={DATA_TEST_IDS.TEAMS.IMPORT.BUTTON}
            >
              Invite Humans
            </Button>
          </Col>
        )}
      </Row>

      {isError && <ErrorMessage errors={error} />}

      <InviteTeamMemberModal
        onToggleInviteModel={onToggleInviteModel}
        isInviteModalOpen={isInviteModalOpen}
        refetchTeamMembers={refetch}
        companyId={company.currentCompany.id}
      />

      <ConfirmRoleChangeModal
        isOpen={isConfirmModalOpen}
        onClose={setConfirmModalOpen}
        teamMember={confirmTeamMember}
        updatedRole={confirmRoleUpdatedValue}
      />

      <ConfirmTeamMemberRemovalModal
        isOpen={isRemoveModalOpen}
        onClose={setRemoveModalOpen}
        teamMember={confirmTeamMember}
        refetch={refetch}
      />

      <Table
        pagination={{
          position: ['bottomLeft'],
          responsive: false,
        }}
        scroll={{ x: true }}
        columns={invitedUsersColumns}
        dataSource={data?.listTeamMembers}
        loading={isLoading}
      />
    </div>
  );
};
