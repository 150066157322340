import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';

import { ROUTE_PATHS } from '@/configs/routes';

import { useAuth } from '@/state/auth';

import { PublicRoute, PrivateRoute } from './routes';

import { LoginPage } from './Auth/Login';
import { Register } from './Auth/Register';
import { VerifyAccount } from './Auth/VerifyAccount';
import { ResetPasswordPage } from './Auth/ResetPassword';
import { ConfirmPasswordReset } from './Auth/ConfirmPasswordReset';
import { ChangePasswordPage } from './Auth/ChangePassword';
import { Error404 } from './Error404/Error404';

import { Home } from './Home';

import { SupportGuides } from './SupportGuides';

import { SponsorChecklist } from './SupportGuides/SponsorChecklist';
import { SponsorChecklistOne } from './SupportGuides/SponsorChecklist/Checklist/One';
import { SponsorChecklistTwo } from './SupportGuides/SponsorChecklist/Checklist/Two';
import { SponsorChecklistThree } from './SupportGuides/SponsorChecklist/Checklist/Three';
import { SponsorChecklistFour } from './SupportGuides/SponsorChecklist/Checklist/Four';
import { SponsorChecklistFive } from './SupportGuides/SponsorChecklist/Checklist/Five';
import { SponsorChecklistSix } from './SupportGuides/SponsorChecklist/Checklist/Six';

import { HowToUse } from './SupportGuides/HowToUse';
import { WhatIsCoaching } from './SupportGuides/WhatIsCoaching';
import { OnboardingCallLeaders } from './SupportGuides/OnboardingCall/Leaders';
import { OnboardingCallTeamMembers } from './SupportGuides/OnboardingCall/TeamMembers';

import { Tools } from './Tools';
import { HabitTracker } from './Tools/HabitTracker';
import { GoalPlanner } from './Tools/GoalPlanner';

import { MyAccount } from './MyAccount';
import { ContactUs } from './ContactUs';

import { Categories } from './Sessions/Category';
import { BookingSuccess } from './Sessions/BookingSuccess';
import { BookingIframeRedirect } from './Sessions/BookingIframeRedirect';
import { Coaches } from './Sessions/Coaches';
import { Coach } from './Sessions/Coach';
import { Browse } from './Sessions/Browse';
import { NextAvailable } from './Sessions/NextAvailable';
import { WhereToStart } from './Sessions/WhereToStart';

import { UserSettings } from './User/Settings';

import { CompanySettings } from './Company/Settings';
import { CompanyBilling } from './Company/Billing';
import { ManageTeam } from './Company/Team';
import { CompanyData } from './Company/Data';

import { LoadingSpinner } from '@/components/LoadingSpinner';

export const Pages = () => {
  const auth = useAuth();

  useEffect(() => {
    auth.initializeUser();
  }, []);

  if (auth.state.isAuthenticating) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <Routes>
      <Route
        path={ROUTE_PATHS.LOGIN}
        element={
          <PublicRoute>
            <LoginPage />
          </PublicRoute>
        }
      />
      <Route
        path={ROUTE_PATHS.REGISTER}
        element={
          <PublicRoute>
            <Register />
          </PublicRoute>
        }
      />
      <Route
        path={ROUTE_PATHS.VERIFY}
        element={
          <PublicRoute>
            <VerifyAccount />
          </PublicRoute>
        }
      />
      <Route
        path={ROUTE_PATHS.RESET_PASSWORD}
        element={
          <PublicRoute>
            <ResetPasswordPage />
          </PublicRoute>
        }
      />
      <Route
        path={ROUTE_PATHS.CONFIRM_RESET_PASSWORD}
        element={
          <PublicRoute>
            <ConfirmPasswordReset />
          </PublicRoute>
        }
      />
      <Route
        path={ROUTE_PATHS.CHANGE_PASSWORD}
        element={
          <PublicRoute>
            <ChangePasswordPage />
          </PublicRoute>
        }
      />
      <Route
        exact
        path={ROUTE_PATHS.DASHBOARD}
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
        page="Home"
      />
      <Route
        exact
        path={ROUTE_PATHS.SUPPORT_GUIDES.MAIN}
        element={
          <PrivateRoute>
            <SupportGuides />
          </PrivateRoute>
        }
        page="Support Guides"
      />
      <Route
        exact
        path={ROUTE_PATHS.SUPPORT_GUIDES.SPONSOR_CHECKLIST.MAIN}
        element={
          <PrivateRoute>
            <SponsorChecklist />
          </PrivateRoute>
        }
        page="Sponsor Checklist"
      />
      <Route
        exact
        path={ROUTE_PATHS.SUPPORT_GUIDES.SPONSOR_CHECKLIST.ONE}
        element={
          <PrivateRoute>
            <SponsorChecklistOne />
          </PrivateRoute>
        }
        page="Sponsor Checklist Step One"
      />
      <Route
        exact
        path={ROUTE_PATHS.SUPPORT_GUIDES.SPONSOR_CHECKLIST.TWO}
        element={
          <PrivateRoute>
            <SponsorChecklistTwo />
          </PrivateRoute>
        }
        page="Sponsor Checklist Step Two"
      />
      <Route
        exact
        path={ROUTE_PATHS.SUPPORT_GUIDES.SPONSOR_CHECKLIST.THREE}
        element={
          <PrivateRoute>
            <SponsorChecklistThree />
          </PrivateRoute>
        }
        page="Sponsor Checklist Step Three"
      />
      <Route
        exact
        path={ROUTE_PATHS.SUPPORT_GUIDES.SPONSOR_CHECKLIST.FOUR}
        element={
          <PrivateRoute>
            <SponsorChecklistFour />
          </PrivateRoute>
        }
        page="Sponsor Checklist Step Four"
      />
      <Route
        exact
        path={ROUTE_PATHS.SUPPORT_GUIDES.SPONSOR_CHECKLIST.FIVE}
        element={
          <PrivateRoute>
            <SponsorChecklistFive />
          </PrivateRoute>
        }
        page="Sponsor Checklist Step Five"
      />
      <Route
        exact
        path={ROUTE_PATHS.SUPPORT_GUIDES.SPONSOR_CHECKLIST.SIX}
        element={
          <PrivateRoute>
            <SponsorChecklistSix />
          </PrivateRoute>
        }
        page="Sponsor Checklist Step Six"
      />
      <Route
        exact
        path={ROUTE_PATHS.SUPPORT_GUIDES.HOW_TO_USE}
        element={
          <PrivateRoute>
            <HowToUse />
          </PrivateRoute>
        }
        page="Support Guides How To Use"
      />
      <Route
        exact
        path={ROUTE_PATHS.SUPPORT_GUIDES.WHAT_IS_COACHING}
        element={
          <PrivateRoute>
            <WhatIsCoaching />
          </PrivateRoute>
        }
        page="Support Guides What Is Coaching"
      />
      <Route
        exact
        path={ROUTE_PATHS.SUPPORT_GUIDES.ONBOARDING_CALL.LEADERS}
        element={
          <PrivateRoute>
            <OnboardingCallLeaders />
          </PrivateRoute>
        }
        page="Onboarding Call Leaders"
      />
      <Route
        exact
        path={ROUTE_PATHS.SUPPORT_GUIDES.ONBOARDING_CALL.TEAM_MEMBERS}
        element={
          <PrivateRoute>
            <OnboardingCallTeamMembers />
          </PrivateRoute>
        }
        page="Onboarding Call Team Members"
      />
      <Route
        exact
        path={ROUTE_PATHS.TOOLS.MAIN}
        element={
          <PrivateRoute>
            <Tools />
          </PrivateRoute>
        }
        page="Tools"
      />
      <Route
        exact
        path={ROUTE_PATHS.TOOLS.GOAL_PLANNER}
        element={
          <PrivateRoute>
            <GoalPlanner />
          </PrivateRoute>
        }
        page="Tools Goal Planner"
      />
      <Route
        exact
        path={ROUTE_PATHS.TOOLS.HABIT_TRACKER}
        element={
          <PrivateRoute>
            <HabitTracker />
          </PrivateRoute>
        }
        page="Tools Habit Tracker"
      />
      <Route
        exact
        path={ROUTE_PATHS.MY_ACCOUNT}
        element={
          <PrivateRoute>
            <MyAccount />
          </PrivateRoute>
        }
        page="My Account"
      />
      <Route
        exact
        path={ROUTE_PATHS.CONTACT_US}
        element={
          <PrivateRoute>
            <ContactUs />
          </PrivateRoute>
        }
        page="Contact Us"
      />
      <Route
        exact
        path={ROUTE_PATHS.SESSIONS.CATEGORY}
        element={
          <PrivateRoute>
            <Categories />
          </PrivateRoute>
        }
        page="Session Category"
      />
      <Route
        exact
        path={ROUTE_PATHS.SESSIONS.COACHES}
        element={
          <PrivateRoute>
            <Coaches />
          </PrivateRoute>
        }
        page="Coaches"
      />
      <Route
        exact
        path={ROUTE_PATHS.SESSIONS.BROWSE}
        element={
          <PrivateRoute>
            <Browse />
          </PrivateRoute>
        }
        page="Session Categories"
      />
      <Route
        exact
        path={ROUTE_PATHS.SESSIONS.COACH}
        element={
          <PrivateRoute>
            <Coach />
          </PrivateRoute>
        }
        page="Coach"
      />
      <Route
        exact
        path="/booking-success"
        element={
          <PrivateRoute>
            <BookingIframeRedirect />
          </PrivateRoute>
        }
        page="Booking Success"
      />
      <Route
        exact
        path={ROUTE_PATHS.SESSIONS.BOOKING_SUCCESS}
        element={
          <PrivateRoute>
            <BookingSuccess />
          </PrivateRoute>
        }
        page="Booking Success"
      />
      <Route
        exact
        path={ROUTE_PATHS.SESSIONS.WHERE_TO_START}
        element={
          <PrivateRoute>
            <WhereToStart />
          </PrivateRoute>
        }
        page="Sessions Where To Start"
      />
      <Route
        exact
        path={ROUTE_PATHS.SESSIONS.NEXT_AVAILABLE}
        element={
          <PrivateRoute>
            <NextAvailable />
          </PrivateRoute>
        }
        page="Sessions Next Available"
      />
      <Route
        exact
        path={ROUTE_PATHS.USER.SETTINGS}
        element={
          <PrivateRoute>
            <UserSettings />
          </PrivateRoute>
        }
        page="Account Settings"
      />
      <Route
        exact
        path={ROUTE_PATHS.COMPANY.SETTINGS}
        element={
          <PrivateRoute>
            <CompanySettings />
          </PrivateRoute>
        }
        page="Company Settings"
      />
      <Route
        exact
        path={ROUTE_PATHS.COMPANY.BILLING}
        element={
          <PrivateRoute>
            <CompanyBilling />
          </PrivateRoute>
        }
        page="Company Billing"
      />
      <Route
        exact
        path={ROUTE_PATHS.COMPANY.MANAGE_TEAM}
        element={
          <PrivateRoute>
            <ManageTeam />
          </PrivateRoute>
        }
        page="Company Manage Team"
      />
      <Route
        exact
        path={ROUTE_PATHS.COMPANY.DATA}
        element={
          <PrivateRoute>
            <CompanyData />
          </PrivateRoute>
        }
        page="Company Data"
      />
      <Route
        path="*"
        element={
          <PublicRoute>
            <Error404 />
          </PublicRoute>
        }
      />
    </Routes>
  );
};
