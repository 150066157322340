import { Layout } from 'antd';
import { useEffect } from 'react';

import { useNavigate } from 'react-router';

import { HtmlMeta } from '@/components/HtmlMeta';

import { isMobile } from 'react-device-detect';

import { openInNewTab } from '@/utils/helpers/app';

import { IPageBaseProps } from '@/interfaces';

interface IProps extends IPageBaseProps {}

export const WhatIsCoaching: React.FC<IProps> = () => {
  const navigate = useNavigate();

  useEffect(() => {
    openInNewTab(
      'https://midnight-revolve-64c.notion.site/What-is-coaching-877c000ec12645abba3101796bf65513',
    );

    if (isMobile) return;

    return navigate(-1);
  }, []);

  return (
    <Layout>
      <HtmlMeta title="What Is Coaching" />
    </Layout>
  );
};
