import { useState } from 'react';

import {
  Row,
  Col,
  Alert,
  List,
  Form,
  Input,
  Upload,
  Button,
  Typography,
  Space,
  Divider,
  message,
} from 'antd';
import ImgCrop from 'antd-img-crop';
import toast from 'react-hot-toast';

import { useUser } from '@/state/user';

import { IPageBaseProps } from '@/interfaces';

interface IProps extends IPageBaseProps {}

const { Title } = Typography;

export const UserNameSettings = (props: IProps) => {
  const user = useUser();

  const onUpdateUser = async (values: any) => {
    try {
      user.update({
        firstName: values.firstName,
        lastName: values.lastName,
      });

      message.success('Profile updated succesfully');
    } catch (err) {
      message.error('Failed to update name. Please try again');
    }
  };

  return (
    <>
      <Title level={2}>Update Name</Title>

      <Form
        layout="vertical"
        name="userInfo"
        onFinish={onUpdateUser}
        initialValues={{
          firstName: user.currentUser?.firstName,
          lastName: user.currentUser?.lastName,
        }}
      >
        <Form.Item name="firstName" label="First Name">
          <Input size="large" placeholder="First Name" />
        </Form.Item>
        <Form.Item name="lastName" label="Last Name">
          <Input size="large" placeholder="Second Name" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" size="large" block>
            Save changes
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
