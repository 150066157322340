import { useEffect, useState } from 'react';
import { Auth, CognitoUser } from '@aws-amplify/auth';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import qs from 'query-string';

import { Layout, Image, Input, Form, Button, Typography, Space } from 'antd';

import { useAuth } from '@/state/auth';

import { HtmlMeta } from '@/components/HtmlMeta';
import { Message } from '@/components/Message';

import { DATA_TEST_IDS } from '@/elements.config';

import { WEB_ASSETS_URL } from '@/configs/app.config';

import { Mixpanel } from '@/utils/mixpanel';

/**
 * Form Validation Schema
 */
type FormInputs = {
  password: string;
};

const defaultValues = {
  password: '',
};

export const ConfirmPasswordReset = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const query = qs.parse(location.search);

  const auth = useAuth();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [code, setCode] = useState<string | null>(null);
  const [confirmPassword, setConfirmPassword] = useState<boolean>(false);

  const email = decodeURIComponent(String(query.email));

  useEffect(() => {
    if (email && query?.code) {
      setCode(String(query.code));
      setConfirmPassword(true);
    }
  }, [email, query]);

  const onSubmit = async (values: FormInputs) => {
    setError(null);

    if (!email) {
      navigate('/auth/reset-password');
      return;
    }

    try {
      setLoading(true);

      if (!code) {
        setLoading(false);
        return;
      }

      await Auth.forgotPasswordSubmit(
        email.toLowerCase(),
        code,
        values.password,
      );

      Mixpanel.track('Password Changed');

      const user = (await Auth.signIn(
        email,
        values.password,
      )) as CognitoUser & {
        challengeName?: string;
        challengeParam: {
          requiredAttributes: string;
        };
      };

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        await Auth.completeNewPassword(user, values.password, {});
      }

      auth.initializeUser();

      setLoading(false);

      return window.location.reload();
    } catch (err) {
      // @ts-ignore
      setError(err.message);
      setLoading(false);
    }
  };

  const onSubmitFailed = (err: any) => setError(err.message);

  const onResendConfirmationCode = async () => {
    try {
      await Auth.forgotPassword(email);
    } catch (err: any) {
      return setError(err.message);
    }
  };

  return (
    <Layout className="mh-layout-public">
      <HtmlMeta title="Reset Password" />

      <Layout.Content>
        <div className="site-login-content">
          <div className="login-wrap">
            <Image
              className="login-img"
              preview={false}
              src={`${WEB_ASSETS_URL}/More_Happi_logo.svg`}
              alt="logo"
            />

            <Space direction="vertical" size={32}>
              <Typography.Title level={5}>Reset your password</Typography.Title>

              <div className="auth-form-wrap">
                {confirmPassword ? (
                  <Form
                    name="completePasswordResetForm"
                    initialValues={defaultValues}
                    layout="vertical"
                    onFinish={(values) => onSubmit(values)}
                    onFinishFailed={onSubmitFailed}
                  >
                    <Form.Item
                      label="Password"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter a password',
                        },
                        {
                          min: 8,
                          message: 'Minimum of 8 characters',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const passwordFromInput = getFieldValue('password');

                            if (!passwordFromInput.match(/(?=.*\d)/)) {
                              return Promise.reject(
                                new Error('Contains atleast one number'),
                              );
                            }

                            if (!passwordFromInput.match(/(?=.*[A-Z])/)) {
                              return Promise.reject(
                                new Error(
                                  'Contains atleast one uppercase character',
                                ),
                              );
                            }

                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        data-cy={DATA_TEST_IDS.LOGIN.EMAIL.INPUT}
                      />
                    </Form.Item>

                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={loading}
                        data-cy={DATA_TEST_IDS.LOGIN.SUBMIT.BUTTON}
                      >
                        {loading ? 'Updating... ' : 'Change Password'}
                      </Button>
                    </Form.Item>
                  </Form>
                ) : (
                  <div>
                    <p>
                      We have sent a link to {query.email} to reset your
                      password. <br /> The link expires in{' '}
                      <strong>1 hour</strong>.
                    </p>
                  </div>
                )}

                {error && <Message type="error" items={[{ message: error }]} />}

                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={loading}
                  onClick={onResendConfirmationCode}
                >
                  Resend email
                </Button>

                <br />

                <Link to="/auth/reset-password">Send another link</Link>
              </div>
            </Space>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  );
};
