import { useUser } from '@/state/user';
import { useCompany } from '@/state/company';
import { useCompanySubscription } from '@/state/company-subscription';

import { Button, message } from 'antd';

import { getItemPriceId } from '@/utils/subscription';

import {
  useGetHostedPageMutation,
  AccountType,
} from '@/graphql/types.generated';

import { Mixpanel } from '@/utils/mixpanel';

export const UpgradeSubscriptionButton = () => {
  const user = useUser();
  const company = useCompany();
  const companySubscription = useCompanySubscription();

  const hostedPageCallbackOpened = () => {
    companySubscription.toggleUpdatingSubscription();
    // @todo track checkout opened event
  };

  const hostedPageCallbackSuccess = (params: any) => {
    message.success('Thank you! Your subscription is now active');

    Mixpanel.track('Company Subscription Activated', {
      active: true,
      status: 'active',
    });

    companySubscription.setSubscriptionStatus({
      active: true,
      status: 'active',
    });

    company.update({
      accountType: AccountType.Starter,
    });

    return company.updateAdmin({
      subscriptionActive: true,
    });
  };

  const hostedPageCallbackError = () => {
    // @todo - Slack report error
    return message.error(
      'There was a problem updating your subscription. Please try again or contact us via chat',
    );
  };

  const hostedPageCallbackClose = () => {
    companySubscription.toggleUpdatingSubscription();
    // @todo query?.refetch();
  };

  const getHostedPageMutation = useGetHostedPageMutation({
    onError: () =>
      message.error(
        'There was a problem activating your subscription. Please contact us via chat.',
      ),
    onSuccess: (data) => {
      Mixpanel.track('Company Subscription Checkout Opened', {
        active: true,
        status: 'active',
      });

      const chargebeeInstance = companySubscription.getChargebeeInstance();

      chargebeeInstance?.openCheckout({
        hostedPage: () => Promise.resolve(data?.getHostedPage),
        loaded: hostedPageCallbackOpened,
        success: hostedPageCallbackSuccess,
        error: hostedPageCallbackError,
        close: hostedPageCallbackClose,
      });
    },
  });

  const onUpgrade = () => {
    if (!user.currentUser || !company.currentCompany) return;

    const itemPriceId = getItemPriceId(company.currentCompany);

    getHostedPageMutation.mutate({
      userId: user.currentUser?.id,
      companyId: company.currentCompany?.id,
      itemPriceId,
    });
  };

  const disabled = !user.currentUser?.isAdmin || getHostedPageMutation.isError;

  return (
    <Button
      onClick={onUpgrade}
      type="primary"
      disabled={disabled}
      loading={getHostedPageMutation.isLoading}
    >
      Upgrade
    </Button>
  );
};
