import { createContext, useContext } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { useAuth } from '@/state/auth';

import {
  useWhoAmIQuery,
  WhoAmIQuery,
  useUpdateUserMutation,
  UserUpdateInput,
  UserRole,
} from '@/graphql/types.generated';

import { Mixpanel } from '@/utils/mixpanel';

export type CurrentUser = WhoAmIQuery['getUser'] & {
  fullName: string;
  isAdmin: boolean;
  isSponsor: boolean;
  isTeamMember: boolean;
};

export interface IUserContext {
  isLoading: boolean;
  isError: boolean;
  currentUser?: CurrentUser | null;
  update: any;
}

const UserContext = createContext<IUserContext>({
  isLoading: false,
  isError: false,
  currentUser: undefined,
  update: undefined,
});

const withSelectors = (user: WhoAmIQuery['getUser']) => {
  return {
    ...user,
    fullName: `${user?.firstName} ${user?.lastName}`,
    isAdmin:
      user?.role === UserRole.Sponsor ||
      user?.role === UserRole.Admin ||
      user?.role === UserRole.Superadmin,
    isSponsor: user?.role === UserRole.Sponsor,
    isTeamMember: user?.role === UserRole.TeamMember,
  };
};

export const UserProvider = ({ children }: any) => {
  const queryClient = useQueryClient();

  const auth = useAuth();

  const where = {
    cognitoId: auth.state.cognitoId || '',
  };

  const { isLoading, isError, data } = useWhoAmIQuery(where, {
    enabled: auth.state.isAuthenticated,
    onSuccess: async ({ getUser }) => {
      if (!getUser) {
        console.error('User not found in database');

        // should logout user here
        await auth.logout();

        return null;
      }

      Mixpanel.identify(getUser.id);

      return Mixpanel.people.set({
        $externalId: getUser.id,
        $name: `${getUser.firstName} ${getUser.lastName}`,
        $email: getUser.email,
        $role: getUser.role,
      });
    },
  });

  const updateUser = useUpdateUserMutation({
    onSuccess: () => {
      Mixpanel.track('User Updated');

      return queryClient.invalidateQueries(['WhoAmI', where]);
    },
  });

  const update = (input: UserUpdateInput) =>
    updateUser.mutate({ where: { id: data?.getUser?.id }, data: input });

  return (
    <UserContext.Provider
      value={{
        isLoading,
        isError,
        currentUser:
          data?.getUser && (withSelectors(data?.getUser) as CurrentUser),
        update,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
