import { Button, message } from 'antd';

import { useUser } from '@/state/user';
import { useCompany } from '@/state/company';

import { FlashNotice } from '@/components/FlashNotice';

import {
  UserRole,
  useAdminImpersonateCompanyMutation,
} from '@/graphql/types.generated';

export const ImpersonationNotice = () => {
  const user = useUser();
  const company = useCompany();

  const adminImpersonateCompany = useAdminImpersonateCompanyMutation({
    onSuccess: () => window.location.reload(),
    onError: () =>
      message.error(
        `There was a problem stoppin impersonation. Please contact engineering`,
      ),
  });

  const onStopImpersonating = () => {
    if (
      user?.currentUser?.role !== UserRole.Superadmin ||
      !company?.currentCompany?.id
    )
      return;

    return adminImpersonateCompany.mutate({
      userId: user.currentUser?.id,
      companyId: company?.currentCompany?.id,
    });
  };

  return (
    <FlashNotice type="error">
      Impersonating {company?.currentCompany?.name} as {user.currentUser?.email}
      <Button
        type="link"
        style={{ color: '#fff' }}
        onClick={onStopImpersonating}
        loading={adminImpersonateCompany.isLoading}
        disabled={adminImpersonateCompany.isError}
      >
        Stop Impersonating
      </Button>
    </FlashNotice>
  );
};
