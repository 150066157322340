import { Layout, PageHeader, Row, Col, Typography } from 'antd';

import { PageHeaderBlock } from '@/components/PageHeaderBlock';
import { HtmlMeta } from '@/components/HtmlMeta';
import { ActionTile } from '@/components/ActionTile';
import { useUser } from '@/state/user';
import { useIntercom } from 'react-use-intercom';
import { IPageBaseProps } from '@/interfaces';
import { theme } from '@/configs/theme';

interface IProps extends IPageBaseProps {}

export const ContactUs: React.FC<IProps> = () => {
  const { show } = useIntercom();

  const user = useUser();

  const SESSION_NAVIGATION_ITEMS = [
    {
      id: 1,
      navTitle: 'Support Guides',
      title: `FAQs.`,
      href: 'https://intercom.help/more-happi/en/collections/2828356-faqs',
      color: theme.colors.blue,
    },
    {
      id: 2,
      navTitle: 'Contact Us',
      title: `Live chat here.`,
      to: '#',
      onClick: show,
      color: theme.colors.darkGreen,
    },
  ];

  const adminCopy = () => {
    return (
      <>
        <Typography.Paragraph>
          We’re here when you need us. Use our directory below to make sure you
          get to the right support.
        </Typography.Paragraph>

        <Typography.Paragraph>
          General + technical enquiries{' '}
          <a href="mailto:hey@morehappi.com">hey@morehappi.com</a> or Live chat
          for help with:
        </Typography.Paragraph>

        <ul>
          <li>Technical issues</li>
          <li>Session bookings</li>
          <li>Billing / invoicing (monthly subscriptions)</li>
          <li>General queries</li>
        </ul>

        <Typography.Paragraph>
          We will respond to you within 24 hours.
        </Typography.Paragraph>

        <Typography.Title level={5}>
          Customer success & account upgrades
        </Typography.Title>

        <Typography.Paragraph>
          <a href="mailto:hannahr@morehappi.com">hannahr@morehappi.com</a> (Mon
          - Thurs)
        </Typography.Paragraph>

        <ul>
          <li>
            Integrating More Happi into your team’s day-to-day (Enterprise
            customers only)
          </li>
          <li>
            Subscription upgrades, renewals, overseas teams (Enterprise
            customers only)
          </li>
          <li>Switching from a monthly to enterprise subscription</li>
        </ul>
      </>
    );
  };

  const teamMemberCopy = () => {
    return (
      <>
        <Typography.Paragraph>
          We’re here when you need us. Use our directory below to make sure you
          get to the right support.
        </Typography.Paragraph>

        <Typography.Title level={5}>
          General & technical enquiries
        </Typography.Title>

        <Typography.Paragraph>
          <a href="mailto:hannahr@morehappi.com">hey@morehappi.com</a> or Live
          chat for help with:
        </Typography.Paragraph>

        <ul>
          <li>Technical issues</li>
          <li>Session bookings</li>
        </ul>

        <Typography.Paragraph>
          We’ll respond to you within 24 hours.
        </Typography.Paragraph>
      </>
    );
  };

  return (
    <Layout>
      <HtmlMeta title="Contact Us" />

      <Layout.Content className="site-layout-content">
        <PageHeader title="Contact Us" ghost className="site-page-header">
          <PageHeaderBlock
            image={{
              src: '/images/Contact_us.svg',
              alt: 'Contact Us',
            }}
          >
            {user.currentUser?.isAdmin ? adminCopy() : teamMemberCopy()}
          </PageHeaderBlock>
        </PageHeader>

        <Row
          className="mh-row-top-md"
          gutter={{ xs: 4, sm: 8, md: 16, lg: 24 }}
        >
          {SESSION_NAVIGATION_ITEMS.map((item: any) => (
            <Col
              className="mh-vertical-spacer"
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={6}
            >
              <ActionTile {...item} />
            </Col>
          ))}
        </Row>
      </Layout.Content>
    </Layout>
  );
};
