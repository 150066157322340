import { Link } from 'react-router-dom';
import type { FC } from 'react';
import { Row, Col, Typography, Divider, Table, Space } from 'antd';

import { CurrentCompany, useCompany } from '@/state/company';
import { useCompanySubscription } from '@/state/company-subscription';
import { ManageSubscriptionButton } from '@/components/ManageSubscriptionButton';
import { UpgradeSubscriptionButton } from '@/components/UpgradeSubscriptionButton';
import { pricingTierTableColumns } from '@/utils/table_data/pricingTierTableInfo';
import { getTablePricingData } from '@/utils/subscription';
import { IPageBaseProps } from '@/interfaces';
import { PricingTable } from './PricingTable';

interface IProps extends IPageBaseProps {}

const { Title, Text } = Typography;

const contactOptions = (
  <>
    <Title level={4}>Need to discuss your subscription?</Title>
    <Text>
      Talk to us in the chat on the bottom right hand side of the screen or
      email us at <Link to="mailto:hey@morehappi.com">hey@morehappi.com</Link>
    </Text>
  </>
);

export const CompanySubscriptionSettings = (props: IProps) => {
  const { currentCompany } = useCompany();

  const companySubscription = useCompanySubscription();

  if (!currentCompany) {
    return null;
  }

  const { isActive, isCancelled } = companySubscription.state;

  const notEnterprise = !currentCompany?.isEnterprise;
  const neverSubscribed = !isActive && !isCancelled;
  const isActiveNotCancelled = isActive && !isCancelled;

  return (
    <>
      {notEnterprise && (
        <>
          {neverSubscribed && (
            <Row gutter={24} wrap={false} align="middle">
              <Col>
                <Title level={5}>
                  There is no active subscription for your team yet!
                </Title>
                <Text className="mt-xl">
                  Your company will need an active subscription to invite team
                  members and book coaching sessions.
                </Text>
              </Col>
              <Col>
                <UpgradeSubscriptionButton />
              </Col>
            </Row>
          )}

          {isActiveNotCancelled && (
            <Row gutter={24} wrap={false} align="middle">
              <Col>
                <Title level={5}>Manage Billing Information</Title>
                <Text className="mt-xl">
                  Your subscription is currently{' '}
                  <strong>{companySubscription.state.status}</strong>. Update
                  your payment method, reactivate or cancel your subscription
                  below.
                </Text>
              </Col>
              <Col>
                <ManageSubscriptionButton />
              </Col>
            </Row>
          )}

          {isCancelled && (
            <Row gutter={24} wrap={false} align="middle">
              <Col>
                <Title level={5}>Manage Billing Information</Title>
                <Text className="mt-xl">
                  Your subscription has been cancelled and your payment method
                  will no longer be charged. You will not be able to access More
                  Happi coaching after your billing cycle ends on{' '}
                  {companySubscription.state?.cancellingAt}
                </Text>
              </Col>
              <Col>
                <ManageSubscriptionButton text="Resume Subscription" />
              </Col>
            </Row>
          )}
          <PricingTable company={currentCompany} />
        </>
      )}
      {contactOptions}
    </>
  );
};
