import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout, PageHeader } from 'antd';

import { HtmlMeta } from '@/components/HtmlMeta';
import { TeamList } from '@/components/TeamList';

import { useScrollTo, useRoleRedirect } from '@/hooks';

import { UserRole } from '@/graphql/types.generated';

import { IPageBaseProps } from '@/interfaces';
import { ROUTE_PATHS } from '@/configs/routes';
import { BackIcon } from '@/icons';

interface IProps extends IPageBaseProps {}

export const ManageTeam: React.FC<IProps> = () => {
  const navigate = useNavigate();
  useScrollTo();

  useRoleRedirect([UserRole.Admin, UserRole.Sponsor, UserRole.Superadmin]);

  return (
    <Layout>
      <HtmlMeta title="Manage Team" />
      <Layout.Content className="site-layout-content">
        <PageHeader
          ghost
          title="Manage Team"
          onBack={() => navigate(ROUTE_PATHS.MY_ACCOUNT)}
          backIcon={<BackIcon style={{ fontSize: '40px' }} />}
        />
        <TeamList />
      </Layout.Content>
    </Layout>
  );
};
