import { Layout, PageHeader, Row, Col, Typography, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

import { HtmlMeta } from '@/components/HtmlMeta';
import { openInNewTab } from '@/utils/helpers/app';
import { Mixpanel } from '@/utils/mixpanel';
import { IPageBaseProps } from '@/interfaces';
import { ROUTE_PATHS } from '@/configs/routes';
import { BackIcon } from '@/icons';

interface IProps extends IPageBaseProps {}

export const OnboardingCallLeaders: React.FC<IProps> = () => {
  const navigate = useNavigate();
  return (
    <Layout>
      <HtmlMeta title="Live Onboarding Call" />
      <Layout.Content className="site-layout-content thin">
        <PageHeader
          title="Attend a live onboarding call"
          ghost
          className="site-page-header"
          onBack={() =>
            navigate(ROUTE_PATHS.SUPPORT_GUIDES.SPONSOR_CHECKLIST.MAIN)
          }
          backIcon={<BackIcon style={{ fontSize: '40px' }} />}
        />
        <Typography.Title level={4}>What’s this all about?</Typography.Title>

        <Typography.Paragraph>
          Whether you’re brand new to coaching, or already a super fan, we
          encourage <strong>everyone </strong>to join our onboarding sessions so
          that you know exactly what More Happi coaching is and how it can help
          you thrive. Sign up to the next available date today!
        </Typography.Paragraph>

        <Typography.Title level={4}>
          Intro to coaching for leaders
        </Typography.Title>

        <Typography.Title level={5}>What:</Typography.Title>

        <Typography.Paragraph>
          A detailed dive into how More Happi coaching can support people
          managers and their teams alike. From coaching for leadership, to more
          efficient 1-2-1s, we will run through some use-cases and a handy tool
          to help facilitate and monitor growth conversations and prepare for
          coaching sessions.
        </Typography.Paragraph>

        <Typography.Title level={5}>When:</Typography.Title>

        <Typography.Paragraph>
          Wednesdays, 1pm (once a month)
        </Typography.Paragraph>

        <Typography.Title level={5}>How long:</Typography.Title>

        <Typography.Paragraph>60 minutes</Typography.Paragraph>

        <Typography.Title level={5}>Who is it for:</Typography.Title>

        <Typography.Paragraph>
          Anyone who manages direct reports, from seasoned leaders, to those new
          to people management
        </Typography.Paragraph>

        <Button
          type="primary"
          size="large"
          onClick={() => {
            Mixpanel.track('Onboarding Call Leaders Registration Clicked');
            openInNewTab(
              'https://us06web.zoom.us/webinar/register/WN_hhB2QNBrQ0KKBQe8GKhTzw',
            );
          }}
        >
          Register Now
        </Button>
      </Layout.Content>
    </Layout>
  );
};
