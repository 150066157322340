import { useState, useEffect } from 'react';
import { useIntercom as useIntercomClient } from 'react-use-intercom';

import { useUser } from '@/state/user';

export const useIntercom = () => {
  const user = useUser();

  const { show, boot, update } = useIntercomClient();

  useEffect(() => {
    if (user) {
      boot({
        userId: user.currentUser?.id,
        email: user.currentUser?.email,
      });
      update({
        userId: user.currentUser?.id,
        email: user.currentUser?.email,
      });
    } else {
      boot();
      show();
    }
  }, [boot, update, user]);
};
