import Auth, { CognitoUser } from '@aws-amplify/auth';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Typography,
  Space,
  Divider,
  message,
} from 'antd';

import { useAuth } from '@/state/auth';

import { IPageBaseProps } from '@/interfaces';

interface IProps extends IPageBaseProps {}

const { Title, Text } = Typography;

export const UserPasswordSettings = (props: IProps) => {
  const auth = useAuth();

  const onUpdatePassword = async (values: any) => {
    try {
      const cognitoUser: CognitoUser = await auth.getCurrentCognitoUser();

      await Auth.changePassword(
        cognitoUser,
        values.oldPassword,
        values.newPassword,
      );

      message.success('Password changed. Use it when you log in next.');

      return;
    } catch (err) {
      message.error('Failed to update password. Please try again');
    }
  };

  return (
    <>
      <Title level={2}>Update Password</Title>
      <Form
        layout="vertical"
        name="passwordUpdated"
        onFinish={onUpdatePassword}
        initialValues={{}}
      >
        <Form.Item
          name="oldPassword"
          label="Current Password"
          rules={[
            {
              required: true,
              message: 'Please enter your old password',
            },
          ]}
        >
          <Input.Password size="large" />
        </Form.Item>
        <Form.Item
          name="newPassword"
          label="New password"
          rules={[
            {
              required: true,
              message: 'Please enter your new password',
            },
          ]}
        >
          <Input.Password size="large" />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          label="Confirm new password"
          dependencies={['newPassword']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your new password',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  new Error('The two passwords that you entered do not match!'),
                );
              },
            }),
          ]}
        >
          <Input.Password size="large" />
        </Form.Item>

        <Button type="primary" htmlType="submit" size="large" block>
          Update password
        </Button>
      </Form>
    </>
  );
};
