import { Avatar, Card, Dropdown, Menu, type MenuProps } from 'antd';

import { NavLink } from 'react-router-dom';
import { useAuth } from '@/state/auth';
import { type IUserContext, useUser } from '@/state/user';
import { ROUTE_PATHS } from '@/configs/routes';
import React from 'react';
import { WEB_ASSETS_URL } from '@/configs/app.config';
import { type ICompanyContext, useCompany } from '@/state/company';

const { Meta } = Card;

const getMenu = ({
  user,
  company,
  menuProps = {},
}: {
  user: IUserContext;
  company: ICompanyContext;
  menuProps?: MenuProps;
}) => {
  const auth = useAuth();

  return (
    <Menu {...menuProps}>
      <Menu.Item>
        <NavLink to={ROUTE_PATHS.USER.SETTINGS}>Edit Profile</NavLink>
      </Menu.Item>

      {user.currentUser?.isAdmin && (
        <>
          <Menu.Item>
            <NavLink to={ROUTE_PATHS.COMPANY.SETTINGS}>Edit Company</NavLink>
          </Menu.Item>
          <Menu.Item>
            <NavLink to={ROUTE_PATHS.MY_ACCOUNT}>Manage Account</NavLink>
          </Menu.Item>
        </>
      )}

      <Menu.Item onClick={auth.logout}>Sign out</Menu.Item>
    </Menu>
  );
};

export const AccountMenu: React.FC<{ mobile?: boolean }> = ({
  mobile = false,
}) => {
  const user = useUser();
  const company = useCompany();

  if (mobile) {
    return getMenu({
      user,
      company,
      menuProps: {
        defaultSelectedKeys: [],
        mode: 'vertical',
        theme: 'light',
        className: 'mh-menu',
        disabledOverflow: true,
      },
    });
  }

  const menu = getMenu({ user, company });

  return (
    <Dropdown overlay={menu} trigger={['click']} placement="topRight">
      <Avatar
        size="large"
        src={
          user.currentUser?.avatarKey ||
          `${WEB_ASSETS_URL}/User_Icon_Smiley_round.svg`
        }
      />
    </Dropdown>
  );
};
