import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const BackSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 43 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.5 40.6875C32.3731 40.6875 41.1875 31.8731 41.1875 21C41.1875 10.1269 32.3731 1.3125 21.5 1.3125C10.6269 1.3125 1.8125 10.1269 1.8125 21C1.8125 31.8731 10.6269 40.6875 21.5 40.6875Z"
      fill="#F7C421"
    />
    <path
      d="M18.8753 13.7812L10.3441 21L18.8753 28.2188M33.3115 21.0013H10.3428H33.3115Z"
      stroke="#1E1E1E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const BackIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={BackSvg} {...props} />
);
