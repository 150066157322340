import { Alert, List } from 'antd';

import { ICompBaseProps } from '@/interfaces';

interface IProps extends ICompBaseProps {
  errors: Array<{ message: string }>;
}

export const ErrorMessage = (props: IProps): any =>
  props.errors !== undefined &&
  props.errors.length > 0 && (
    <Alert
      message={
        <List
          dataSource={props.errors}
          renderItem={(item) => {
            const isErrorString = typeof item === 'string';
            return <List.Item>{isErrorString ? item : item.message}</List.Item>;
          }}
        />
      }
      showIcon
      type="error"
      style={{ textAlign: 'left' }}
    />
  );
