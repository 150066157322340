import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { Mixpanel } from '@/utils/mixpanel';

import { ICompBaseProps } from '@/interfaces';

interface IProps extends ICompBaseProps {
  title: React.ReactNode;
  disableSiteName?: boolean;
}

export const HtmlMeta: React.FC<IProps> = (props) => {
  useEffect(() => {
    Mixpanel.track(`${props.title} Page Viewed`);
  }, []);

  return (
    <Helmet>
      <title>{props.title || ''} | More Happi</title>
    </Helmet>
  );
};
