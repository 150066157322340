import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Layout, PageHeader } from 'antd';

import { HtmlMeta } from '@/components/HtmlMeta';
import { Iframe } from '@/components/Iframe';
import { SubscriptionPermission } from '@/components/SubscriptionPermission';

import { useUser } from '@/state/user';
import { useCompany } from '@/state/company';

import { getYouCanBookMeParams } from '@/utils/getYouCanBookMeParams';
import { createUrl } from '@/utils/createUrl';

import { IPageBaseProps } from '@/interfaces';

import { app } from '@/configs/app.config';

import { ROUTE_PATHS } from '@/configs/routes';
import { BackIcon } from '@/icons';

interface IProps extends IPageBaseProps {}

const CATEGORIES = {
  relationships: {
    title: 'Relationships',
    url: app.COACHING_URLS.RELATIONSHIPS,
  },
  purpose: {
    title: 'Purpose',
    url: app.COACHING_URLS.PURPOSE,
  },
  self: {
    title: 'Self',
    url: app.COACHING_URLS.SELF,
  },
  parenting: {
    title: 'Parenting',
    url: app.COACHING_URLS.PARENTING,
  },
  work: {
    title: 'Work',
    url: app.COACHING_URLS.WORK,
  },
};

export const Categories: React.FC<IProps> = () => {
  const navigate = useNavigate();
  const params = useParams();

  const user = useUser();
  const company = useCompany();

  useEffect(() => {
    if (!params.category) {
      return navigate(ROUTE_PATHS.SESSIONS.COACHES);
    }
  }, [params]);

  // @ts-ignore
  const coachingCategory = CATEGORIES[params.category as string];

  const ycbmParams = getYouCanBookMeParams({
    fullName: user?.currentUser?.fullName,
    companyName: company?.currentCompany?.name,
    email: user?.currentUser?.email,
  });

  const queryStringParams = {
    ...ycbmParams,
  };

  const fullUrl = createUrl(coachingCategory.url, queryStringParams);

  const iframe = `
    <iframe
      src='${fullUrl}'
      id='ycbmiframemorehappi-${coachingCategory}'
      style='width:100%;height:1000px;border:0px;background-color:white'
      frameborder='0'
      allowtransparency='true'
      sandbox='allow-same-origin allow-top-navigation allow-scripts allow-forms'
    ></iframe>
  `;

  return (
    <SubscriptionPermission required>
      <Layout>
        <HtmlMeta title="Category" />
        <Layout.Content>
          <PageHeader
            ghost
            className="site-page-header"
            onBack={() => navigate('/browse')}
            backIcon={<BackIcon style={{ fontSize: '40px' }} />}
            title="Book a session"
          />

          <Layout.Content className="coaches-layout-content">
            <Iframe iframe={iframe} />
          </Layout.Content>
        </Layout.Content>
      </Layout>
    </SubscriptionPermission>
  );
};
