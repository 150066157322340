import React from 'react';

import cx from 'classnames';
import { SiteHeader } from '@/components/Siteheader';

import { useIsMobile, usePageTracking, useIntercom } from '@/hooks';

export interface IProps {
  children: React.ReactNode;
  disableHeader?: boolean;
  disableFooter?: boolean;
}

export const PublicLayout: React.FC<IProps> = (props) => {
  usePageTracking();
  useIntercom();

  return <div className="mh-layout">{props.children}</div>;
};
