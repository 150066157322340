import styles from './styles.module.less';

import cx from 'classnames';

interface Props {
  type?: 'error' | 'info';
  children: any;
}

export const FlashNotice = ({ type = 'info', children }: Props) => (
  <div className={cx(styles['flash-notice-wrapper'], styles[type])}>
    <span className={styles.content}>{children}</span>
  </div>
);
