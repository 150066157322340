import React from 'react';
import { Layout, PageHeader } from 'antd';
import { useNavigate } from 'react-router-dom';

import { HtmlMeta } from '@/components/HtmlMeta';
import { CompanyNameSettings } from '@/components/Settings/CompanyName';
import { useScrollTo, useRoleRedirect } from '@/hooks';
import { UserRole } from '@/graphql/types.generated';
import { IPageBaseProps } from '@/interfaces';
import { ROUTE_PATHS } from '@/configs/routes';
import { BackIcon } from '@/icons';

interface IProps extends IPageBaseProps {}

export const CompanySettings: React.FC<IProps> = () => {
  const navigate = useNavigate();
  useScrollTo();

  useRoleRedirect([UserRole.Admin, UserRole.Sponsor, UserRole.Superadmin]);

  return (
    <Layout>
      <HtmlMeta title="Company Settings" />
      <Layout.Content className="site-layout-content thin">
        <PageHeader
          ghost
          className="site-page-header"
          title="Company Settings"
          onBack={() => navigate(ROUTE_PATHS.MY_ACCOUNT)}
          backIcon={<BackIcon style={{ fontSize: '40px' }} />}
        />
        <CompanyNameSettings />
      </Layout.Content>
    </Layout>
  );
};
