import { useMutation, useQuery, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
import { fetchData } from './client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `AWSDateTime` scalar type provided by AWS AppSync, represents a valid
   * ***extended*** [ISO 8601 DateTime](https://en.wikipedia.org/wiki/ISO_8601#Combined_date_and_time_representations)
   * string. In other words, this scalar type accepts datetime strings of the form
   * `YYYY-MM-DDThh:mm:ss.SSSZ`.  The scalar can also accept "negative years" of the
   * form `-YYYY` which correspond to years before `0000`. For example,
   * "**-2017-01-01T00:00Z**" and "**-9999-01-01T00:00Z**" are both valid datetime
   * strings.  The field after the two digit seconds field is a nanoseconds field. It
   * can accept between 1 and 9 digits. So, for example,
   * "**1970-01-01T12:00:00.2Z**", "**1970-01-01T12:00:00.277Z**" and
   * "**1970-01-01T12:00:00.123456789Z**" are all valid datetime strings.  The
   * seconds and nanoseconds fields are optional (the seconds field must be specified
   * if the nanoseconds field is to be used).  The [time zone
   * offset](https://en.wikipedia.org/wiki/ISO_8601#Time_zone_designators) is
   * compulsory for this scalar. The time zone offset must either be `Z`
   * (representing the UTC time zone) or be in the format `±hh:mm:ss`. The seconds
   * field in the timezone offset will be considered valid even though it is not part
   * of the ISO 8601 standard.
   */
  AWSDateTime: any;
  /**
   * The `AWSEmail` scalar type provided by AWS AppSync, represents an Email address
   * string that complies with [RFC 822](https://www.ietf.org/rfc/rfc822.txt). For
   * example, "**username@example.com**" is a valid Email address.
   */
  AWSEmail: any;
  /**
   * The `AWSJSON` scalar type provided by AWS AppSync, represents a JSON string that
   * complies with [RFC 8259](https://tools.ietf.org/html/rfc8259).  Maps like
   * "**{\\"upvotes\\": 10}**", lists like "**[1,2,3]**", and scalar values like
   * "**\\"AWSJSON example string\\"**", "**1**", and "**true**" are accepted as
   * valid JSON and will automatically be parsed and loaded in the resolver mapping
   * templates as Maps, Lists, or Scalar values rather than as the literal input
   * strings.  Invalid JSON strings like "**{a: 1}**", "**{'a': 1}**" and "**Unquoted
   * string**" will throw GraphQL validation errors.
   */
  AWSJSON: any;
  /**
   * The `AWSURL` scalar type provided by AWS AppSync, represents a valid URL string
   * (Ex: <https://www.amazon.com/>). The URL may use any scheme and may also be a
   * local URL (Ex: <http://localhost/>).  URLs without schemes like "**amazon.com**"
   * or "**www.amazon.com**" are considered invalid. URLs which contain double
   * slashes (two consecutive forward slashes) in their path are also considered invalid.
   */
  AWSURL: any;
};

export type AwsDateTimeFilter = {
  equals?: InputMaybe<Scalars['AWSDateTime']>;
  gt?: InputMaybe<Scalars['AWSDateTime']>;
  gte?: InputMaybe<Scalars['AWSDateTime']>;
  in?: InputMaybe<Array<Scalars['AWSDateTime']>>;
  lt?: InputMaybe<Scalars['AWSDateTime']>;
  lte?: InputMaybe<Scalars['AWSDateTime']>;
  not?: InputMaybe<AwsDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['AWSDateTime']>>;
};

export type AwsEmailFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['AWSEmail']>;
  in?: InputMaybe<Array<Scalars['AWSEmail']>>;
  not?: InputMaybe<AwsEmailFilter>;
  notIn?: InputMaybe<Array<Scalars['AWSEmail']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type AwsjsonFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['AWSJSON']>;
  in?: InputMaybe<Array<Scalars['AWSJSON']>>;
  not?: InputMaybe<AwsjsonFilter>;
  notIn?: InputMaybe<Array<Scalars['AWSJSON']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type AwsurlFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['AWSURL']>;
  in?: InputMaybe<Array<Scalars['AWSURL']>>;
  not?: InputMaybe<AwsurlFilter>;
  notIn?: InputMaybe<Array<Scalars['AWSURL']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export enum AccountType {
  Enterprise = 'enterprise',
  EnterpriseTrial = 'enterprise_trial',
  Free = 'free',
  Pro = 'pro',
  Restricted = 'restricted',
  Starter = 'starter'
}

export type AccountTypeEnumFilter = {
  equals?: InputMaybe<AccountType>;
  in?: InputMaybe<Array<AccountType>>;
  not?: InputMaybe<AccountTypeEnumFilter>;
  notIn?: InputMaybe<Array<AccountType>>;
};

export enum ActivationState {
  Activated = 'activated',
  Disabled = 'disabled',
  Invited = 'invited',
  Pending = 'pending'
}

export type ActivationStateEnumFilter = {
  equals?: InputMaybe<ActivationState>;
  in?: InputMaybe<Array<ActivationState>>;
  not?: InputMaybe<ActivationStateEnumFilter>;
  notIn?: InputMaybe<Array<ActivationState>>;
};

export type AdminCreateCompanyInput = {
  accountType: AccountType;
  chargebeeCustomerId?: InputMaybe<Scalars['String']>;
  hubspotCompanyId?: InputMaybe<Scalars['String']>;
  intercomCompanyId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  sponsorEmail: Scalars['String'];
  sponsorFirstName: Scalars['String'];
  sponsorLastName: Scalars['String'];
};

export type BatchPayload = {
  __typename?: 'BatchPayload';
  count?: Maybe<Scalars['Int']>;
};

export type BooleanFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<BooleanFilter>;
};

export type CalendarSetting = {
  __typename?: 'CalendarSetting';
  coach?: Maybe<Coach>;
  coachId?: Maybe<Scalars['String']>;
  createdAt: Scalars['AWSDateTime'];
  externalId: Scalars['String'];
  id: Scalars['String'];
  lastSynced?: Maybe<Scalars['AWSDateTime']>;
  nextSyncToken?: Maybe<Scalars['String']>;
  pushChannelId?: Maybe<Scalars['String']>;
  pushExpiresAt?: Maybe<Scalars['AWSDateTime']>;
  pushResourceId?: Maybe<Scalars['String']>;
  pushResourceUri?: Maybe<Scalars['String']>;
  source: CalendarSource;
  updatedAt: Scalars['AWSDateTime'];
};

export type CalendarSettingCoachCreateRelationInput = {
  connect?: InputMaybe<CoachWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachConnectOrCreateInput>;
  create?: InputMaybe<CoachCreateInput>;
};

export type CalendarSettingCoachUpdateRelationsInput = {
  connect?: InputMaybe<CoachWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachConnectOrCreateInput>;
  create?: InputMaybe<CoachCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CoachUpdateInput>;
  upsert?: InputMaybe<CoachUpsertInput>;
};

export type CalendarSettingConnectOrCreateInput = {
  create: CalendarSettingCreateInput;
  where: CalendarSettingWhereUniqueInput;
};

export type CalendarSettingCreateInput = {
  coach?: InputMaybe<CalendarSettingCoachCreateRelationInput>;
  externalId: Scalars['String'];
  lastSynced?: InputMaybe<Scalars['AWSDateTime']>;
  nextSyncToken?: InputMaybe<Scalars['String']>;
  pushChannelId?: InputMaybe<Scalars['String']>;
  pushExpiresAt?: InputMaybe<Scalars['AWSDateTime']>;
  pushResourceId?: InputMaybe<Scalars['String']>;
  pushResourceUri?: InputMaybe<Scalars['String']>;
  source: CalendarSource;
};

export type CalendarSettingCreateManyInput = {
  externalId: Scalars['String'];
  lastSynced?: InputMaybe<Scalars['AWSDateTime']>;
  nextSyncToken?: InputMaybe<Scalars['String']>;
  pushChannelId?: InputMaybe<Scalars['String']>;
  pushExpiresAt?: InputMaybe<Scalars['AWSDateTime']>;
  pushResourceId?: InputMaybe<Scalars['String']>;
  pushResourceUri?: InputMaybe<Scalars['String']>;
  source: CalendarSource;
};

export type CalendarSettingDeleteManyInput = {
  where: CalendarSettingWhereInput;
};

export type CalendarSettingDeleteUniqueInput = {
  where: CalendarSettingWhereUniqueInput;
};

export type CalendarSettingFilter = {
  every?: InputMaybe<CalendarSettingScalarWhereInput>;
  none?: InputMaybe<CalendarSettingScalarWhereInput>;
  some?: InputMaybe<CalendarSettingScalarWhereInput>;
};

export type CalendarSettingOrderByInput = {
  coach?: InputMaybe<CoachOrderByInput>;
  coachId?: InputMaybe<OrderByArg>;
  createdAt?: InputMaybe<OrderByArg>;
  externalId?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  lastSynced?: InputMaybe<OrderByArg>;
  nextSyncToken?: InputMaybe<OrderByArg>;
  pushChannelId?: InputMaybe<OrderByArg>;
  pushExpiresAt?: InputMaybe<OrderByArg>;
  pushResourceId?: InputMaybe<OrderByArg>;
  pushResourceUri?: InputMaybe<OrderByArg>;
  source?: InputMaybe<OrderByArg>;
  updatedAt?: InputMaybe<OrderByArg>;
};

export type CalendarSettingRelationFilter = {
  coachId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  externalId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  lastSynced?: InputMaybe<AwsDateTimeFilter>;
  nextSyncToken?: InputMaybe<StringFilter>;
  pushChannelId?: InputMaybe<StringFilter>;
  pushExpiresAt?: InputMaybe<AwsDateTimeFilter>;
  pushResourceId?: InputMaybe<StringFilter>;
  pushResourceUri?: InputMaybe<StringFilter>;
  source?: InputMaybe<CalendarSourceEnumFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type CalendarSettingScalarWhereInput = {
  coachId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  externalId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  lastSynced?: InputMaybe<AwsDateTimeFilter>;
  nextSyncToken?: InputMaybe<StringFilter>;
  pushChannelId?: InputMaybe<StringFilter>;
  pushExpiresAt?: InputMaybe<AwsDateTimeFilter>;
  pushResourceId?: InputMaybe<StringFilter>;
  pushResourceUri?: InputMaybe<StringFilter>;
  source?: InputMaybe<CalendarSourceEnumFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type CalendarSettingUpdateInput = {
  coach?: InputMaybe<CalendarSettingCoachUpdateRelationsInput>;
  externalId?: InputMaybe<Scalars['String']>;
  lastSynced?: InputMaybe<Scalars['AWSDateTime']>;
  nextSyncToken?: InputMaybe<Scalars['String']>;
  pushChannelId?: InputMaybe<Scalars['String']>;
  pushExpiresAt?: InputMaybe<Scalars['AWSDateTime']>;
  pushResourceId?: InputMaybe<Scalars['String']>;
  pushResourceUri?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<CalendarSource>;
};

export type CalendarSettingUpdateManyInput = {
  data: CalendarSettingUpdateInput;
  where: CalendarSettingWhereInput;
};

export type CalendarSettingUpdateUniqueInput = {
  data: CalendarSettingUpdateInput;
  where: CalendarSettingWhereUniqueInput;
};

export type CalendarSettingUpsertInput = {
  create: CalendarSettingCreateInput;
  update: CalendarSettingUpdateInput;
};

export type CalendarSettingUpsertUniqueInput = {
  create: CalendarSettingCreateInput;
  update: CalendarSettingUpdateInput;
  where: CalendarSettingWhereUniqueInput;
};

export type CalendarSettingWhereInput = {
  AND?: InputMaybe<Array<InputMaybe<CalendarSettingWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<CalendarSettingWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<CalendarSettingWhereInput>>>;
  coach?: InputMaybe<CoachRelationFilter>;
  coachId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  externalId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  lastSynced?: InputMaybe<AwsDateTimeFilter>;
  nextSyncToken?: InputMaybe<StringFilter>;
  pushChannelId?: InputMaybe<StringFilter>;
  pushExpiresAt?: InputMaybe<AwsDateTimeFilter>;
  pushResourceId?: InputMaybe<StringFilter>;
  pushResourceUri?: InputMaybe<StringFilter>;
  source?: InputMaybe<CalendarSourceEnumFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type CalendarSettingWhereUniqueInput = {
  coachId?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export enum CalendarSource {
  Google = 'GOOGLE'
}

export type CalendarSourceEnumFilter = {
  equals?: InputMaybe<CalendarSource>;
  in?: InputMaybe<Array<CalendarSource>>;
  not?: InputMaybe<CalendarSourceEnumFilter>;
  notIn?: InputMaybe<Array<CalendarSource>>;
};

export type Coach = {
  __typename?: 'Coach';
  approaches?: Maybe<Array<CoachApproach>>;
  audienceExperiences?: Maybe<Array<CoachAudienceExperience>>;
  avatarKey?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  calendarSettings?: Maybe<CalendarSetting>;
  categories?: Maybe<Array<CoachCategory>>;
  coachingSince?: Maybe<Scalars['AWSDateTime']>;
  createdAt: Scalars['AWSDateTime'];
  displayName?: Maybe<Scalars['String']>;
  events?: Maybe<Array<CoachEvent>>;
  gender?: Maybe<Gender>;
  handle?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  languages?: Maybe<Scalars['AWSJSON']>;
  position?: Maybe<Scalars['Int']>;
  pronouns?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  reviews?: Maybe<Array<CoachReview>>;
  sessions?: Maybe<Array<Session>>;
  specialisms?: Maybe<Array<CoachSpecialism>>;
  trainingSchool?: Maybe<Scalars['String']>;
  updatedAt: Scalars['AWSDateTime'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
  ycmbUrl?: Maybe<Scalars['String']>;
};

export type CoachApproach = {
  __typename?: 'CoachApproach';
  coaches?: Maybe<Array<Coach>>;
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['String'];
  label: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
  value: Scalars['String'];
};

export type CoachApproachCoachesCreateRelationInput = {
  connect?: InputMaybe<Array<InputMaybe<CoachWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CoachConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<CoachCreateInput>>>;
};

export type CoachApproachCoachesUpdateRelationsInput = {
  connect?: InputMaybe<Array<InputMaybe<CoachWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CoachConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<CoachCreateInput>>>;
  delete?: InputMaybe<Array<InputMaybe<CoachDeleteUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<CoachDeleteManyInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<CoachWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<CoachWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<CoachUpdateUniqueInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<CoachUpdateManyInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<CoachUpsertUniqueInput>>>;
};

export type CoachApproachConnectOrCreateInput = {
  create: CoachApproachCreateInput;
  where: CoachApproachWhereUniqueInput;
};

export type CoachApproachCreateInput = {
  coaches?: InputMaybe<CoachApproachCoachesCreateRelationInput>;
  label: Scalars['String'];
  value: Scalars['String'];
};

export type CoachApproachCreateManyInput = {
  label: Scalars['String'];
  value: Scalars['String'];
};

export type CoachApproachDeleteManyInput = {
  where: CoachApproachWhereInput;
};

export type CoachApproachDeleteUniqueInput = {
  where: CoachApproachWhereUniqueInput;
};

export type CoachApproachFilter = {
  every?: InputMaybe<CoachApproachScalarWhereInput>;
  none?: InputMaybe<CoachApproachScalarWhereInput>;
  some?: InputMaybe<CoachApproachScalarWhereInput>;
};

export type CoachApproachOrderByInput = {
  coaches?: InputMaybe<CoachOrderByInput>;
  createdAt?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  label?: InputMaybe<OrderByArg>;
  updatedAt?: InputMaybe<OrderByArg>;
  value?: InputMaybe<OrderByArg>;
};

export type CoachApproachRelationFilter = {
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type CoachApproachScalarWhereInput = {
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type CoachApproachUpdateInput = {
  coaches?: InputMaybe<CoachApproachCoachesUpdateRelationsInput>;
  label?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type CoachApproachUpdateManyInput = {
  data: CoachApproachUpdateInput;
  where: CoachApproachWhereInput;
};

export type CoachApproachUpdateUniqueInput = {
  data: CoachApproachUpdateInput;
  where: CoachApproachWhereUniqueInput;
};

export type CoachApproachUpsertInput = {
  create: CoachApproachCreateInput;
  update: CoachApproachUpdateInput;
};

export type CoachApproachUpsertUniqueInput = {
  create: CoachApproachCreateInput;
  update: CoachApproachUpdateInput;
  where: CoachApproachWhereUniqueInput;
};

export type CoachApproachWhereInput = {
  AND?: InputMaybe<Array<InputMaybe<CoachApproachWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<CoachApproachWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<CoachApproachWhereInput>>>;
  coaches?: InputMaybe<CoachFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type CoachApproachWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type CoachApproachesCreateRelationInput = {
  connect?: InputMaybe<Array<InputMaybe<CoachApproachWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CoachApproachConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<CoachApproachCreateInput>>>;
};

export type CoachApproachesUpdateRelationsInput = {
  connect?: InputMaybe<Array<InputMaybe<CoachApproachWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CoachApproachConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<CoachApproachCreateInput>>>;
  delete?: InputMaybe<Array<InputMaybe<CoachApproachDeleteUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<CoachApproachDeleteManyInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<CoachApproachWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<CoachApproachWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<CoachApproachUpdateUniqueInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<CoachApproachUpdateManyInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<CoachApproachUpsertUniqueInput>>>;
};

export type CoachAudienceExperience = {
  __typename?: 'CoachAudienceExperience';
  coaches?: Maybe<Array<Coach>>;
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['String'];
  label: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
  value: Scalars['String'];
};

export type CoachAudienceExperienceCoachesCreateRelationInput = {
  connect?: InputMaybe<Array<InputMaybe<CoachWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CoachConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<CoachCreateInput>>>;
};

export type CoachAudienceExperienceCoachesUpdateRelationsInput = {
  connect?: InputMaybe<Array<InputMaybe<CoachWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CoachConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<CoachCreateInput>>>;
  delete?: InputMaybe<Array<InputMaybe<CoachDeleteUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<CoachDeleteManyInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<CoachWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<CoachWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<CoachUpdateUniqueInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<CoachUpdateManyInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<CoachUpsertUniqueInput>>>;
};

export type CoachAudienceExperienceConnectOrCreateInput = {
  create: CoachAudienceExperienceCreateInput;
  where: CoachAudienceExperienceWhereUniqueInput;
};

export type CoachAudienceExperienceCreateInput = {
  coaches?: InputMaybe<CoachAudienceExperienceCoachesCreateRelationInput>;
  label: Scalars['String'];
  value: Scalars['String'];
};

export type CoachAudienceExperienceCreateManyInput = {
  label: Scalars['String'];
  value: Scalars['String'];
};

export type CoachAudienceExperienceDeleteManyInput = {
  where: CoachAudienceExperienceWhereInput;
};

export type CoachAudienceExperienceDeleteUniqueInput = {
  where: CoachAudienceExperienceWhereUniqueInput;
};

export type CoachAudienceExperienceFilter = {
  every?: InputMaybe<CoachAudienceExperienceScalarWhereInput>;
  none?: InputMaybe<CoachAudienceExperienceScalarWhereInput>;
  some?: InputMaybe<CoachAudienceExperienceScalarWhereInput>;
};

export type CoachAudienceExperienceOrderByInput = {
  coaches?: InputMaybe<CoachOrderByInput>;
  createdAt?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  label?: InputMaybe<OrderByArg>;
  updatedAt?: InputMaybe<OrderByArg>;
  value?: InputMaybe<OrderByArg>;
};

export type CoachAudienceExperienceRelationFilter = {
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type CoachAudienceExperienceScalarWhereInput = {
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type CoachAudienceExperienceUpdateInput = {
  coaches?: InputMaybe<CoachAudienceExperienceCoachesUpdateRelationsInput>;
  label?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type CoachAudienceExperienceUpdateManyInput = {
  data: CoachAudienceExperienceUpdateInput;
  where: CoachAudienceExperienceWhereInput;
};

export type CoachAudienceExperienceUpdateUniqueInput = {
  data: CoachAudienceExperienceUpdateInput;
  where: CoachAudienceExperienceWhereUniqueInput;
};

export type CoachAudienceExperienceUpsertInput = {
  create: CoachAudienceExperienceCreateInput;
  update: CoachAudienceExperienceUpdateInput;
};

export type CoachAudienceExperienceUpsertUniqueInput = {
  create: CoachAudienceExperienceCreateInput;
  update: CoachAudienceExperienceUpdateInput;
  where: CoachAudienceExperienceWhereUniqueInput;
};

export type CoachAudienceExperienceWhereInput = {
  AND?: InputMaybe<Array<InputMaybe<CoachAudienceExperienceWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<CoachAudienceExperienceWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<CoachAudienceExperienceWhereInput>>>;
  coaches?: InputMaybe<CoachFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type CoachAudienceExperienceWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type CoachAudienceExperiencesCreateRelationInput = {
  connect?: InputMaybe<Array<InputMaybe<CoachAudienceExperienceWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CoachAudienceExperienceConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<CoachAudienceExperienceCreateInput>>>;
};

export type CoachAudienceExperiencesUpdateRelationsInput = {
  connect?: InputMaybe<Array<InputMaybe<CoachAudienceExperienceWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CoachAudienceExperienceConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<CoachAudienceExperienceCreateInput>>>;
  delete?: InputMaybe<Array<InputMaybe<CoachAudienceExperienceDeleteUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<CoachAudienceExperienceDeleteManyInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<CoachAudienceExperienceWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<CoachAudienceExperienceWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<CoachAudienceExperienceUpdateUniqueInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<CoachAudienceExperienceUpdateManyInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<CoachAudienceExperienceUpsertUniqueInput>>>;
};

export type CoachAvailabilityInput = {
  endsAt?: InputMaybe<Scalars['AWSDateTime']>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']>;
};

export type CoachCalendarSettingsCreateRelationInput = {
  connect?: InputMaybe<Array<InputMaybe<CalendarSettingWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CalendarSettingConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<CalendarSettingCreateInput>>>;
};

export type CoachCalendarSettingsUpdateRelationsInput = {
  connect?: InputMaybe<Array<InputMaybe<CalendarSettingWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CalendarSettingConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<CalendarSettingCreateInput>>>;
  delete?: InputMaybe<Array<InputMaybe<CalendarSettingDeleteUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<CalendarSettingDeleteManyInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<CalendarSettingWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<CalendarSettingWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<CalendarSettingUpdateUniqueInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<CalendarSettingUpdateManyInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<CalendarSettingUpsertUniqueInput>>>;
};

export type CoachCategoriesCreateRelationInput = {
  connect?: InputMaybe<Array<InputMaybe<CoachCategoryWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CoachCategoryConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<CoachCategoryCreateInput>>>;
};

export type CoachCategoriesUpdateRelationsInput = {
  connect?: InputMaybe<Array<InputMaybe<CoachCategoryWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CoachCategoryConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<CoachCategoryCreateInput>>>;
  delete?: InputMaybe<Array<InputMaybe<CoachCategoryDeleteUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<CoachCategoryDeleteManyInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<CoachCategoryWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<CoachCategoryWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<CoachCategoryUpdateUniqueInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<CoachCategoryUpdateManyInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<CoachCategoryUpsertUniqueInput>>>;
};

export type CoachCategory = {
  __typename?: 'CoachCategory';
  coaches?: Maybe<Array<Coach>>;
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['String'];
  label: Scalars['String'];
  sessions?: Maybe<Array<Session>>;
  updatedAt: Scalars['AWSDateTime'];
  value: Scalars['String'];
};

export type CoachCategoryCoachesCreateRelationInput = {
  connect?: InputMaybe<Array<InputMaybe<CoachWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CoachConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<CoachCreateInput>>>;
};

export type CoachCategoryCoachesUpdateRelationsInput = {
  connect?: InputMaybe<Array<InputMaybe<CoachWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CoachConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<CoachCreateInput>>>;
  delete?: InputMaybe<Array<InputMaybe<CoachDeleteUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<CoachDeleteManyInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<CoachWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<CoachWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<CoachUpdateUniqueInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<CoachUpdateManyInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<CoachUpsertUniqueInput>>>;
};

export type CoachCategoryConnectOrCreateInput = {
  create: CoachCategoryCreateInput;
  where: CoachCategoryWhereUniqueInput;
};

export type CoachCategoryCreateInput = {
  coaches?: InputMaybe<CoachCategoryCoachesCreateRelationInput>;
  label: Scalars['String'];
  sessions?: InputMaybe<CoachCategorySessionsCreateRelationInput>;
  value: Scalars['String'];
};

export type CoachCategoryCreateManyInput = {
  label: Scalars['String'];
  value: Scalars['String'];
};

export type CoachCategoryDeleteManyInput = {
  where: CoachCategoryWhereInput;
};

export type CoachCategoryDeleteUniqueInput = {
  where: CoachCategoryWhereUniqueInput;
};

export type CoachCategoryFilter = {
  every?: InputMaybe<CoachCategoryScalarWhereInput>;
  none?: InputMaybe<CoachCategoryScalarWhereInput>;
  some?: InputMaybe<CoachCategoryScalarWhereInput>;
};

export type CoachCategoryOrderByInput = {
  coaches?: InputMaybe<CoachOrderByInput>;
  createdAt?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  label?: InputMaybe<OrderByArg>;
  sessions?: InputMaybe<SessionOrderByInput>;
  updatedAt?: InputMaybe<OrderByArg>;
  value?: InputMaybe<OrderByArg>;
};

export type CoachCategoryRelationFilter = {
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type CoachCategoryScalarWhereInput = {
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type CoachCategorySessionsCreateRelationInput = {
  connect?: InputMaybe<Array<InputMaybe<SessionWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SessionConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<SessionCreateInput>>>;
};

export type CoachCategorySessionsUpdateRelationsInput = {
  connect?: InputMaybe<Array<InputMaybe<SessionWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SessionConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<SessionCreateInput>>>;
  delete?: InputMaybe<Array<InputMaybe<SessionDeleteUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<SessionDeleteManyInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<SessionWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<SessionWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<SessionUpdateUniqueInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<SessionUpdateManyInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<SessionUpsertUniqueInput>>>;
};

export type CoachCategoryUpdateInput = {
  coaches?: InputMaybe<CoachCategoryCoachesUpdateRelationsInput>;
  label?: InputMaybe<Scalars['String']>;
  sessions?: InputMaybe<CoachCategorySessionsUpdateRelationsInput>;
  value?: InputMaybe<Scalars['String']>;
};

export type CoachCategoryUpdateManyInput = {
  data: CoachCategoryUpdateInput;
  where: CoachCategoryWhereInput;
};

export type CoachCategoryUpdateUniqueInput = {
  data: CoachCategoryUpdateInput;
  where: CoachCategoryWhereUniqueInput;
};

export type CoachCategoryUpsertInput = {
  create: CoachCategoryCreateInput;
  update: CoachCategoryUpdateInput;
};

export type CoachCategoryUpsertUniqueInput = {
  create: CoachCategoryCreateInput;
  update: CoachCategoryUpdateInput;
  where: CoachCategoryWhereUniqueInput;
};

export type CoachCategoryWhereInput = {
  AND?: InputMaybe<Array<InputMaybe<CoachCategoryWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<CoachCategoryWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<CoachCategoryWhereInput>>>;
  coaches?: InputMaybe<CoachFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  sessions?: InputMaybe<SessionFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type CoachCategoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type CoachConnectOrCreateInput = {
  create: CoachCreateInput;
  where: CoachWhereUniqueInput;
};

export type CoachCreateInput = {
  approaches?: InputMaybe<CoachApproachesCreateRelationInput>;
  audienceExperiences?: InputMaybe<CoachAudienceExperiencesCreateRelationInput>;
  avatarKey?: InputMaybe<Scalars['String']>;
  bio?: InputMaybe<Scalars['String']>;
  calendarSettings?: InputMaybe<CoachCalendarSettingsCreateRelationInput>;
  categories?: InputMaybe<CoachCategoriesCreateRelationInput>;
  coachingSince?: InputMaybe<Scalars['AWSDateTime']>;
  displayName?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<CoachEventsCreateRelationInput>;
  gender?: InputMaybe<Gender>;
  handle?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  languages?: InputMaybe<Scalars['AWSJSON']>;
  position?: InputMaybe<Scalars['Int']>;
  pronouns?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  reviews?: InputMaybe<CoachReviewsCreateRelationInput>;
  sessions?: InputMaybe<CoachSessionsCreateRelationInput>;
  specialisms?: InputMaybe<CoachSpecialismsCreateRelationInput>;
  trainingSchool?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<CoachUserCreateRelationInput>;
  ycmbUrl?: InputMaybe<Scalars['String']>;
};

export type CoachCreateManyInput = {
  avatarKey?: InputMaybe<Scalars['String']>;
  bio?: InputMaybe<Scalars['String']>;
  coachingSince?: InputMaybe<Scalars['AWSDateTime']>;
  displayName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  handle?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  languages?: InputMaybe<Scalars['AWSJSON']>;
  position?: InputMaybe<Scalars['Int']>;
  pronouns?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  trainingSchool?: InputMaybe<Scalars['String']>;
  ycmbUrl?: InputMaybe<Scalars['String']>;
};

export type CoachDeleteManyInput = {
  where: CoachWhereInput;
};

export type CoachDeleteUniqueInput = {
  where: CoachWhereUniqueInput;
};

export type CoachEvent = {
  __typename?: 'CoachEvent';
  coach?: Maybe<Coach>;
  coachId?: Maybe<Scalars['String']>;
  createdAt: Scalars['AWSDateTime'];
  creator?: Maybe<Scalars['String']>;
  endsAt?: Maybe<Scalars['AWSDateTime']>;
  eventHtmlLink?: Maybe<Scalars['String']>;
  eventId: Scalars['String'];
  eventType?: Maybe<Scalars['String']>;
  iCalUID?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  organizer?: Maybe<Scalars['String']>;
  recurrence?: Maybe<Scalars['String']>;
  recurringEventId?: Maybe<Scalars['String']>;
  session?: Maybe<Array<Session>>;
  startsAt?: Maybe<Scalars['AWSDateTime']>;
  status?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['AWSDateTime'];
};

export type CoachEventCoachCreateRelationInput = {
  connect?: InputMaybe<CoachWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachConnectOrCreateInput>;
  create?: InputMaybe<CoachCreateInput>;
};

export type CoachEventCoachUpdateRelationsInput = {
  connect?: InputMaybe<CoachWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachConnectOrCreateInput>;
  create?: InputMaybe<CoachCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CoachUpdateInput>;
  upsert?: InputMaybe<CoachUpsertInput>;
};

export type CoachEventConnectOrCreateInput = {
  create: CoachEventCreateInput;
  where: CoachEventWhereUniqueInput;
};

export type CoachEventCreateInput = {
  coach?: InputMaybe<CoachEventCoachCreateRelationInput>;
  creator?: InputMaybe<Scalars['String']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']>;
  eventHtmlLink?: InputMaybe<Scalars['String']>;
  eventId: Scalars['String'];
  eventType?: InputMaybe<Scalars['String']>;
  iCalUID?: InputMaybe<Scalars['String']>;
  organizer?: InputMaybe<Scalars['String']>;
  recurrence?: InputMaybe<Scalars['String']>;
  recurringEventId?: InputMaybe<Scalars['String']>;
  session?: InputMaybe<CoachEventSessionCreateRelationInput>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']>;
  status?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type CoachEventCreateManyInput = {
  creator?: InputMaybe<Scalars['String']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']>;
  eventHtmlLink?: InputMaybe<Scalars['String']>;
  eventId: Scalars['String'];
  eventType?: InputMaybe<Scalars['String']>;
  iCalUID?: InputMaybe<Scalars['String']>;
  organizer?: InputMaybe<Scalars['String']>;
  recurrence?: InputMaybe<Scalars['String']>;
  recurringEventId?: InputMaybe<Scalars['String']>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']>;
  status?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type CoachEventDeleteManyInput = {
  where: CoachEventWhereInput;
};

export type CoachEventDeleteUniqueInput = {
  where: CoachEventWhereUniqueInput;
};

export type CoachEventFilter = {
  every?: InputMaybe<CoachEventScalarWhereInput>;
  none?: InputMaybe<CoachEventScalarWhereInput>;
  some?: InputMaybe<CoachEventScalarWhereInput>;
};

export type CoachEventOrderByInput = {
  coach?: InputMaybe<CoachOrderByInput>;
  coachId?: InputMaybe<OrderByArg>;
  createdAt?: InputMaybe<OrderByArg>;
  creator?: InputMaybe<OrderByArg>;
  endsAt?: InputMaybe<OrderByArg>;
  eventHtmlLink?: InputMaybe<OrderByArg>;
  eventId?: InputMaybe<OrderByArg>;
  eventType?: InputMaybe<OrderByArg>;
  iCalUID?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  organizer?: InputMaybe<OrderByArg>;
  recurrence?: InputMaybe<OrderByArg>;
  recurringEventId?: InputMaybe<OrderByArg>;
  session?: InputMaybe<SessionOrderByInput>;
  startsAt?: InputMaybe<OrderByArg>;
  status?: InputMaybe<OrderByArg>;
  timeZone?: InputMaybe<OrderByArg>;
  title?: InputMaybe<OrderByArg>;
  updatedAt?: InputMaybe<OrderByArg>;
};

export type CoachEventRelationFilter = {
  coachId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  creator?: InputMaybe<StringFilter>;
  endsAt?: InputMaybe<AwsDateTimeFilter>;
  eventHtmlLink?: InputMaybe<StringFilter>;
  eventId?: InputMaybe<StringFilter>;
  eventType?: InputMaybe<StringFilter>;
  iCalUID?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  organizer?: InputMaybe<StringFilter>;
  recurrence?: InputMaybe<StringFilter>;
  recurringEventId?: InputMaybe<StringFilter>;
  startsAt?: InputMaybe<AwsDateTimeFilter>;
  status?: InputMaybe<StringFilter>;
  timeZone?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type CoachEventScalarWhereInput = {
  coachId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  creator?: InputMaybe<StringFilter>;
  endsAt?: InputMaybe<AwsDateTimeFilter>;
  eventHtmlLink?: InputMaybe<StringFilter>;
  eventId?: InputMaybe<StringFilter>;
  eventType?: InputMaybe<StringFilter>;
  iCalUID?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  organizer?: InputMaybe<StringFilter>;
  recurrence?: InputMaybe<StringFilter>;
  recurringEventId?: InputMaybe<StringFilter>;
  startsAt?: InputMaybe<AwsDateTimeFilter>;
  status?: InputMaybe<StringFilter>;
  timeZone?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type CoachEventSessionCreateRelationInput = {
  connect?: InputMaybe<Array<InputMaybe<SessionWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SessionConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<SessionCreateInput>>>;
};

export type CoachEventSessionUpdateRelationsInput = {
  connect?: InputMaybe<Array<InputMaybe<SessionWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SessionConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<SessionCreateInput>>>;
  delete?: InputMaybe<Array<InputMaybe<SessionDeleteUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<SessionDeleteManyInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<SessionWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<SessionWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<SessionUpdateUniqueInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<SessionUpdateManyInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<SessionUpsertUniqueInput>>>;
};

export type CoachEventUpdateInput = {
  coach?: InputMaybe<CoachEventCoachUpdateRelationsInput>;
  creator?: InputMaybe<Scalars['String']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']>;
  eventHtmlLink?: InputMaybe<Scalars['String']>;
  eventId?: InputMaybe<Scalars['String']>;
  eventType?: InputMaybe<Scalars['String']>;
  iCalUID?: InputMaybe<Scalars['String']>;
  organizer?: InputMaybe<Scalars['String']>;
  recurrence?: InputMaybe<Scalars['String']>;
  recurringEventId?: InputMaybe<Scalars['String']>;
  session?: InputMaybe<CoachEventSessionUpdateRelationsInput>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']>;
  status?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type CoachEventUpdateManyInput = {
  data: CoachEventUpdateInput;
  where: CoachEventWhereInput;
};

export type CoachEventUpdateUniqueInput = {
  data: CoachEventUpdateInput;
  where: CoachEventWhereUniqueInput;
};

export type CoachEventUpsertInput = {
  create: CoachEventCreateInput;
  update: CoachEventUpdateInput;
};

export type CoachEventUpsertUniqueInput = {
  create: CoachEventCreateInput;
  update: CoachEventUpdateInput;
  where: CoachEventWhereUniqueInput;
};

export type CoachEventWhereInput = {
  AND?: InputMaybe<Array<InputMaybe<CoachEventWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<CoachEventWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<CoachEventWhereInput>>>;
  coach?: InputMaybe<CoachRelationFilter>;
  coachId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  creator?: InputMaybe<StringFilter>;
  endsAt?: InputMaybe<AwsDateTimeFilter>;
  eventHtmlLink?: InputMaybe<StringFilter>;
  eventId?: InputMaybe<StringFilter>;
  eventType?: InputMaybe<StringFilter>;
  iCalUID?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  organizer?: InputMaybe<StringFilter>;
  recurrence?: InputMaybe<StringFilter>;
  recurringEventId?: InputMaybe<StringFilter>;
  session?: InputMaybe<SessionFilter>;
  startsAt?: InputMaybe<AwsDateTimeFilter>;
  status?: InputMaybe<StringFilter>;
  timeZone?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type CoachEventWhereUniqueInput = {
  coachId?: InputMaybe<Scalars['String']>;
  eventId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type CoachEventsCreateRelationInput = {
  connect?: InputMaybe<Array<InputMaybe<CoachEventWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CoachEventConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<CoachEventCreateInput>>>;
};

export type CoachEventsUpdateRelationsInput = {
  connect?: InputMaybe<Array<InputMaybe<CoachEventWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CoachEventConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<CoachEventCreateInput>>>;
  delete?: InputMaybe<Array<InputMaybe<CoachEventDeleteUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<CoachEventDeleteManyInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<CoachEventWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<CoachEventWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<CoachEventUpdateUniqueInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<CoachEventUpdateManyInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<CoachEventUpsertUniqueInput>>>;
};

export type CoachFilter = {
  every?: InputMaybe<CoachScalarWhereInput>;
  none?: InputMaybe<CoachScalarWhereInput>;
  some?: InputMaybe<CoachScalarWhereInput>;
};

export type CoachOperationInput = {
  position?: InputMaybe<IntOperation>;
};

export type CoachOrderByInput = {
  approaches?: InputMaybe<CoachApproachOrderByInput>;
  audienceExperiences?: InputMaybe<CoachAudienceExperienceOrderByInput>;
  avatarKey?: InputMaybe<OrderByArg>;
  bio?: InputMaybe<OrderByArg>;
  calendarSettings?: InputMaybe<CalendarSettingOrderByInput>;
  categories?: InputMaybe<CoachCategoryOrderByInput>;
  coachingSince?: InputMaybe<OrderByArg>;
  createdAt?: InputMaybe<OrderByArg>;
  displayName?: InputMaybe<OrderByArg>;
  events?: InputMaybe<CoachEventOrderByInput>;
  gender?: InputMaybe<OrderByArg>;
  handle?: InputMaybe<OrderByArg>;
  headline?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  languages?: InputMaybe<OrderByArg>;
  position?: InputMaybe<OrderByArg>;
  pronouns?: InputMaybe<OrderByArg>;
  published?: InputMaybe<OrderByArg>;
  reviews?: InputMaybe<CoachReviewOrderByInput>;
  sessions?: InputMaybe<SessionOrderByInput>;
  specialisms?: InputMaybe<CoachSpecialismOrderByInput>;
  trainingSchool?: InputMaybe<OrderByArg>;
  updatedAt?: InputMaybe<OrderByArg>;
  user?: InputMaybe<UserOrderByInput>;
  userId?: InputMaybe<OrderByArg>;
  ycmbUrl?: InputMaybe<OrderByArg>;
};

export type CoachRelationFilter = {
  avatarKey?: InputMaybe<StringFilter>;
  bio?: InputMaybe<StringFilter>;
  coachingSince?: InputMaybe<AwsDateTimeFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  displayName?: InputMaybe<StringFilter>;
  gender?: InputMaybe<GenderEnumFilter>;
  handle?: InputMaybe<StringFilter>;
  headline?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  languages?: InputMaybe<AwsjsonFilter>;
  position?: InputMaybe<IntFilter>;
  pronouns?: InputMaybe<StringFilter>;
  published?: InputMaybe<BooleanFilter>;
  trainingSchool?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
  ycmbUrl?: InputMaybe<StringFilter>;
};

export type CoachReview = {
  __typename?: 'CoachReview';
  body?: Maybe<Scalars['String']>;
  coach?: Maybe<Coach>;
  coachId?: Maybe<Scalars['String']>;
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['String'];
  rating: Scalars['Int'];
  teamMember?: Maybe<TeamMember>;
  teamMemberId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['AWSDateTime'];
};

export type CoachReviewCoachCreateRelationInput = {
  connect?: InputMaybe<CoachWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachConnectOrCreateInput>;
  create?: InputMaybe<CoachCreateInput>;
};

export type CoachReviewCoachUpdateRelationsInput = {
  connect?: InputMaybe<CoachWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachConnectOrCreateInput>;
  create?: InputMaybe<CoachCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CoachUpdateInput>;
  upsert?: InputMaybe<CoachUpsertInput>;
};

export type CoachReviewConnectOrCreateInput = {
  create: CoachReviewCreateInput;
  where: CoachReviewWhereUniqueInput;
};

export type CoachReviewCreateInput = {
  body?: InputMaybe<Scalars['String']>;
  coach?: InputMaybe<CoachReviewCoachCreateRelationInput>;
  rating: Scalars['Int'];
  teamMember?: InputMaybe<CoachReviewTeamMemberCreateRelationInput>;
};

export type CoachReviewCreateManyInput = {
  body?: InputMaybe<Scalars['String']>;
  rating: Scalars['Int'];
};

export type CoachReviewDeleteManyInput = {
  where: CoachReviewWhereInput;
};

export type CoachReviewDeleteUniqueInput = {
  where: CoachReviewWhereUniqueInput;
};

export type CoachReviewFilter = {
  every?: InputMaybe<CoachReviewScalarWhereInput>;
  none?: InputMaybe<CoachReviewScalarWhereInput>;
  some?: InputMaybe<CoachReviewScalarWhereInput>;
};

export type CoachReviewOperationInput = {
  rating?: InputMaybe<IntOperation>;
};

export type CoachReviewOrderByInput = {
  body?: InputMaybe<OrderByArg>;
  coach?: InputMaybe<CoachOrderByInput>;
  coachId?: InputMaybe<OrderByArg>;
  createdAt?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  rating?: InputMaybe<OrderByArg>;
  teamMember?: InputMaybe<TeamMemberOrderByInput>;
  teamMemberId?: InputMaybe<OrderByArg>;
  updatedAt?: InputMaybe<OrderByArg>;
};

export type CoachReviewRelationFilter = {
  body?: InputMaybe<StringFilter>;
  coachId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  rating?: InputMaybe<IntFilter>;
  teamMemberId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type CoachReviewScalarWhereInput = {
  body?: InputMaybe<StringFilter>;
  coachId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  rating?: InputMaybe<IntFilter>;
  teamMemberId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type CoachReviewTeamMemberCreateRelationInput = {
  connect?: InputMaybe<TeamMemberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamMemberConnectOrCreateInput>;
  create?: InputMaybe<TeamMemberCreateInput>;
};

export type CoachReviewTeamMemberUpdateRelationsInput = {
  connect?: InputMaybe<TeamMemberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamMemberConnectOrCreateInput>;
  create?: InputMaybe<TeamMemberCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<TeamMemberUpdateInput>;
  upsert?: InputMaybe<TeamMemberUpsertInput>;
};

export type CoachReviewUpdateInput = {
  body?: InputMaybe<Scalars['String']>;
  coach?: InputMaybe<CoachReviewCoachUpdateRelationsInput>;
  rating?: InputMaybe<Scalars['Int']>;
  teamMember?: InputMaybe<CoachReviewTeamMemberUpdateRelationsInput>;
};

export type CoachReviewUpdateManyInput = {
  data: CoachReviewUpdateInput;
  where: CoachReviewWhereInput;
};

export type CoachReviewUpdateUniqueInput = {
  data: CoachReviewUpdateInput;
  where: CoachReviewWhereUniqueInput;
};

export type CoachReviewUpsertInput = {
  create: CoachReviewCreateInput;
  update: CoachReviewUpdateInput;
};

export type CoachReviewUpsertUniqueInput = {
  create: CoachReviewCreateInput;
  update: CoachReviewUpdateInput;
  where: CoachReviewWhereUniqueInput;
};

export type CoachReviewWhereInput = {
  AND?: InputMaybe<Array<InputMaybe<CoachReviewWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<CoachReviewWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<CoachReviewWhereInput>>>;
  body?: InputMaybe<StringFilter>;
  coach?: InputMaybe<CoachRelationFilter>;
  coachId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  rating?: InputMaybe<IntFilter>;
  teamMember?: InputMaybe<TeamMemberRelationFilter>;
  teamMemberId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type CoachReviewWhereUniqueInput = {
  coachId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  teamMemberId?: InputMaybe<Scalars['String']>;
};

export type CoachReviewsCreateRelationInput = {
  connect?: InputMaybe<Array<InputMaybe<CoachReviewWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CoachReviewConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<CoachReviewCreateInput>>>;
};

export type CoachReviewsUpdateRelationsInput = {
  connect?: InputMaybe<Array<InputMaybe<CoachReviewWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CoachReviewConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<CoachReviewCreateInput>>>;
  delete?: InputMaybe<Array<InputMaybe<CoachReviewDeleteUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<CoachReviewDeleteManyInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<CoachReviewWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<CoachReviewWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<CoachReviewUpdateUniqueInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<CoachReviewUpdateManyInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<CoachReviewUpsertUniqueInput>>>;
};

export type CoachScalarWhereInput = {
  avatarKey?: InputMaybe<StringFilter>;
  bio?: InputMaybe<StringFilter>;
  coachingSince?: InputMaybe<AwsDateTimeFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  displayName?: InputMaybe<StringFilter>;
  gender?: InputMaybe<GenderEnumFilter>;
  handle?: InputMaybe<StringFilter>;
  headline?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  languages?: InputMaybe<AwsjsonFilter>;
  position?: InputMaybe<IntFilter>;
  pronouns?: InputMaybe<StringFilter>;
  published?: InputMaybe<BooleanFilter>;
  trainingSchool?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
  ycmbUrl?: InputMaybe<StringFilter>;
};

export type CoachSessionsCreateRelationInput = {
  connect?: InputMaybe<Array<InputMaybe<SessionWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SessionConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<SessionCreateInput>>>;
};

export type CoachSessionsUpdateRelationsInput = {
  connect?: InputMaybe<Array<InputMaybe<SessionWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SessionConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<SessionCreateInput>>>;
  delete?: InputMaybe<Array<InputMaybe<SessionDeleteUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<SessionDeleteManyInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<SessionWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<SessionWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<SessionUpdateUniqueInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<SessionUpdateManyInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<SessionUpsertUniqueInput>>>;
};

export type CoachSpecialism = {
  __typename?: 'CoachSpecialism';
  coaches?: Maybe<Array<Coach>>;
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['String'];
  label: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
  value: Scalars['String'];
};

export type CoachSpecialismCoachesCreateRelationInput = {
  connect?: InputMaybe<Array<InputMaybe<CoachWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CoachConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<CoachCreateInput>>>;
};

export type CoachSpecialismCoachesUpdateRelationsInput = {
  connect?: InputMaybe<Array<InputMaybe<CoachWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CoachConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<CoachCreateInput>>>;
  delete?: InputMaybe<Array<InputMaybe<CoachDeleteUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<CoachDeleteManyInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<CoachWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<CoachWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<CoachUpdateUniqueInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<CoachUpdateManyInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<CoachUpsertUniqueInput>>>;
};

export type CoachSpecialismConnectOrCreateInput = {
  create: CoachSpecialismCreateInput;
  where: CoachSpecialismWhereUniqueInput;
};

export type CoachSpecialismCreateInput = {
  coaches?: InputMaybe<CoachSpecialismCoachesCreateRelationInput>;
  label: Scalars['String'];
  value: Scalars['String'];
};

export type CoachSpecialismCreateManyInput = {
  label: Scalars['String'];
  value: Scalars['String'];
};

export type CoachSpecialismDeleteManyInput = {
  where: CoachSpecialismWhereInput;
};

export type CoachSpecialismDeleteUniqueInput = {
  where: CoachSpecialismWhereUniqueInput;
};

export type CoachSpecialismFilter = {
  every?: InputMaybe<CoachSpecialismScalarWhereInput>;
  none?: InputMaybe<CoachSpecialismScalarWhereInput>;
  some?: InputMaybe<CoachSpecialismScalarWhereInput>;
};

export type CoachSpecialismOrderByInput = {
  coaches?: InputMaybe<CoachOrderByInput>;
  createdAt?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  label?: InputMaybe<OrderByArg>;
  updatedAt?: InputMaybe<OrderByArg>;
  value?: InputMaybe<OrderByArg>;
};

export type CoachSpecialismRelationFilter = {
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type CoachSpecialismScalarWhereInput = {
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type CoachSpecialismUpdateInput = {
  coaches?: InputMaybe<CoachSpecialismCoachesUpdateRelationsInput>;
  label?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type CoachSpecialismUpdateManyInput = {
  data: CoachSpecialismUpdateInput;
  where: CoachSpecialismWhereInput;
};

export type CoachSpecialismUpdateUniqueInput = {
  data: CoachSpecialismUpdateInput;
  where: CoachSpecialismWhereUniqueInput;
};

export type CoachSpecialismUpsertInput = {
  create: CoachSpecialismCreateInput;
  update: CoachSpecialismUpdateInput;
};

export type CoachSpecialismUpsertUniqueInput = {
  create: CoachSpecialismCreateInput;
  update: CoachSpecialismUpdateInput;
  where: CoachSpecialismWhereUniqueInput;
};

export type CoachSpecialismWhereInput = {
  AND?: InputMaybe<Array<InputMaybe<CoachSpecialismWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<CoachSpecialismWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<CoachSpecialismWhereInput>>>;
  coaches?: InputMaybe<CoachFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type CoachSpecialismWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type CoachSpecialismsCreateRelationInput = {
  connect?: InputMaybe<Array<InputMaybe<CoachSpecialismWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CoachSpecialismConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<CoachSpecialismCreateInput>>>;
};

export type CoachSpecialismsUpdateRelationsInput = {
  connect?: InputMaybe<Array<InputMaybe<CoachSpecialismWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CoachSpecialismConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<CoachSpecialismCreateInput>>>;
  delete?: InputMaybe<Array<InputMaybe<CoachSpecialismDeleteUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<CoachSpecialismDeleteManyInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<CoachSpecialismWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<CoachSpecialismWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<CoachSpecialismUpdateUniqueInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<CoachSpecialismUpdateManyInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<CoachSpecialismUpsertUniqueInput>>>;
};

export type CoachUpdateInput = {
  approaches?: InputMaybe<CoachApproachesUpdateRelationsInput>;
  audienceExperiences?: InputMaybe<CoachAudienceExperiencesUpdateRelationsInput>;
  avatarKey?: InputMaybe<Scalars['String']>;
  bio?: InputMaybe<Scalars['String']>;
  calendarSettings?: InputMaybe<CoachCalendarSettingsUpdateRelationsInput>;
  categories?: InputMaybe<CoachCategoriesUpdateRelationsInput>;
  coachingSince?: InputMaybe<Scalars['AWSDateTime']>;
  displayName?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<CoachEventsUpdateRelationsInput>;
  gender?: InputMaybe<Gender>;
  handle?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  languages?: InputMaybe<Scalars['AWSJSON']>;
  position?: InputMaybe<Scalars['Int']>;
  pronouns?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  reviews?: InputMaybe<CoachReviewsUpdateRelationsInput>;
  sessions?: InputMaybe<CoachSessionsUpdateRelationsInput>;
  specialisms?: InputMaybe<CoachSpecialismsUpdateRelationsInput>;
  trainingSchool?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<CoachUserUpdateRelationsInput>;
  ycmbUrl?: InputMaybe<Scalars['String']>;
};

export type CoachUpdateManyInput = {
  data: CoachUpdateInput;
  where: CoachWhereInput;
};

export type CoachUpdateUniqueInput = {
  data: CoachUpdateInput;
  where: CoachWhereUniqueInput;
};

export type CoachUpsertInput = {
  create: CoachCreateInput;
  update: CoachUpdateInput;
};

export type CoachUpsertUniqueInput = {
  create: CoachCreateInput;
  update: CoachUpdateInput;
  where: CoachWhereUniqueInput;
};

export type CoachUserCreateRelationInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserConnectOrCreateInput>;
  create?: InputMaybe<UserCreateInput>;
};

export type CoachUserUpdateRelationsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserConnectOrCreateInput>;
  create?: InputMaybe<UserCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserUpdateInput>;
  upsert?: InputMaybe<UserUpsertInput>;
};

export type CoachWhereInput = {
  AND?: InputMaybe<Array<InputMaybe<CoachWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<CoachWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<CoachWhereInput>>>;
  approaches?: InputMaybe<CoachApproachFilter>;
  audienceExperiences?: InputMaybe<CoachAudienceExperienceFilter>;
  avatarKey?: InputMaybe<StringFilter>;
  bio?: InputMaybe<StringFilter>;
  calendarSettings?: InputMaybe<CalendarSettingFilter>;
  categories?: InputMaybe<CoachCategoryFilter>;
  coachingSince?: InputMaybe<AwsDateTimeFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  displayName?: InputMaybe<StringFilter>;
  events?: InputMaybe<CoachEventFilter>;
  gender?: InputMaybe<GenderEnumFilter>;
  handle?: InputMaybe<StringFilter>;
  headline?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  languages?: InputMaybe<AwsjsonFilter>;
  position?: InputMaybe<IntFilter>;
  pronouns?: InputMaybe<StringFilter>;
  published?: InputMaybe<BooleanFilter>;
  reviews?: InputMaybe<CoachReviewFilter>;
  sessions?: InputMaybe<SessionFilter>;
  specialisms?: InputMaybe<CoachSpecialismFilter>;
  trainingSchool?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  ycmbUrl?: InputMaybe<StringFilter>;
};

export type CoachWhereUniqueInput = {
  handle?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type Company = {
  __typename?: 'Company';
  accountType: AccountType;
  companyAdmin?: Maybe<CompanyAdmin>;
  createdAt: Scalars['AWSDateTime'];
  hubspotCompanyId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  intercomCompanyId?: Maybe<Scalars['String']>;
  logoKey?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  reportUrl?: Maybe<Scalars['String']>;
  teamMembers?: Maybe<Array<TeamMember>>;
  updatedAt: Scalars['AWSDateTime'];
};

export type CompanyAdmin = {
  __typename?: 'CompanyAdmin';
  chargebeeCancelScheduleCreatedAt?: Maybe<Scalars['AWSDateTime']>;
  chargebeeCancelledAt?: Maybe<Scalars['AWSDateTime']>;
  chargebeeCurrentTermEnd?: Maybe<Scalars['AWSDateTime']>;
  chargebeeCurrentTermStart?: Maybe<Scalars['AWSDateTime']>;
  chargebeeCustomerId?: Maybe<Scalars['String']>;
  chargebeeSubscriptionId?: Maybe<Scalars['String']>;
  chargebeeSubscriptionStatus?: Maybe<Scalars['String']>;
  chargebeeTotalSeats: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['String']>;
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['String'];
  subscriptionActive?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['AWSDateTime'];
};

export type CompanyAdminCompanyCreateRelationInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyConnectOrCreateInput>;
  create?: InputMaybe<CompanyCreateInput>;
};

export type CompanyAdminCompanyUpdateRelationsInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyConnectOrCreateInput>;
  create?: InputMaybe<CompanyCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CompanyUpdateInput>;
  upsert?: InputMaybe<CompanyUpsertInput>;
};

export type CompanyAdminConnectOrCreateInput = {
  create: CompanyAdminCreateInput;
  where: CompanyAdminWhereUniqueInput;
};

export type CompanyAdminCreateInput = {
  chargebeeCancelScheduleCreatedAt?: InputMaybe<Scalars['AWSDateTime']>;
  chargebeeCancelledAt?: InputMaybe<Scalars['AWSDateTime']>;
  chargebeeCurrentTermEnd?: InputMaybe<Scalars['AWSDateTime']>;
  chargebeeCurrentTermStart?: InputMaybe<Scalars['AWSDateTime']>;
  chargebeeCustomerId?: InputMaybe<Scalars['String']>;
  chargebeeSubscriptionId?: InputMaybe<Scalars['String']>;
  chargebeeSubscriptionStatus?: InputMaybe<Scalars['String']>;
  chargebeeTotalSeats: Scalars['Int'];
  company?: InputMaybe<CompanyAdminCompanyCreateRelationInput>;
  subscriptionActive?: InputMaybe<Scalars['Boolean']>;
};

export type CompanyAdminCreateManyInput = {
  chargebeeCancelScheduleCreatedAt?: InputMaybe<Scalars['AWSDateTime']>;
  chargebeeCancelledAt?: InputMaybe<Scalars['AWSDateTime']>;
  chargebeeCurrentTermEnd?: InputMaybe<Scalars['AWSDateTime']>;
  chargebeeCurrentTermStart?: InputMaybe<Scalars['AWSDateTime']>;
  chargebeeCustomerId?: InputMaybe<Scalars['String']>;
  chargebeeSubscriptionId?: InputMaybe<Scalars['String']>;
  chargebeeSubscriptionStatus?: InputMaybe<Scalars['String']>;
  chargebeeTotalSeats: Scalars['Int'];
  subscriptionActive?: InputMaybe<Scalars['Boolean']>;
};

export type CompanyAdminDeleteManyInput = {
  where: CompanyAdminWhereInput;
};

export type CompanyAdminDeleteUniqueInput = {
  where: CompanyAdminWhereUniqueInput;
};

export type CompanyAdminFilter = {
  every?: InputMaybe<CompanyAdminScalarWhereInput>;
  none?: InputMaybe<CompanyAdminScalarWhereInput>;
  some?: InputMaybe<CompanyAdminScalarWhereInput>;
};

export type CompanyAdminOperationInput = {
  chargebeeTotalSeats?: InputMaybe<IntOperation>;
};

export type CompanyAdminOrderByInput = {
  chargebeeCancelScheduleCreatedAt?: InputMaybe<OrderByArg>;
  chargebeeCancelledAt?: InputMaybe<OrderByArg>;
  chargebeeCurrentTermEnd?: InputMaybe<OrderByArg>;
  chargebeeCurrentTermStart?: InputMaybe<OrderByArg>;
  chargebeeCustomerId?: InputMaybe<OrderByArg>;
  chargebeeSubscriptionId?: InputMaybe<OrderByArg>;
  chargebeeSubscriptionStatus?: InputMaybe<OrderByArg>;
  chargebeeTotalSeats?: InputMaybe<OrderByArg>;
  company?: InputMaybe<CompanyOrderByInput>;
  companyId?: InputMaybe<OrderByArg>;
  createdAt?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  subscriptionActive?: InputMaybe<OrderByArg>;
  updatedAt?: InputMaybe<OrderByArg>;
};

export type CompanyAdminRelationFilter = {
  chargebeeCancelScheduleCreatedAt?: InputMaybe<AwsDateTimeFilter>;
  chargebeeCancelledAt?: InputMaybe<AwsDateTimeFilter>;
  chargebeeCurrentTermEnd?: InputMaybe<AwsDateTimeFilter>;
  chargebeeCurrentTermStart?: InputMaybe<AwsDateTimeFilter>;
  chargebeeCustomerId?: InputMaybe<StringFilter>;
  chargebeeSubscriptionId?: InputMaybe<StringFilter>;
  chargebeeSubscriptionStatus?: InputMaybe<StringFilter>;
  chargebeeTotalSeats?: InputMaybe<IntFilter>;
  companyId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  subscriptionActive?: InputMaybe<BooleanFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type CompanyAdminScalarWhereInput = {
  chargebeeCancelScheduleCreatedAt?: InputMaybe<AwsDateTimeFilter>;
  chargebeeCancelledAt?: InputMaybe<AwsDateTimeFilter>;
  chargebeeCurrentTermEnd?: InputMaybe<AwsDateTimeFilter>;
  chargebeeCurrentTermStart?: InputMaybe<AwsDateTimeFilter>;
  chargebeeCustomerId?: InputMaybe<StringFilter>;
  chargebeeSubscriptionId?: InputMaybe<StringFilter>;
  chargebeeSubscriptionStatus?: InputMaybe<StringFilter>;
  chargebeeTotalSeats?: InputMaybe<IntFilter>;
  companyId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  subscriptionActive?: InputMaybe<BooleanFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type CompanyAdminUpdateInput = {
  chargebeeCancelScheduleCreatedAt?: InputMaybe<Scalars['AWSDateTime']>;
  chargebeeCancelledAt?: InputMaybe<Scalars['AWSDateTime']>;
  chargebeeCurrentTermEnd?: InputMaybe<Scalars['AWSDateTime']>;
  chargebeeCurrentTermStart?: InputMaybe<Scalars['AWSDateTime']>;
  chargebeeCustomerId?: InputMaybe<Scalars['String']>;
  chargebeeSubscriptionId?: InputMaybe<Scalars['String']>;
  chargebeeSubscriptionStatus?: InputMaybe<Scalars['String']>;
  chargebeeTotalSeats?: InputMaybe<Scalars['Int']>;
  company?: InputMaybe<CompanyAdminCompanyUpdateRelationsInput>;
  subscriptionActive?: InputMaybe<Scalars['Boolean']>;
};

export type CompanyAdminUpdateManyInput = {
  data: CompanyAdminUpdateInput;
  where: CompanyAdminWhereInput;
};

export type CompanyAdminUpdateUniqueInput = {
  data: CompanyAdminUpdateInput;
  where: CompanyAdminWhereUniqueInput;
};

export type CompanyAdminUpsertInput = {
  create: CompanyAdminCreateInput;
  update: CompanyAdminUpdateInput;
};

export type CompanyAdminUpsertUniqueInput = {
  create: CompanyAdminCreateInput;
  update: CompanyAdminUpdateInput;
  where: CompanyAdminWhereUniqueInput;
};

export type CompanyAdminWhereInput = {
  AND?: InputMaybe<Array<InputMaybe<CompanyAdminWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<CompanyAdminWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<CompanyAdminWhereInput>>>;
  chargebeeCancelScheduleCreatedAt?: InputMaybe<AwsDateTimeFilter>;
  chargebeeCancelledAt?: InputMaybe<AwsDateTimeFilter>;
  chargebeeCurrentTermEnd?: InputMaybe<AwsDateTimeFilter>;
  chargebeeCurrentTermStart?: InputMaybe<AwsDateTimeFilter>;
  chargebeeCustomerId?: InputMaybe<StringFilter>;
  chargebeeSubscriptionId?: InputMaybe<StringFilter>;
  chargebeeSubscriptionStatus?: InputMaybe<StringFilter>;
  chargebeeTotalSeats?: InputMaybe<IntFilter>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  subscriptionActive?: InputMaybe<BooleanFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type CompanyAdminWhereUniqueInput = {
  companyId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type CompanyCompanyAdminCreateRelationInput = {
  connect?: InputMaybe<Array<InputMaybe<CompanyAdminWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CompanyAdminConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<CompanyAdminCreateInput>>>;
};

export type CompanyCompanyAdminUpdateRelationsInput = {
  connect?: InputMaybe<Array<InputMaybe<CompanyAdminWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CompanyAdminConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<CompanyAdminCreateInput>>>;
  delete?: InputMaybe<Array<InputMaybe<CompanyAdminDeleteUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<CompanyAdminDeleteManyInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<CompanyAdminWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<CompanyAdminWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<CompanyAdminUpdateUniqueInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<CompanyAdminUpdateManyInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<CompanyAdminUpsertUniqueInput>>>;
};

export type CompanyConnectOrCreateInput = {
  create: CompanyCreateInput;
  where: CompanyWhereUniqueInput;
};

export type CompanyCreateInput = {
  accountType: AccountType;
  companyAdmin?: InputMaybe<CompanyCompanyAdminCreateRelationInput>;
  hubspotCompanyId?: InputMaybe<Scalars['String']>;
  intercomCompanyId?: InputMaybe<Scalars['String']>;
  logoKey?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  reportUrl?: InputMaybe<Scalars['String']>;
  teamMembers?: InputMaybe<CompanyTeamMembersCreateRelationInput>;
};

export type CompanyCreateManyInput = {
  accountType: AccountType;
  hubspotCompanyId?: InputMaybe<Scalars['String']>;
  intercomCompanyId?: InputMaybe<Scalars['String']>;
  logoKey?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  reportUrl?: InputMaybe<Scalars['String']>;
};

export type CompanyDeleteManyInput = {
  where: CompanyWhereInput;
};

export type CompanyDeleteUniqueInput = {
  where: CompanyWhereUniqueInput;
};

export type CompanyFilter = {
  every?: InputMaybe<CompanyScalarWhereInput>;
  none?: InputMaybe<CompanyScalarWhereInput>;
  some?: InputMaybe<CompanyScalarWhereInput>;
};

export type CompanyOrderByInput = {
  accountType?: InputMaybe<OrderByArg>;
  companyAdmin?: InputMaybe<CompanyAdminOrderByInput>;
  createdAt?: InputMaybe<OrderByArg>;
  hubspotCompanyId?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  intercomCompanyId?: InputMaybe<OrderByArg>;
  logoKey?: InputMaybe<OrderByArg>;
  name?: InputMaybe<OrderByArg>;
  reportUrl?: InputMaybe<OrderByArg>;
  teamMembers?: InputMaybe<TeamMemberOrderByInput>;
  updatedAt?: InputMaybe<OrderByArg>;
};

export type CompanyRelationFilter = {
  accountType?: InputMaybe<AccountTypeEnumFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  hubspotCompanyId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  intercomCompanyId?: InputMaybe<StringFilter>;
  logoKey?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  reportUrl?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type CompanyScalarWhereInput = {
  accountType?: InputMaybe<AccountTypeEnumFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  hubspotCompanyId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  intercomCompanyId?: InputMaybe<StringFilter>;
  logoKey?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  reportUrl?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type CompanyTeamMembersCreateRelationInput = {
  connect?: InputMaybe<Array<InputMaybe<TeamMemberWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<TeamMemberConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<TeamMemberCreateInput>>>;
};

export type CompanyTeamMembersUpdateRelationsInput = {
  connect?: InputMaybe<Array<InputMaybe<TeamMemberWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<TeamMemberConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<TeamMemberCreateInput>>>;
  delete?: InputMaybe<Array<InputMaybe<TeamMemberDeleteUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<TeamMemberDeleteManyInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<TeamMemberWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<TeamMemberWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<TeamMemberUpdateUniqueInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<TeamMemberUpdateManyInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<TeamMemberUpsertUniqueInput>>>;
};

export type CompanyUpdateInput = {
  accountType?: InputMaybe<AccountType>;
  companyAdmin?: InputMaybe<CompanyCompanyAdminUpdateRelationsInput>;
  hubspotCompanyId?: InputMaybe<Scalars['String']>;
  intercomCompanyId?: InputMaybe<Scalars['String']>;
  logoKey?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  reportUrl?: InputMaybe<Scalars['String']>;
  teamMembers?: InputMaybe<CompanyTeamMembersUpdateRelationsInput>;
};

export type CompanyUpdateManyInput = {
  data: CompanyUpdateInput;
  where: CompanyWhereInput;
};

export type CompanyUpdateUniqueInput = {
  data: CompanyUpdateInput;
  where: CompanyWhereUniqueInput;
};

export type CompanyUpsertInput = {
  create: CompanyCreateInput;
  update: CompanyUpdateInput;
};

export type CompanyUpsertUniqueInput = {
  create: CompanyCreateInput;
  update: CompanyUpdateInput;
  where: CompanyWhereUniqueInput;
};

export type CompanyWhereInput = {
  AND?: InputMaybe<Array<InputMaybe<CompanyWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<CompanyWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<CompanyWhereInput>>>;
  accountType?: InputMaybe<AccountTypeEnumFilter>;
  companyAdmin?: InputMaybe<CompanyAdminFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  hubspotCompanyId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  intercomCompanyId?: InputMaybe<StringFilter>;
  logoKey?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  reportUrl?: InputMaybe<StringFilter>;
  teamMembers?: InputMaybe<TeamMemberFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type CompanyWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type CreateTeamMemberFromInviteInput = {
  email: Scalars['AWSEmail'];
  firstName: Scalars['String'];
  isManager: Scalars['Boolean'];
  lastName: Scalars['String'];
  role: UserRole;
};

export type FailedInviteTeamMemberResponse = {
  __typename?: 'FailedInviteTeamMemberResponse';
  reason: Scalars['String'];
  teamMemberEmail: Scalars['String'];
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<FloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type FloatOperation = {
  decrement?: InputMaybe<Scalars['Float']>;
  divide?: InputMaybe<Scalars['Float']>;
  increment?: InputMaybe<Scalars['Float']>;
  multiply?: InputMaybe<Scalars['Float']>;
  set?: InputMaybe<Scalars['Float']>;
};

export enum Gender {
  Female = 'female',
  Male = 'male'
}

export type GenderEnumFilter = {
  equals?: InputMaybe<Gender>;
  in?: InputMaybe<Array<Gender>>;
  not?: InputMaybe<GenderEnumFilter>;
  notIn?: InputMaybe<Array<Gender>>;
};

export type HostedPageType = {
  __typename?: 'HostedPageType';
  checkoutInfo?: Maybe<Scalars['String']>;
  createdAt: Scalars['Int'];
  embed: Scalars['Boolean'];
  expiresAt: Scalars['Int'];
  failureReason?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  object?: Maybe<Scalars['String']>;
  passThruContent?: Maybe<Scalars['String']>;
  resourceVersion: Scalars['String'];
  state: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['Int'];
  url: Scalars['String'];
};

export type ImpersonateCompanyResponse = {
  __typename?: 'ImpersonateCompanyResponse';
  companyId: Scalars['String'];
  success: Scalars['Boolean'];
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<IntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntOperation = {
  decrement?: InputMaybe<Scalars['Int']>;
  divide?: InputMaybe<Scalars['Int']>;
  increment?: InputMaybe<Scalars['Int']>;
  multiply?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<Scalars['Int']>;
};

export type InviteTeamMembersInput = {
  companyId: Scalars['String'];
  teamMembers?: InputMaybe<Array<CreateTeamMemberFromInviteInput>>;
};

export type InviteTeamMembersResponse = {
  __typename?: 'InviteTeamMembersResponse';
  failed?: Maybe<Array<FailedInviteTeamMemberResponse>>;
  success?: Maybe<Array<TeamMember>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  adminChangeEmail: Scalars['Boolean'];
  adminChangePassword: Scalars['Boolean'];
  adminCreateCompany: Company;
  adminDeleteTeamMember: Scalars['Boolean'];
  adminImpersonateCompany: ImpersonateCompanyResponse;
  adminResendInvites: Scalars['Boolean'];
  cancelSession?: Maybe<Session>;
  /**   Create a new single CalendarSetting record. */
  createCalendarSetting?: Maybe<CalendarSetting>;
  /**   Create a new single Coach record. */
  createCoach?: Maybe<Coach>;
  /**   Create a new single CoachApproach record. */
  createCoachApproach?: Maybe<CoachApproach>;
  /**   Create a new single CoachAudienceExperience record. */
  createCoachAudienceExperience?: Maybe<CoachAudienceExperience>;
  /**   Create a new single CoachCategory record. */
  createCoachCategory?: Maybe<CoachCategory>;
  /**   Create a new single CoachEvent record. */
  createCoachEvent?: Maybe<CoachEvent>;
  /**   Create a new single CoachReview record. */
  createCoachReview?: Maybe<CoachReview>;
  /**   Create a new single CoachSpecialism record. */
  createCoachSpecialism?: Maybe<CoachSpecialism>;
  /**   Create a new single Company record. */
  createCompany?: Maybe<Company>;
  /**   Create a new single CompanyAdmin record. */
  createCompanyAdmin?: Maybe<CompanyAdmin>;
  /**   Create multiple new CalendarSetting records. */
  createManyCalendarSettings?: Maybe<BatchPayload>;
  /**   Create multiple new CoachApproach records. */
  createManyCoachApproaches?: Maybe<BatchPayload>;
  /**   Create multiple new CoachAudienceExperience records. */
  createManyCoachAudienceExperiences?: Maybe<BatchPayload>;
  /**   Create multiple new CoachCategory records. */
  createManyCoachCategories?: Maybe<BatchPayload>;
  /**   Create multiple new CoachEvent records. */
  createManyCoachEvents?: Maybe<BatchPayload>;
  /**   Create multiple new CoachReview records. */
  createManyCoachReviews?: Maybe<BatchPayload>;
  /**   Create multiple new CoachSpecialism records. */
  createManyCoachSpecialisms?: Maybe<BatchPayload>;
  /**   Create multiple new Coach records. */
  createManyCoaches?: Maybe<BatchPayload>;
  /**   Create multiple new Company records. */
  createManyCompanies?: Maybe<BatchPayload>;
  /**   Create multiple new CompanyAdmin records. */
  createManyCompanyAdmins?: Maybe<BatchPayload>;
  /**   Create multiple new Session records. */
  createManySessions?: Maybe<BatchPayload>;
  /**   Create multiple new TeamMemberOutcome records. */
  createManyTeamMemberOutcomes?: Maybe<BatchPayload>;
  /**   Create multiple new TeamMember records. */
  createManyTeamMembers?: Maybe<BatchPayload>;
  /**   Create multiple new User records. */
  createManyUsers?: Maybe<BatchPayload>;
  /**   Create a new single Session record. */
  createSession?: Maybe<Session>;
  /**   Create a new single TeamMember record. */
  createTeamMember?: Maybe<TeamMember>;
  /**   Create a new single TeamMemberOutcome record. */
  createTeamMemberOutcome?: Maybe<TeamMemberOutcome>;
  /**   Create a new single User record. */
  createUser?: Maybe<User>;
  /**   Delete a single CalendarSetting record. */
  deleteCalendarSetting?: Maybe<CalendarSetting>;
  /**   Delete a single Coach record. */
  deleteCoach?: Maybe<Coach>;
  /**   Delete a single CoachApproach record. */
  deleteCoachApproach?: Maybe<CoachApproach>;
  /**   Delete a single CoachAudienceExperience record. */
  deleteCoachAudienceExperience?: Maybe<CoachAudienceExperience>;
  /**   Delete a single CoachCategory record. */
  deleteCoachCategory?: Maybe<CoachCategory>;
  /**   Delete a single CoachEvent record. */
  deleteCoachEvent?: Maybe<CoachEvent>;
  /**   Delete a single CoachReview record. */
  deleteCoachReview?: Maybe<CoachReview>;
  /**   Delete a single CoachSpecialism record. */
  deleteCoachSpecialism?: Maybe<CoachSpecialism>;
  /**   Delete a single Company record. */
  deleteCompany?: Maybe<Company>;
  /**   Delete a single CompanyAdmin record. */
  deleteCompanyAdmin?: Maybe<CompanyAdmin>;
  /**   Delete multiple CalendarSetting records. */
  deleteManyCalendarSettings?: Maybe<BatchPayload>;
  /**   Delete multiple CoachApproach records. */
  deleteManyCoachApproaches?: Maybe<BatchPayload>;
  /**   Delete multiple CoachAudienceExperience records. */
  deleteManyCoachAudienceExperiences?: Maybe<BatchPayload>;
  /**   Delete multiple CoachCategory records. */
  deleteManyCoachCategories?: Maybe<BatchPayload>;
  /**   Delete multiple CoachEvent records. */
  deleteManyCoachEvents?: Maybe<BatchPayload>;
  /**   Delete multiple CoachReview records. */
  deleteManyCoachReviews?: Maybe<BatchPayload>;
  /**   Delete multiple CoachSpecialism records. */
  deleteManyCoachSpecialisms?: Maybe<BatchPayload>;
  /**   Delete multiple Coach records. */
  deleteManyCoaches?: Maybe<BatchPayload>;
  /**   Delete multiple Company records. */
  deleteManyCompanies?: Maybe<BatchPayload>;
  /**   Delete multiple CompanyAdmin records. */
  deleteManyCompanyAdmins?: Maybe<BatchPayload>;
  /**   Delete multiple Session records. */
  deleteManySessions?: Maybe<BatchPayload>;
  /**   Delete multiple TeamMemberOutcome records. */
  deleteManyTeamMemberOutcomes?: Maybe<BatchPayload>;
  /**   Delete multiple TeamMember records. */
  deleteManyTeamMembers?: Maybe<BatchPayload>;
  /**   Delete multiple User records. */
  deleteManyUsers?: Maybe<BatchPayload>;
  /**   Delete a single Session record. */
  deleteSession?: Maybe<Session>;
  /**   Delete a single TeamMember record. */
  deleteTeamMember?: Maybe<TeamMember>;
  /**   Delete a single TeamMemberOutcome record. */
  deleteTeamMemberOutcome?: Maybe<TeamMemberOutcome>;
  /**   Delete a single User record. */
  deleteUser?: Maybe<User>;
  getHostedPage: HostedPageType;
  getPortalPage: PortalPageType;
  inviteTeamMembers: InviteTeamMembersResponse;
  removeTeamMember: Scalars['Boolean'];
  removeTeamMembers: Scalars['Int'];
  resendTeamMemberInvite: TeamMember;
  /**   Update an existing single CalendarSetting record. */
  updateCalendarSetting?: Maybe<CalendarSetting>;
  /**   Update an existing single Coach record. */
  updateCoach?: Maybe<Coach>;
  /**   Update an existing single CoachApproach record. */
  updateCoachApproach?: Maybe<CoachApproach>;
  /**   Update an existing single CoachAudienceExperience record. */
  updateCoachAudienceExperience?: Maybe<CoachAudienceExperience>;
  /**   Update an existing single CoachCategory record. */
  updateCoachCategory?: Maybe<CoachCategory>;
  /**   Update an existing single CoachEvent record. */
  updateCoachEvent?: Maybe<CoachEvent>;
  /**   Update an existing single CoachReview record. */
  updateCoachReview?: Maybe<CoachReview>;
  /**   Update an existing single CoachSpecialism record. */
  updateCoachSpecialism?: Maybe<CoachSpecialism>;
  /**   Update an existing single Company record. */
  updateCompany?: Maybe<Company>;
  /**   Update an existing single CompanyAdmin record. */
  updateCompanyAdmin?: Maybe<CompanyAdmin>;
  updateCompanySponsor?: Maybe<Company>;
  /**   Update multiple existing CalendarSetting records. */
  updateManyCalendarSettings?: Maybe<BatchPayload>;
  /**   Update multiple existing CoachApproach records. */
  updateManyCoachApproaches?: Maybe<BatchPayload>;
  /**   Update multiple existing CoachAudienceExperience records. */
  updateManyCoachAudienceExperiences?: Maybe<BatchPayload>;
  /**   Update multiple existing CoachCategory records. */
  updateManyCoachCategories?: Maybe<BatchPayload>;
  /**   Update multiple existing CoachEvent records. */
  updateManyCoachEvents?: Maybe<BatchPayload>;
  /**   Update multiple existing CoachReview records. */
  updateManyCoachReviews?: Maybe<BatchPayload>;
  /**   Update multiple existing CoachSpecialism records. */
  updateManyCoachSpecialisms?: Maybe<BatchPayload>;
  /**   Update multiple existing Coach records. */
  updateManyCoaches?: Maybe<BatchPayload>;
  /**   Update multiple existing Company records. */
  updateManyCompanies?: Maybe<BatchPayload>;
  /**   Update multiple existing CompanyAdmin records. */
  updateManyCompanyAdmins?: Maybe<BatchPayload>;
  /**   Update multiple existing Session records. */
  updateManySessions?: Maybe<BatchPayload>;
  /**   Update multiple existing TeamMemberOutcome records. */
  updateManyTeamMemberOutcomes?: Maybe<BatchPayload>;
  /**   Update multiple existing TeamMember records. */
  updateManyTeamMembers?: Maybe<BatchPayload>;
  /**   Update multiple existing User records. */
  updateManyUsers?: Maybe<BatchPayload>;
  /**   Update an existing single Session record. */
  updateSession?: Maybe<Session>;
  /**   Update an existing single TeamMember record. */
  updateTeamMember?: Maybe<TeamMember>;
  /**   Update an existing single TeamMemberOutcome record. */
  updateTeamMemberOutcome?: Maybe<TeamMemberOutcome>;
  /**   Update an existing single User record. */
  updateUser?: Maybe<User>;
  /**   Update an existing or create a new single CalendarSetting record. */
  upsertCalendarSetting?: Maybe<CalendarSetting>;
  /**   Update an existing or create a new single Coach record. */
  upsertCoach?: Maybe<Coach>;
  /**   Update an existing or create a new single CoachApproach record. */
  upsertCoachApproach?: Maybe<CoachApproach>;
  /**   Update an existing or create a new single CoachAudienceExperience record. */
  upsertCoachAudienceExperience?: Maybe<CoachAudienceExperience>;
  /**   Update an existing or create a new single CoachCategory record. */
  upsertCoachCategory?: Maybe<CoachCategory>;
  /**   Update an existing or create a new single CoachEvent record. */
  upsertCoachEvent?: Maybe<CoachEvent>;
  /**   Update an existing or create a new single CoachReview record. */
  upsertCoachReview?: Maybe<CoachReview>;
  /**   Update an existing or create a new single CoachSpecialism record. */
  upsertCoachSpecialism?: Maybe<CoachSpecialism>;
  /**   Update an existing or create a new single Company record. */
  upsertCompany?: Maybe<Company>;
  /**   Update an existing or create a new single CompanyAdmin record. */
  upsertCompanyAdmin?: Maybe<CompanyAdmin>;
  /**   Update an existing or create a new single Session record. */
  upsertSession?: Maybe<Session>;
  /**   Update an existing or create a new single TeamMember record. */
  upsertTeamMember?: Maybe<TeamMember>;
  /**   Update an existing or create a new single TeamMemberOutcome record. */
  upsertTeamMemberOutcome?: Maybe<TeamMemberOutcome>;
  /**   Update an existing or create a new single User record. */
  upsertUser?: Maybe<User>;
};


export type MutationAdminChangeEmailArgs = {
  email: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationAdminChangePasswordArgs = {
  password: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationAdminCreateCompanyArgs = {
  input: AdminCreateCompanyInput;
};


export type MutationAdminDeleteTeamMemberArgs = {
  teamMemberId: Scalars['String'];
};


export type MutationAdminImpersonateCompanyArgs = {
  companyId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationAdminResendInvitesArgs = {
  companyId: Scalars['String'];
};


export type MutationCancelSessionArgs = {
  sessionId: Scalars['String'];
};


export type MutationCreateCalendarSettingArgs = {
  data: CalendarSettingCreateInput;
};


export type MutationCreateCoachArgs = {
  data: CoachCreateInput;
};


export type MutationCreateCoachApproachArgs = {
  data: CoachApproachCreateInput;
};


export type MutationCreateCoachAudienceExperienceArgs = {
  data: CoachAudienceExperienceCreateInput;
};


export type MutationCreateCoachCategoryArgs = {
  data: CoachCategoryCreateInput;
};


export type MutationCreateCoachEventArgs = {
  data: CoachEventCreateInput;
};


export type MutationCreateCoachReviewArgs = {
  data: CoachReviewCreateInput;
};


export type MutationCreateCoachSpecialismArgs = {
  data: CoachSpecialismCreateInput;
};


export type MutationCreateCompanyArgs = {
  data: CompanyCreateInput;
};


export type MutationCreateCompanyAdminArgs = {
  data: CompanyAdminCreateInput;
};


export type MutationCreateManyCalendarSettingsArgs = {
  data?: InputMaybe<Array<CalendarSettingCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyCoachApproachesArgs = {
  data?: InputMaybe<Array<CoachApproachCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyCoachAudienceExperiencesArgs = {
  data?: InputMaybe<Array<CoachAudienceExperienceCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyCoachCategoriesArgs = {
  data?: InputMaybe<Array<CoachCategoryCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyCoachEventsArgs = {
  data?: InputMaybe<Array<CoachEventCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyCoachReviewsArgs = {
  data?: InputMaybe<Array<CoachReviewCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyCoachSpecialismsArgs = {
  data?: InputMaybe<Array<CoachSpecialismCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyCoachesArgs = {
  data?: InputMaybe<Array<CoachCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyCompaniesArgs = {
  data?: InputMaybe<Array<CompanyCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyCompanyAdminsArgs = {
  data?: InputMaybe<Array<CompanyAdminCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManySessionsArgs = {
  data?: InputMaybe<Array<SessionCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyTeamMemberOutcomesArgs = {
  data?: InputMaybe<Array<TeamMemberOutcomeCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyTeamMembersArgs = {
  data?: InputMaybe<Array<TeamMemberCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyUsersArgs = {
  data?: InputMaybe<Array<UserCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateSessionArgs = {
  data: SessionCreateInput;
};


export type MutationCreateTeamMemberArgs = {
  data: TeamMemberCreateInput;
};


export type MutationCreateTeamMemberOutcomeArgs = {
  data: TeamMemberOutcomeCreateInput;
};


export type MutationCreateUserArgs = {
  data: UserCreateInput;
};


export type MutationDeleteCalendarSettingArgs = {
  where: CalendarSettingWhereUniqueInput;
};


export type MutationDeleteCoachArgs = {
  where: CoachWhereUniqueInput;
};


export type MutationDeleteCoachApproachArgs = {
  where: CoachApproachWhereUniqueInput;
};


export type MutationDeleteCoachAudienceExperienceArgs = {
  where: CoachAudienceExperienceWhereUniqueInput;
};


export type MutationDeleteCoachCategoryArgs = {
  where: CoachCategoryWhereUniqueInput;
};


export type MutationDeleteCoachEventArgs = {
  where: CoachEventWhereUniqueInput;
};


export type MutationDeleteCoachReviewArgs = {
  where: CoachReviewWhereUniqueInput;
};


export type MutationDeleteCoachSpecialismArgs = {
  where: CoachSpecialismWhereUniqueInput;
};


export type MutationDeleteCompanyArgs = {
  where: CompanyWhereUniqueInput;
};


export type MutationDeleteCompanyAdminArgs = {
  where: CompanyAdminWhereUniqueInput;
};


export type MutationDeleteManyCalendarSettingsArgs = {
  where: CalendarSettingWhereInput;
};


export type MutationDeleteManyCoachApproachesArgs = {
  where: CoachApproachWhereInput;
};


export type MutationDeleteManyCoachAudienceExperiencesArgs = {
  where: CoachAudienceExperienceWhereInput;
};


export type MutationDeleteManyCoachCategoriesArgs = {
  where: CoachCategoryWhereInput;
};


export type MutationDeleteManyCoachEventsArgs = {
  where: CoachEventWhereInput;
};


export type MutationDeleteManyCoachReviewsArgs = {
  where: CoachReviewWhereInput;
};


export type MutationDeleteManyCoachSpecialismsArgs = {
  where: CoachSpecialismWhereInput;
};


export type MutationDeleteManyCoachesArgs = {
  where: CoachWhereInput;
};


export type MutationDeleteManyCompaniesArgs = {
  where: CompanyWhereInput;
};


export type MutationDeleteManyCompanyAdminsArgs = {
  where: CompanyAdminWhereInput;
};


export type MutationDeleteManySessionsArgs = {
  where: SessionWhereInput;
};


export type MutationDeleteManyTeamMemberOutcomesArgs = {
  where: TeamMemberOutcomeWhereInput;
};


export type MutationDeleteManyTeamMembersArgs = {
  where: TeamMemberWhereInput;
};


export type MutationDeleteManyUsersArgs = {
  where: UserWhereInput;
};


export type MutationDeleteSessionArgs = {
  where: SessionWhereUniqueInput;
};


export type MutationDeleteTeamMemberArgs = {
  where: TeamMemberWhereUniqueInput;
};


export type MutationDeleteTeamMemberOutcomeArgs = {
  where: TeamMemberOutcomeWhereUniqueInput;
};


export type MutationDeleteUserArgs = {
  where: UserWhereUniqueInput;
};


export type MutationGetHostedPageArgs = {
  companyId: Scalars['String'];
  itemPriceId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationGetPortalPageArgs = {
  companyId: Scalars['String'];
};


export type MutationInviteTeamMembersArgs = {
  input: InviteTeamMembersInput;
};


export type MutationRemoveTeamMemberArgs = {
  teamMemberId: Scalars['String'];
};


export type MutationRemoveTeamMembersArgs = {
  companyId: Scalars['String'];
  teamMemberIds?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationResendTeamMemberInviteArgs = {
  teamMemberId: Scalars['String'];
};


export type MutationUpdateCalendarSettingArgs = {
  data?: InputMaybe<CalendarSettingUpdateInput>;
  where: CalendarSettingWhereUniqueInput;
};


export type MutationUpdateCoachArgs = {
  data?: InputMaybe<CoachUpdateInput>;
  operation?: InputMaybe<CoachOperationInput>;
  where: CoachWhereUniqueInput;
};


export type MutationUpdateCoachApproachArgs = {
  data?: InputMaybe<CoachApproachUpdateInput>;
  where: CoachApproachWhereUniqueInput;
};


export type MutationUpdateCoachAudienceExperienceArgs = {
  data?: InputMaybe<CoachAudienceExperienceUpdateInput>;
  where: CoachAudienceExperienceWhereUniqueInput;
};


export type MutationUpdateCoachCategoryArgs = {
  data?: InputMaybe<CoachCategoryUpdateInput>;
  where: CoachCategoryWhereUniqueInput;
};


export type MutationUpdateCoachEventArgs = {
  data?: InputMaybe<CoachEventUpdateInput>;
  where: CoachEventWhereUniqueInput;
};


export type MutationUpdateCoachReviewArgs = {
  data?: InputMaybe<CoachReviewUpdateInput>;
  operation?: InputMaybe<CoachReviewOperationInput>;
  where: CoachReviewWhereUniqueInput;
};


export type MutationUpdateCoachSpecialismArgs = {
  data?: InputMaybe<CoachSpecialismUpdateInput>;
  where: CoachSpecialismWhereUniqueInput;
};


export type MutationUpdateCompanyArgs = {
  data?: InputMaybe<CompanyUpdateInput>;
  where: CompanyWhereUniqueInput;
};


export type MutationUpdateCompanyAdminArgs = {
  data?: InputMaybe<CompanyAdminUpdateInput>;
  operation?: InputMaybe<CompanyAdminOperationInput>;
  where: CompanyAdminWhereUniqueInput;
};


export type MutationUpdateCompanySponsorArgs = {
  companyId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationUpdateManyCalendarSettingsArgs = {
  data?: InputMaybe<CalendarSettingUpdateInput>;
  where: CalendarSettingWhereInput;
};


export type MutationUpdateManyCoachApproachesArgs = {
  data?: InputMaybe<CoachApproachUpdateInput>;
  where: CoachApproachWhereInput;
};


export type MutationUpdateManyCoachAudienceExperiencesArgs = {
  data?: InputMaybe<CoachAudienceExperienceUpdateInput>;
  where: CoachAudienceExperienceWhereInput;
};


export type MutationUpdateManyCoachCategoriesArgs = {
  data?: InputMaybe<CoachCategoryUpdateInput>;
  where: CoachCategoryWhereInput;
};


export type MutationUpdateManyCoachEventsArgs = {
  data?: InputMaybe<CoachEventUpdateInput>;
  where: CoachEventWhereInput;
};


export type MutationUpdateManyCoachReviewsArgs = {
  data?: InputMaybe<CoachReviewUpdateInput>;
  operation?: InputMaybe<CoachReviewOperationInput>;
  where: CoachReviewWhereInput;
};


export type MutationUpdateManyCoachSpecialismsArgs = {
  data?: InputMaybe<CoachSpecialismUpdateInput>;
  where: CoachSpecialismWhereInput;
};


export type MutationUpdateManyCoachesArgs = {
  data?: InputMaybe<CoachUpdateInput>;
  operation?: InputMaybe<CoachOperationInput>;
  where: CoachWhereInput;
};


export type MutationUpdateManyCompaniesArgs = {
  data?: InputMaybe<CompanyUpdateInput>;
  where: CompanyWhereInput;
};


export type MutationUpdateManyCompanyAdminsArgs = {
  data?: InputMaybe<CompanyAdminUpdateInput>;
  operation?: InputMaybe<CompanyAdminOperationInput>;
  where: CompanyAdminWhereInput;
};


export type MutationUpdateManySessionsArgs = {
  data?: InputMaybe<SessionUpdateInput>;
  where: SessionWhereInput;
};


export type MutationUpdateManyTeamMemberOutcomesArgs = {
  data?: InputMaybe<TeamMemberOutcomeUpdateInput>;
  where: TeamMemberOutcomeWhereInput;
};


export type MutationUpdateManyTeamMembersArgs = {
  data?: InputMaybe<TeamMemberUpdateInput>;
  where: TeamMemberWhereInput;
};


export type MutationUpdateManyUsersArgs = {
  data?: InputMaybe<UserUpdateInput>;
  operation?: InputMaybe<UserOperationInput>;
  where: UserWhereInput;
};


export type MutationUpdateSessionArgs = {
  data?: InputMaybe<SessionUpdateInput>;
  where: SessionWhereUniqueInput;
};


export type MutationUpdateTeamMemberArgs = {
  data?: InputMaybe<TeamMemberUpdateInput>;
  where: TeamMemberWhereUniqueInput;
};


export type MutationUpdateTeamMemberOutcomeArgs = {
  data?: InputMaybe<TeamMemberOutcomeUpdateInput>;
  where: TeamMemberOutcomeWhereUniqueInput;
};


export type MutationUpdateUserArgs = {
  data?: InputMaybe<UserUpdateInput>;
  operation?: InputMaybe<UserOperationInput>;
  where: UserWhereUniqueInput;
};


export type MutationUpsertCalendarSettingArgs = {
  data: CalendarSettingUpdateInput;
  where: CalendarSettingWhereUniqueInput;
};


export type MutationUpsertCoachArgs = {
  data: CoachUpdateInput;
  where: CoachWhereUniqueInput;
};


export type MutationUpsertCoachApproachArgs = {
  data: CoachApproachUpdateInput;
  where: CoachApproachWhereUniqueInput;
};


export type MutationUpsertCoachAudienceExperienceArgs = {
  data: CoachAudienceExperienceUpdateInput;
  where: CoachAudienceExperienceWhereUniqueInput;
};


export type MutationUpsertCoachCategoryArgs = {
  data: CoachCategoryUpdateInput;
  where: CoachCategoryWhereUniqueInput;
};


export type MutationUpsertCoachEventArgs = {
  data: CoachEventUpdateInput;
  where: CoachEventWhereUniqueInput;
};


export type MutationUpsertCoachReviewArgs = {
  data: CoachReviewUpdateInput;
  where: CoachReviewWhereUniqueInput;
};


export type MutationUpsertCoachSpecialismArgs = {
  data: CoachSpecialismUpdateInput;
  where: CoachSpecialismWhereUniqueInput;
};


export type MutationUpsertCompanyArgs = {
  data: CompanyUpdateInput;
  where: CompanyWhereUniqueInput;
};


export type MutationUpsertCompanyAdminArgs = {
  data: CompanyAdminUpdateInput;
  where: CompanyAdminWhereUniqueInput;
};


export type MutationUpsertSessionArgs = {
  data: SessionUpdateInput;
  where: SessionWhereUniqueInput;
};


export type MutationUpsertTeamMemberArgs = {
  data: TeamMemberUpdateInput;
  where: TeamMemberWhereUniqueInput;
};


export type MutationUpsertTeamMemberOutcomeArgs = {
  data: TeamMemberOutcomeUpdateInput;
  where: TeamMemberOutcomeWhereUniqueInput;
};


export type MutationUpsertUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};

export enum OrderByArg {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type PortalPageType = {
  __typename?: 'PortalPageType';
  accessUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Int']>;
  customerId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  coachAvailability?: Maybe<Array<Maybe<CoachEvent>>>;
  /**   Count all CalendarSetting records (optional query filters). */
  countCalendarSettings?: Maybe<Scalars['Int']>;
  /**   Count all CoachApproach records (optional query filters). */
  countCoachApproaches?: Maybe<Scalars['Int']>;
  /**   Count all CoachAudienceExperience records (optional query filters). */
  countCoachAudienceExperiences?: Maybe<Scalars['Int']>;
  /**   Count all CoachCategory records (optional query filters). */
  countCoachCategories?: Maybe<Scalars['Int']>;
  /**   Count all CoachEvent records (optional query filters). */
  countCoachEvents?: Maybe<Scalars['Int']>;
  /**   Count all CoachReview records (optional query filters). */
  countCoachReviews?: Maybe<Scalars['Int']>;
  /**   Count all CoachSpecialism records (optional query filters). */
  countCoachSpecialisms?: Maybe<Scalars['Int']>;
  /**   Count all Coach records (optional query filters). */
  countCoaches?: Maybe<Scalars['Int']>;
  /**   Count all Company records (optional query filters). */
  countCompanies?: Maybe<Scalars['Int']>;
  /**   Count all CompanyAdmin records (optional query filters). */
  countCompanyAdmins?: Maybe<Scalars['Int']>;
  /**   Count all Session records (optional query filters). */
  countSessions?: Maybe<Scalars['Int']>;
  /**   Count all TeamMemberOutcome records (optional query filters). */
  countTeamMemberOutcomes?: Maybe<Scalars['Int']>;
  /**   Count all TeamMember records (optional query filters). */
  countTeamMembers?: Maybe<Scalars['Int']>;
  /**   Count all User records (optional query filters). */
  countUsers?: Maybe<Scalars['Int']>;
  /**   Find a single CalendarSetting record by unique identifier. */
  getCalendarSetting?: Maybe<CalendarSetting>;
  /**   Find a single Coach record by unique identifier. */
  getCoach?: Maybe<Coach>;
  /**   Find a single CoachApproach record by unique identifier. */
  getCoachApproach?: Maybe<CoachApproach>;
  /**   Find a single CoachAudienceExperience record by unique identifier. */
  getCoachAudienceExperience?: Maybe<CoachAudienceExperience>;
  /**   Find a single CoachCategory record by unique identifier. */
  getCoachCategory?: Maybe<CoachCategory>;
  /**   Find a single CoachEvent record by unique identifier. */
  getCoachEvent?: Maybe<CoachEvent>;
  /**   Find a single CoachReview record by unique identifier. */
  getCoachReview?: Maybe<CoachReview>;
  /**   Find a single CoachSpecialism record by unique identifier. */
  getCoachSpecialism?: Maybe<CoachSpecialism>;
  /**   Find a single Company record by unique identifier. */
  getCompany?: Maybe<Company>;
  /**   Find a single CompanyAdmin record by unique identifier. */
  getCompanyAdmin?: Maybe<CompanyAdmin>;
  /**   Find a single Session record by unique identifier. */
  getSession?: Maybe<Session>;
  /**   Find a single TeamMember record by unique identifier. */
  getTeamMember?: Maybe<TeamMember>;
  /**   Find a single TeamMemberOutcome record by unique identifier. */
  getTeamMemberOutcome?: Maybe<TeamMemberOutcome>;
  /**   Find a single User record by unique identifier. */
  getUser?: Maybe<User>;
  /**   Find many CalendarSetting records (optional query filters). */
  listCalendarSettings?: Maybe<Array<Maybe<CalendarSetting>>>;
  /**   Find many CoachApproach records (optional query filters). */
  listCoachApproaches?: Maybe<Array<Maybe<CoachApproach>>>;
  /**   Find many CoachAudienceExperience records (optional query filters). */
  listCoachAudienceExperiences?: Maybe<Array<Maybe<CoachAudienceExperience>>>;
  /**   Find many CoachCategory records (optional query filters). */
  listCoachCategories?: Maybe<Array<Maybe<CoachCategory>>>;
  /**   Find many CoachEvent records (optional query filters). */
  listCoachEvents?: Maybe<Array<Maybe<CoachEvent>>>;
  /**   Find many CoachReview records (optional query filters). */
  listCoachReviews?: Maybe<Array<Maybe<CoachReview>>>;
  /**   Find many CoachSpecialism records (optional query filters). */
  listCoachSpecialisms?: Maybe<Array<Maybe<CoachSpecialism>>>;
  /**   Find many Coach records (optional query filters). */
  listCoaches?: Maybe<Array<Maybe<Coach>>>;
  /**   Find many Company records (optional query filters). */
  listCompanies?: Maybe<Array<Maybe<Company>>>;
  /**   Find many CompanyAdmin records (optional query filters). */
  listCompanyAdmins?: Maybe<Array<Maybe<CompanyAdmin>>>;
  /**   Find many Session records (optional query filters). */
  listSessions?: Maybe<Array<Maybe<Session>>>;
  /**   Find many TeamMemberOutcome records (optional query filters). */
  listTeamMemberOutcomes?: Maybe<Array<Maybe<TeamMemberOutcome>>>;
  /**   Find many TeamMember records (optional query filters). */
  listTeamMembers?: Maybe<Array<Maybe<TeamMember>>>;
  /**   Find many User records (optional query filters). */
  listUsers?: Maybe<Array<Maybe<User>>>;
  searchCoaches?: Maybe<Array<Maybe<Coach>>>;
  searchCoachesByAvailability?: Maybe<Array<Maybe<Coach>>>;
  whoAmI: User;
};


export type QueryCoachAvailabilityArgs = {
  coachId: Scalars['String'];
};


export type QueryCountCalendarSettingsArgs = {
  orderBy?: InputMaybe<Array<InputMaybe<CalendarSettingOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CalendarSettingWhereInput>;
};


export type QueryCountCoachApproachesArgs = {
  orderBy?: InputMaybe<Array<InputMaybe<CoachApproachOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CoachApproachWhereInput>;
};


export type QueryCountCoachAudienceExperiencesArgs = {
  orderBy?: InputMaybe<Array<InputMaybe<CoachAudienceExperienceOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CoachAudienceExperienceWhereInput>;
};


export type QueryCountCoachCategoriesArgs = {
  orderBy?: InputMaybe<Array<InputMaybe<CoachCategoryOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CoachCategoryWhereInput>;
};


export type QueryCountCoachEventsArgs = {
  orderBy?: InputMaybe<Array<InputMaybe<CoachEventOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CoachEventWhereInput>;
};


export type QueryCountCoachReviewsArgs = {
  orderBy?: InputMaybe<Array<InputMaybe<CoachReviewOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CoachReviewWhereInput>;
};


export type QueryCountCoachSpecialismsArgs = {
  orderBy?: InputMaybe<Array<InputMaybe<CoachSpecialismOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CoachSpecialismWhereInput>;
};


export type QueryCountCoachesArgs = {
  orderBy?: InputMaybe<Array<InputMaybe<CoachOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CoachWhereInput>;
};


export type QueryCountCompaniesArgs = {
  orderBy?: InputMaybe<Array<InputMaybe<CompanyOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CompanyWhereInput>;
};


export type QueryCountCompanyAdminsArgs = {
  orderBy?: InputMaybe<Array<InputMaybe<CompanyAdminOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CompanyAdminWhereInput>;
};


export type QueryCountSessionsArgs = {
  orderBy?: InputMaybe<Array<InputMaybe<SessionOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SessionWhereInput>;
};


export type QueryCountTeamMemberOutcomesArgs = {
  orderBy?: InputMaybe<Array<InputMaybe<TeamMemberOutcomeOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TeamMemberOutcomeWhereInput>;
};


export type QueryCountTeamMembersArgs = {
  orderBy?: InputMaybe<Array<InputMaybe<TeamMemberOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TeamMemberWhereInput>;
};


export type QueryCountUsersArgs = {
  orderBy?: InputMaybe<Array<InputMaybe<UserOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryGetCalendarSettingArgs = {
  where: CalendarSettingWhereUniqueInput;
};


export type QueryGetCoachArgs = {
  where: CoachWhereUniqueInput;
};


export type QueryGetCoachApproachArgs = {
  where: CoachApproachWhereUniqueInput;
};


export type QueryGetCoachAudienceExperienceArgs = {
  where: CoachAudienceExperienceWhereUniqueInput;
};


export type QueryGetCoachCategoryArgs = {
  where: CoachCategoryWhereUniqueInput;
};


export type QueryGetCoachEventArgs = {
  where: CoachEventWhereUniqueInput;
};


export type QueryGetCoachReviewArgs = {
  where: CoachReviewWhereUniqueInput;
};


export type QueryGetCoachSpecialismArgs = {
  where: CoachSpecialismWhereUniqueInput;
};


export type QueryGetCompanyArgs = {
  where: CompanyWhereUniqueInput;
};


export type QueryGetCompanyAdminArgs = {
  where: CompanyAdminWhereUniqueInput;
};


export type QueryGetSessionArgs = {
  where: SessionWhereUniqueInput;
};


export type QueryGetTeamMemberArgs = {
  where: TeamMemberWhereUniqueInput;
};


export type QueryGetTeamMemberOutcomeArgs = {
  where: TeamMemberOutcomeWhereUniqueInput;
};


export type QueryGetUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryListCalendarSettingsArgs = {
  orderBy?: InputMaybe<Array<InputMaybe<CalendarSettingOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CalendarSettingWhereInput>;
};


export type QueryListCoachApproachesArgs = {
  orderBy?: InputMaybe<Array<InputMaybe<CoachApproachOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CoachApproachWhereInput>;
};


export type QueryListCoachAudienceExperiencesArgs = {
  orderBy?: InputMaybe<Array<InputMaybe<CoachAudienceExperienceOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CoachAudienceExperienceWhereInput>;
};


export type QueryListCoachCategoriesArgs = {
  orderBy?: InputMaybe<Array<InputMaybe<CoachCategoryOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CoachCategoryWhereInput>;
};


export type QueryListCoachEventsArgs = {
  orderBy?: InputMaybe<Array<InputMaybe<CoachEventOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CoachEventWhereInput>;
};


export type QueryListCoachReviewsArgs = {
  orderBy?: InputMaybe<Array<InputMaybe<CoachReviewOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CoachReviewWhereInput>;
};


export type QueryListCoachSpecialismsArgs = {
  orderBy?: InputMaybe<Array<InputMaybe<CoachSpecialismOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CoachSpecialismWhereInput>;
};


export type QueryListCoachesArgs = {
  orderBy?: InputMaybe<Array<InputMaybe<CoachOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CoachWhereInput>;
};


export type QueryListCompaniesArgs = {
  orderBy?: InputMaybe<Array<InputMaybe<CompanyOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CompanyWhereInput>;
};


export type QueryListCompanyAdminsArgs = {
  orderBy?: InputMaybe<Array<InputMaybe<CompanyAdminOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CompanyAdminWhereInput>;
};


export type QueryListSessionsArgs = {
  orderBy?: InputMaybe<Array<InputMaybe<SessionOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SessionWhereInput>;
};


export type QueryListTeamMemberOutcomesArgs = {
  orderBy?: InputMaybe<Array<InputMaybe<TeamMemberOutcomeOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TeamMemberOutcomeWhereInput>;
};


export type QueryListTeamMembersArgs = {
  orderBy?: InputMaybe<Array<InputMaybe<TeamMemberOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TeamMemberWhereInput>;
};


export type QueryListUsersArgs = {
  orderBy?: InputMaybe<Array<InputMaybe<UserOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QuerySearchCoachesArgs = {
  availability?: InputMaybe<CoachAvailabilityInput>;
  orderBy?: InputMaybe<Array<InputMaybe<CoachOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CoachWhereInput>;
};


export type QuerySearchCoachesByAvailabilityArgs = {
  availability?: InputMaybe<CoachAvailabilityInput>;
  orderBy?: InputMaybe<Array<InputMaybe<CoachOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CoachWhereInput>;
};

export type Session = {
  __typename?: 'Session';
  cancelledAt?: Maybe<Scalars['AWSDateTime']>;
  category?: Maybe<CoachCategory>;
  coach?: Maybe<Coach>;
  coachCategoryId?: Maybe<Scalars['String']>;
  coachEventId?: Maybe<Scalars['String']>;
  coachId?: Maybe<Scalars['String']>;
  createdAt: Scalars['AWSDateTime'];
  endsAt?: Maybe<Scalars['AWSDateTime']>;
  event?: Maybe<CoachEvent>;
  googleEventId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  noShow?: Maybe<Scalars['Boolean']>;
  startsAt?: Maybe<Scalars['AWSDateTime']>;
  state: SessionState;
  teamMember?: Maybe<TeamMember>;
  teamMemberId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['AWSDateTime'];
};

export type SessionCategoryCreateRelationInput = {
  connect?: InputMaybe<CoachCategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachCategoryConnectOrCreateInput>;
  create?: InputMaybe<CoachCategoryCreateInput>;
};

export type SessionCategoryUpdateRelationsInput = {
  connect?: InputMaybe<CoachCategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachCategoryConnectOrCreateInput>;
  create?: InputMaybe<CoachCategoryCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CoachCategoryUpdateInput>;
  upsert?: InputMaybe<CoachCategoryUpsertInput>;
};

export type SessionCoachCreateRelationInput = {
  connect?: InputMaybe<CoachWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachConnectOrCreateInput>;
  create?: InputMaybe<CoachCreateInput>;
};

export type SessionCoachUpdateRelationsInput = {
  connect?: InputMaybe<CoachWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachConnectOrCreateInput>;
  create?: InputMaybe<CoachCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CoachUpdateInput>;
  upsert?: InputMaybe<CoachUpsertInput>;
};

export type SessionConnectOrCreateInput = {
  create: SessionCreateInput;
  where: SessionWhereUniqueInput;
};

export type SessionCreateInput = {
  cancelledAt?: InputMaybe<Scalars['AWSDateTime']>;
  category?: InputMaybe<SessionCategoryCreateRelationInput>;
  coach?: InputMaybe<SessionCoachCreateRelationInput>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']>;
  event?: InputMaybe<SessionEventCreateRelationInput>;
  googleEventId?: InputMaybe<Scalars['String']>;
  noShow?: InputMaybe<Scalars['Boolean']>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']>;
  state: SessionState;
  teamMember?: InputMaybe<SessionTeamMemberCreateRelationInput>;
};

export type SessionCreateManyInput = {
  cancelledAt?: InputMaybe<Scalars['AWSDateTime']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']>;
  googleEventId?: InputMaybe<Scalars['String']>;
  noShow?: InputMaybe<Scalars['Boolean']>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']>;
  state: SessionState;
};

export type SessionDeleteManyInput = {
  where: SessionWhereInput;
};

export type SessionDeleteUniqueInput = {
  where: SessionWhereUniqueInput;
};

export type SessionEventCreateRelationInput = {
  connect?: InputMaybe<CoachEventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachEventConnectOrCreateInput>;
  create?: InputMaybe<CoachEventCreateInput>;
};

export type SessionEventUpdateRelationsInput = {
  connect?: InputMaybe<CoachEventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachEventConnectOrCreateInput>;
  create?: InputMaybe<CoachEventCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CoachEventUpdateInput>;
  upsert?: InputMaybe<CoachEventUpsertInput>;
};

export type SessionFilter = {
  every?: InputMaybe<SessionScalarWhereInput>;
  none?: InputMaybe<SessionScalarWhereInput>;
  some?: InputMaybe<SessionScalarWhereInput>;
};

export type SessionOrderByInput = {
  cancelledAt?: InputMaybe<OrderByArg>;
  category?: InputMaybe<CoachCategoryOrderByInput>;
  coach?: InputMaybe<CoachOrderByInput>;
  coachCategoryId?: InputMaybe<OrderByArg>;
  coachEventId?: InputMaybe<OrderByArg>;
  coachId?: InputMaybe<OrderByArg>;
  createdAt?: InputMaybe<OrderByArg>;
  endsAt?: InputMaybe<OrderByArg>;
  event?: InputMaybe<CoachEventOrderByInput>;
  googleEventId?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  noShow?: InputMaybe<OrderByArg>;
  startsAt?: InputMaybe<OrderByArg>;
  state?: InputMaybe<OrderByArg>;
  teamMember?: InputMaybe<TeamMemberOrderByInput>;
  teamMemberId?: InputMaybe<OrderByArg>;
  updatedAt?: InputMaybe<OrderByArg>;
};

export type SessionRelationFilter = {
  cancelledAt?: InputMaybe<AwsDateTimeFilter>;
  coachCategoryId?: InputMaybe<StringFilter>;
  coachEventId?: InputMaybe<StringFilter>;
  coachId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  endsAt?: InputMaybe<AwsDateTimeFilter>;
  googleEventId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  noShow?: InputMaybe<BooleanFilter>;
  startsAt?: InputMaybe<AwsDateTimeFilter>;
  state?: InputMaybe<SessionStateEnumFilter>;
  teamMemberId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type SessionScalarWhereInput = {
  cancelledAt?: InputMaybe<AwsDateTimeFilter>;
  coachCategoryId?: InputMaybe<StringFilter>;
  coachEventId?: InputMaybe<StringFilter>;
  coachId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  endsAt?: InputMaybe<AwsDateTimeFilter>;
  googleEventId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  noShow?: InputMaybe<BooleanFilter>;
  startsAt?: InputMaybe<AwsDateTimeFilter>;
  state?: InputMaybe<SessionStateEnumFilter>;
  teamMemberId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export enum SessionState {
  Cancelled = 'CANCELLED',
  CancelledCoach = 'CANCELLED_COACH',
  Completed = 'COMPLETED',
  Confirmed = 'CONFIRMED'
}

export type SessionStateEnumFilter = {
  equals?: InputMaybe<SessionState>;
  in?: InputMaybe<Array<SessionState>>;
  not?: InputMaybe<SessionStateEnumFilter>;
  notIn?: InputMaybe<Array<SessionState>>;
};

export type SessionTeamMemberCreateRelationInput = {
  connect?: InputMaybe<TeamMemberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamMemberConnectOrCreateInput>;
  create?: InputMaybe<TeamMemberCreateInput>;
};

export type SessionTeamMemberUpdateRelationsInput = {
  connect?: InputMaybe<TeamMemberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamMemberConnectOrCreateInput>;
  create?: InputMaybe<TeamMemberCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<TeamMemberUpdateInput>;
  upsert?: InputMaybe<TeamMemberUpsertInput>;
};

export type SessionUpdateInput = {
  cancelledAt?: InputMaybe<Scalars['AWSDateTime']>;
  category?: InputMaybe<SessionCategoryUpdateRelationsInput>;
  coach?: InputMaybe<SessionCoachUpdateRelationsInput>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']>;
  event?: InputMaybe<SessionEventUpdateRelationsInput>;
  googleEventId?: InputMaybe<Scalars['String']>;
  noShow?: InputMaybe<Scalars['Boolean']>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']>;
  state?: InputMaybe<SessionState>;
  teamMember?: InputMaybe<SessionTeamMemberUpdateRelationsInput>;
};

export type SessionUpdateManyInput = {
  data: SessionUpdateInput;
  where: SessionWhereInput;
};

export type SessionUpdateUniqueInput = {
  data: SessionUpdateInput;
  where: SessionWhereUniqueInput;
};

export type SessionUpsertInput = {
  create: SessionCreateInput;
  update: SessionUpdateInput;
};

export type SessionUpsertUniqueInput = {
  create: SessionCreateInput;
  update: SessionUpdateInput;
  where: SessionWhereUniqueInput;
};

export type SessionWhereInput = {
  AND?: InputMaybe<Array<InputMaybe<SessionWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<SessionWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<SessionWhereInput>>>;
  cancelledAt?: InputMaybe<AwsDateTimeFilter>;
  category?: InputMaybe<CoachCategoryRelationFilter>;
  coach?: InputMaybe<CoachRelationFilter>;
  coachCategoryId?: InputMaybe<StringFilter>;
  coachEventId?: InputMaybe<StringFilter>;
  coachId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  endsAt?: InputMaybe<AwsDateTimeFilter>;
  event?: InputMaybe<CoachEventRelationFilter>;
  googleEventId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  noShow?: InputMaybe<BooleanFilter>;
  startsAt?: InputMaybe<AwsDateTimeFilter>;
  state?: InputMaybe<SessionStateEnumFilter>;
  teamMember?: InputMaybe<TeamMemberRelationFilter>;
  teamMemberId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type SessionWhereUniqueInput = {
  coachCategoryId?: InputMaybe<Scalars['String']>;
  coachEventId?: InputMaybe<Scalars['String']>;
  coachId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  teamMemberId?: InputMaybe<Scalars['String']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  mode?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<StringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  /**   Triggered from `createCalendarSetting` mutation (excl. `createManyCalendarSettings` and `upsertCalendarSetting`). */
  onCreatedCalendarSetting?: Maybe<CalendarSetting>;
  /**   Triggered from `createCoach` mutation (excl. `createManyCoaches` and `upsertCoach`). */
  onCreatedCoach?: Maybe<Coach>;
  /**   Triggered from `createCoachApproach` mutation (excl. `createManyCoachApproaches` and `upsertCoachApproach`). */
  onCreatedCoachApproach?: Maybe<CoachApproach>;
  /**
   *   Triggered from `createCoachAudienceExperience` mutation (excl.
   * `createManyCoachAudienceExperiences` and `upsertCoachAudienceExperience`).
   */
  onCreatedCoachAudienceExperience?: Maybe<CoachAudienceExperience>;
  /**   Triggered from `createCoachCategory` mutation (excl. `createManyCoachCategories` and `upsertCoachCategory`). */
  onCreatedCoachCategory?: Maybe<CoachCategory>;
  /**   Triggered from `createCoachEvent` mutation (excl. `createManyCoachEvents` and `upsertCoachEvent`). */
  onCreatedCoachEvent?: Maybe<CoachEvent>;
  /**   Triggered from `createCoachReview` mutation (excl. `createManyCoachReviews` and `upsertCoachReview`). */
  onCreatedCoachReview?: Maybe<CoachReview>;
  /**   Triggered from `createCoachSpecialism` mutation (excl. `createManyCoachSpecialisms` and `upsertCoachSpecialism`). */
  onCreatedCoachSpecialism?: Maybe<CoachSpecialism>;
  /**   Triggered from `createCompany` mutation (excl. `createManyCompanies` and `upsertCompany`). */
  onCreatedCompany?: Maybe<Company>;
  /**   Triggered from `createCompanyAdmin` mutation (excl. `createManyCompanyAdmins` and `upsertCompanyAdmin`). */
  onCreatedCompanyAdmin?: Maybe<CompanyAdmin>;
  /**   Triggered from `createManyCalendarSettings` mutation. */
  onCreatedManyCalendarSettings?: Maybe<BatchPayload>;
  /**   Triggered from `createManyCoachApproaches` mutation. */
  onCreatedManyCoachApproaches?: Maybe<BatchPayload>;
  /**   Triggered from `createManyCoachAudienceExperiences` mutation. */
  onCreatedManyCoachAudienceExperiences?: Maybe<BatchPayload>;
  /**   Triggered from `createManyCoachCategories` mutation. */
  onCreatedManyCoachCategories?: Maybe<BatchPayload>;
  /**   Triggered from `createManyCoachEvents` mutation. */
  onCreatedManyCoachEvents?: Maybe<BatchPayload>;
  /**   Triggered from `createManyCoachReviews` mutation. */
  onCreatedManyCoachReviews?: Maybe<BatchPayload>;
  /**   Triggered from `createManyCoachSpecialisms` mutation. */
  onCreatedManyCoachSpecialisms?: Maybe<BatchPayload>;
  /**   Triggered from `createManyCoaches` mutation. */
  onCreatedManyCoaches?: Maybe<BatchPayload>;
  /**   Triggered from `createManyCompanies` mutation. */
  onCreatedManyCompanies?: Maybe<BatchPayload>;
  /**   Triggered from `createManyCompanyAdmins` mutation. */
  onCreatedManyCompanyAdmins?: Maybe<BatchPayload>;
  /**   Triggered from `createManySessions` mutation. */
  onCreatedManySessions?: Maybe<BatchPayload>;
  /**   Triggered from `createManyTeamMemberOutcomes` mutation. */
  onCreatedManyTeamMemberOutcomes?: Maybe<BatchPayload>;
  /**   Triggered from `createManyTeamMembers` mutation. */
  onCreatedManyTeamMembers?: Maybe<BatchPayload>;
  /**   Triggered from `createManyUsers` mutation. */
  onCreatedManyUsers?: Maybe<BatchPayload>;
  /**   Triggered from `createSession` mutation (excl. `createManySessions` and `upsertSession`). */
  onCreatedSession?: Maybe<Session>;
  /**   Triggered from `createTeamMember` mutation (excl. `createManyTeamMembers` and `upsertTeamMember`). */
  onCreatedTeamMember?: Maybe<TeamMember>;
  /**   Triggered from `createTeamMemberOutcome` mutation (excl. `createManyTeamMemberOutcomes` and `upsertTeamMemberOutcome`). */
  onCreatedTeamMemberOutcome?: Maybe<TeamMemberOutcome>;
  /**   Triggered from `createUser` mutation (excl. `createManyUsers` and `upsertUser`). */
  onCreatedUser?: Maybe<User>;
  /**   Triggered from `deleteCalendarSetting` mutation (excl. `deleteManyCalendarSettings`). */
  onDeletedCalendarSetting?: Maybe<CalendarSetting>;
  /**   Triggered from `deleteCoach` mutation (excl. `deleteManyCoaches`). */
  onDeletedCoach?: Maybe<Coach>;
  /**   Triggered from `deleteCoachApproach` mutation (excl. `deleteManyCoachApproaches`). */
  onDeletedCoachApproach?: Maybe<CoachApproach>;
  /**   Triggered from `deleteCoachAudienceExperience` mutation (excl. `deleteManyCoachAudienceExperiences`). */
  onDeletedCoachAudienceExperience?: Maybe<CoachAudienceExperience>;
  /**   Triggered from `deleteCoachCategory` mutation (excl. `deleteManyCoachCategories`). */
  onDeletedCoachCategory?: Maybe<CoachCategory>;
  /**   Triggered from `deleteCoachEvent` mutation (excl. `deleteManyCoachEvents`). */
  onDeletedCoachEvent?: Maybe<CoachEvent>;
  /**   Triggered from `deleteCoachReview` mutation (excl. `deleteManyCoachReviews`). */
  onDeletedCoachReview?: Maybe<CoachReview>;
  /**   Triggered from `deleteCoachSpecialism` mutation (excl. `deleteManyCoachSpecialisms`). */
  onDeletedCoachSpecialism?: Maybe<CoachSpecialism>;
  /**   Triggered from `deleteCompany` mutation (excl. `deleteManyCompanies`). */
  onDeletedCompany?: Maybe<Company>;
  /**   Triggered from `deleteCompanyAdmin` mutation (excl. `deleteManyCompanyAdmins`). */
  onDeletedCompanyAdmin?: Maybe<CompanyAdmin>;
  /**   Triggered from `deleteManyCalendarSettings` mutation. */
  onDeletedManyCalendarSettings?: Maybe<BatchPayload>;
  /**   Triggered from `deleteManyCoachApproaches` mutation. */
  onDeletedManyCoachApproaches?: Maybe<BatchPayload>;
  /**   Triggered from `deleteManyCoachAudienceExperiences` mutation. */
  onDeletedManyCoachAudienceExperiences?: Maybe<BatchPayload>;
  /**   Triggered from `deleteManyCoachCategories` mutation. */
  onDeletedManyCoachCategories?: Maybe<BatchPayload>;
  /**   Triggered from `deleteManyCoachEvents` mutation. */
  onDeletedManyCoachEvents?: Maybe<BatchPayload>;
  /**   Triggered from `deleteManyCoachReviews` mutation. */
  onDeletedManyCoachReviews?: Maybe<BatchPayload>;
  /**   Triggered from `deleteManyCoachSpecialisms` mutation. */
  onDeletedManyCoachSpecialisms?: Maybe<BatchPayload>;
  /**   Triggered from `deleteManyCoaches` mutation. */
  onDeletedManyCoaches?: Maybe<BatchPayload>;
  /**   Triggered from `deleteManyCompanies` mutation. */
  onDeletedManyCompanies?: Maybe<BatchPayload>;
  /**   Triggered from `deleteManyCompanyAdmins` mutation. */
  onDeletedManyCompanyAdmins?: Maybe<BatchPayload>;
  /**   Triggered from `deleteManySessions` mutation. */
  onDeletedManySessions?: Maybe<BatchPayload>;
  /**   Triggered from `deleteManyTeamMemberOutcomes` mutation. */
  onDeletedManyTeamMemberOutcomes?: Maybe<BatchPayload>;
  /**   Triggered from `deleteManyTeamMembers` mutation. */
  onDeletedManyTeamMembers?: Maybe<BatchPayload>;
  /**   Triggered from `deleteManyUsers` mutation. */
  onDeletedManyUsers?: Maybe<BatchPayload>;
  /**   Triggered from `deleteSession` mutation (excl. `deleteManySessions`). */
  onDeletedSession?: Maybe<Session>;
  /**   Triggered from `deleteTeamMember` mutation (excl. `deleteManyTeamMembers`). */
  onDeletedTeamMember?: Maybe<TeamMember>;
  /**   Triggered from `deleteTeamMemberOutcome` mutation (excl. `deleteManyTeamMemberOutcomes`). */
  onDeletedTeamMemberOutcome?: Maybe<TeamMemberOutcome>;
  /**   Triggered from `deleteUser` mutation (excl. `deleteManyUsers`). */
  onDeletedUser?: Maybe<User>;
  /**   Triggered from ANY SINGLE record mutation. */
  onMutatedCalendarSetting?: Maybe<CalendarSetting>;
  /**   Triggered from ANY SINGLE record mutation. */
  onMutatedCoach?: Maybe<Coach>;
  /**   Triggered from ANY SINGLE record mutation. */
  onMutatedCoachApproach?: Maybe<CoachApproach>;
  /**   Triggered from ANY SINGLE record mutation. */
  onMutatedCoachAudienceExperience?: Maybe<CoachAudienceExperience>;
  /**   Triggered from ANY SINGLE record mutation. */
  onMutatedCoachCategory?: Maybe<CoachCategory>;
  /**   Triggered from ANY SINGLE record mutation. */
  onMutatedCoachEvent?: Maybe<CoachEvent>;
  /**   Triggered from ANY SINGLE record mutation. */
  onMutatedCoachReview?: Maybe<CoachReview>;
  /**   Triggered from ANY SINGLE record mutation. */
  onMutatedCoachSpecialism?: Maybe<CoachSpecialism>;
  /**   Triggered from ANY SINGLE record mutation. */
  onMutatedCompany?: Maybe<Company>;
  /**   Triggered from ANY SINGLE record mutation. */
  onMutatedCompanyAdmin?: Maybe<CompanyAdmin>;
  /**   Triggered from ANY MULTIPLE records mutation. */
  onMutatedManyCalendarSettings?: Maybe<BatchPayload>;
  /**   Triggered from ANY MULTIPLE records mutation. */
  onMutatedManyCoachApproaches?: Maybe<BatchPayload>;
  /**   Triggered from ANY MULTIPLE records mutation. */
  onMutatedManyCoachAudienceExperiences?: Maybe<BatchPayload>;
  /**   Triggered from ANY MULTIPLE records mutation. */
  onMutatedManyCoachCategories?: Maybe<BatchPayload>;
  /**   Triggered from ANY MULTIPLE records mutation. */
  onMutatedManyCoachEvents?: Maybe<BatchPayload>;
  /**   Triggered from ANY MULTIPLE records mutation. */
  onMutatedManyCoachReviews?: Maybe<BatchPayload>;
  /**   Triggered from ANY MULTIPLE records mutation. */
  onMutatedManyCoachSpecialisms?: Maybe<BatchPayload>;
  /**   Triggered from ANY MULTIPLE records mutation. */
  onMutatedManyCoaches?: Maybe<BatchPayload>;
  /**   Triggered from ANY MULTIPLE records mutation. */
  onMutatedManyCompanies?: Maybe<BatchPayload>;
  /**   Triggered from ANY MULTIPLE records mutation. */
  onMutatedManyCompanyAdmins?: Maybe<BatchPayload>;
  /**   Triggered from ANY MULTIPLE records mutation. */
  onMutatedManySessions?: Maybe<BatchPayload>;
  /**   Triggered from ANY MULTIPLE records mutation. */
  onMutatedManyTeamMemberOutcomes?: Maybe<BatchPayload>;
  /**   Triggered from ANY MULTIPLE records mutation. */
  onMutatedManyTeamMembers?: Maybe<BatchPayload>;
  /**   Triggered from ANY MULTIPLE records mutation. */
  onMutatedManyUsers?: Maybe<BatchPayload>;
  /**   Triggered from ANY SINGLE record mutation. */
  onMutatedSession?: Maybe<Session>;
  /**   Triggered from ANY SINGLE record mutation. */
  onMutatedTeamMember?: Maybe<TeamMember>;
  /**   Triggered from ANY SINGLE record mutation. */
  onMutatedTeamMemberOutcome?: Maybe<TeamMemberOutcome>;
  /**   Triggered from ANY SINGLE record mutation. */
  onMutatedUser?: Maybe<User>;
  /**   Triggered from `updateCalendarSetting` mutation (excl. `updateManyCalendarSettings` and `upsertCalendarSetting`). */
  onUpdatedCalendarSetting?: Maybe<CalendarSetting>;
  /**   Triggered from `updateCoach` mutation (excl. `updateManyCoaches` and `upsertCoach`). */
  onUpdatedCoach?: Maybe<Coach>;
  /**   Triggered from `updateCoachApproach` mutation (excl. `updateManyCoachApproaches` and `upsertCoachApproach`). */
  onUpdatedCoachApproach?: Maybe<CoachApproach>;
  /**
   *   Triggered from `updateCoachAudienceExperience` mutation (excl.
   * `updateManyCoachAudienceExperiences` and `upsertCoachAudienceExperience`).
   */
  onUpdatedCoachAudienceExperience?: Maybe<CoachAudienceExperience>;
  /**   Triggered from `updateCoachCategory` mutation (excl. `updateManyCoachCategories` and `upsertCoachCategory`). */
  onUpdatedCoachCategory?: Maybe<CoachCategory>;
  /**   Triggered from `updateCoachEvent` mutation (excl. `updateManyCoachEvents` and `upsertCoachEvent`). */
  onUpdatedCoachEvent?: Maybe<CoachEvent>;
  /**   Triggered from `updateCoachReview` mutation (excl. `updateManyCoachReviews` and `upsertCoachReview`). */
  onUpdatedCoachReview?: Maybe<CoachReview>;
  /**   Triggered from `updateCoachSpecialism` mutation (excl. `updateManyCoachSpecialisms` and `upsertCoachSpecialism`). */
  onUpdatedCoachSpecialism?: Maybe<CoachSpecialism>;
  /**   Triggered from `updateCompany` mutation (excl. `updateManyCompanies` and `upsertCompany`). */
  onUpdatedCompany?: Maybe<Company>;
  /**   Triggered from `updateCompanyAdmin` mutation (excl. `updateManyCompanyAdmins` and `upsertCompanyAdmin`). */
  onUpdatedCompanyAdmin?: Maybe<CompanyAdmin>;
  /**   Triggered from `updateManyCalendarSettings` mutation. */
  onUpdatedManyCalendarSettings?: Maybe<BatchPayload>;
  /**   Triggered from `updateManyCoachApproaches` mutation. */
  onUpdatedManyCoachApproaches?: Maybe<BatchPayload>;
  /**   Triggered from `updateManyCoachAudienceExperiences` mutation. */
  onUpdatedManyCoachAudienceExperiences?: Maybe<BatchPayload>;
  /**   Triggered from `updateManyCoachCategories` mutation. */
  onUpdatedManyCoachCategories?: Maybe<BatchPayload>;
  /**   Triggered from `updateManyCoachEvents` mutation. */
  onUpdatedManyCoachEvents?: Maybe<BatchPayload>;
  /**   Triggered from `updateManyCoachReviews` mutation. */
  onUpdatedManyCoachReviews?: Maybe<BatchPayload>;
  /**   Triggered from `updateManyCoachSpecialisms` mutation. */
  onUpdatedManyCoachSpecialisms?: Maybe<BatchPayload>;
  /**   Triggered from `updateManyCoaches` mutation. */
  onUpdatedManyCoaches?: Maybe<BatchPayload>;
  /**   Triggered from `updateManyCompanies` mutation. */
  onUpdatedManyCompanies?: Maybe<BatchPayload>;
  /**   Triggered from `updateManyCompanyAdmins` mutation. */
  onUpdatedManyCompanyAdmins?: Maybe<BatchPayload>;
  /**   Triggered from `updateManySessions` mutation. */
  onUpdatedManySessions?: Maybe<BatchPayload>;
  /**   Triggered from `updateManyTeamMemberOutcomes` mutation. */
  onUpdatedManyTeamMemberOutcomes?: Maybe<BatchPayload>;
  /**   Triggered from `updateManyTeamMembers` mutation. */
  onUpdatedManyTeamMembers?: Maybe<BatchPayload>;
  /**   Triggered from `updateManyUsers` mutation. */
  onUpdatedManyUsers?: Maybe<BatchPayload>;
  /**   Triggered from `updateSession` mutation (excl. `updateManySessions` and `upsertSession`). */
  onUpdatedSession?: Maybe<Session>;
  /**   Triggered from `updateTeamMember` mutation (excl. `updateManyTeamMembers` and `upsertTeamMember`). */
  onUpdatedTeamMember?: Maybe<TeamMember>;
  /**   Triggered from `updateTeamMemberOutcome` mutation (excl. `updateManyTeamMemberOutcomes` and `upsertTeamMemberOutcome`). */
  onUpdatedTeamMemberOutcome?: Maybe<TeamMemberOutcome>;
  /**   Triggered from `updateUser` mutation (excl. `updateManyUsers` and `upsertUser`). */
  onUpdatedUser?: Maybe<User>;
  /**   Triggered from `upsertCalendarSetting` mutation. */
  onUpsertedCalendarSetting?: Maybe<CalendarSetting>;
  /**   Triggered from `upsertCoach` mutation. */
  onUpsertedCoach?: Maybe<Coach>;
  /**   Triggered from `upsertCoachApproach` mutation. */
  onUpsertedCoachApproach?: Maybe<CoachApproach>;
  /**   Triggered from `upsertCoachAudienceExperience` mutation. */
  onUpsertedCoachAudienceExperience?: Maybe<CoachAudienceExperience>;
  /**   Triggered from `upsertCoachCategory` mutation. */
  onUpsertedCoachCategory?: Maybe<CoachCategory>;
  /**   Triggered from `upsertCoachEvent` mutation. */
  onUpsertedCoachEvent?: Maybe<CoachEvent>;
  /**   Triggered from `upsertCoachReview` mutation. */
  onUpsertedCoachReview?: Maybe<CoachReview>;
  /**   Triggered from `upsertCoachSpecialism` mutation. */
  onUpsertedCoachSpecialism?: Maybe<CoachSpecialism>;
  /**   Triggered from `upsertCompany` mutation. */
  onUpsertedCompany?: Maybe<Company>;
  /**   Triggered from `upsertCompanyAdmin` mutation. */
  onUpsertedCompanyAdmin?: Maybe<CompanyAdmin>;
  /**   Triggered from `upsertSession` mutation. */
  onUpsertedSession?: Maybe<Session>;
  /**   Triggered from `upsertTeamMember` mutation. */
  onUpsertedTeamMember?: Maybe<TeamMember>;
  /**   Triggered from `upsertTeamMemberOutcome` mutation. */
  onUpsertedTeamMemberOutcome?: Maybe<TeamMemberOutcome>;
  /**   Triggered from `upsertUser` mutation. */
  onUpsertedUser?: Maybe<User>;
};


export type SubscriptionOnCreatedCalendarSettingArgs = {
  coachId?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnCreatedCoachArgs = {
  handle?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnCreatedCoachApproachArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnCreatedCoachAudienceExperienceArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnCreatedCoachCategoryArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnCreatedCoachEventArgs = {
  coachId?: InputMaybe<Scalars['String']>;
  eventId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnCreatedCoachReviewArgs = {
  coachId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  teamMemberId?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnCreatedCoachSpecialismArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnCreatedCompanyArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnCreatedCompanyAdminArgs = {
  companyId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnCreatedSessionArgs = {
  coachCategoryId?: InputMaybe<Scalars['String']>;
  coachEventId?: InputMaybe<Scalars['String']>;
  coachId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  teamMemberId?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnCreatedTeamMemberArgs = {
  companyId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  outcomeId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnCreatedTeamMemberOutcomeArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnCreatedUserArgs = {
  cognitoId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['AWSEmail']>;
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnDeletedCalendarSettingArgs = {
  coachId?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnDeletedCoachArgs = {
  handle?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnDeletedCoachApproachArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnDeletedCoachAudienceExperienceArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnDeletedCoachCategoryArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnDeletedCoachEventArgs = {
  coachId?: InputMaybe<Scalars['String']>;
  eventId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnDeletedCoachReviewArgs = {
  coachId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  teamMemberId?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnDeletedCoachSpecialismArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnDeletedCompanyArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnDeletedCompanyAdminArgs = {
  companyId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnDeletedSessionArgs = {
  coachCategoryId?: InputMaybe<Scalars['String']>;
  coachEventId?: InputMaybe<Scalars['String']>;
  coachId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  teamMemberId?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnDeletedTeamMemberArgs = {
  companyId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  outcomeId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnDeletedTeamMemberOutcomeArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnDeletedUserArgs = {
  cognitoId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['AWSEmail']>;
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnMutatedCalendarSettingArgs = {
  coachId?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnMutatedCoachArgs = {
  handle?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnMutatedCoachApproachArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnMutatedCoachAudienceExperienceArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnMutatedCoachCategoryArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnMutatedCoachEventArgs = {
  coachId?: InputMaybe<Scalars['String']>;
  eventId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnMutatedCoachReviewArgs = {
  coachId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  teamMemberId?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnMutatedCoachSpecialismArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnMutatedCompanyArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnMutatedCompanyAdminArgs = {
  companyId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnMutatedSessionArgs = {
  coachCategoryId?: InputMaybe<Scalars['String']>;
  coachEventId?: InputMaybe<Scalars['String']>;
  coachId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  teamMemberId?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnMutatedTeamMemberArgs = {
  companyId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  outcomeId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnMutatedTeamMemberOutcomeArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnMutatedUserArgs = {
  cognitoId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['AWSEmail']>;
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnUpdatedCalendarSettingArgs = {
  coachId?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnUpdatedCoachArgs = {
  handle?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnUpdatedCoachApproachArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnUpdatedCoachAudienceExperienceArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnUpdatedCoachCategoryArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnUpdatedCoachEventArgs = {
  coachId?: InputMaybe<Scalars['String']>;
  eventId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnUpdatedCoachReviewArgs = {
  coachId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  teamMemberId?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnUpdatedCoachSpecialismArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnUpdatedCompanyArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnUpdatedCompanyAdminArgs = {
  companyId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnUpdatedSessionArgs = {
  coachCategoryId?: InputMaybe<Scalars['String']>;
  coachEventId?: InputMaybe<Scalars['String']>;
  coachId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  teamMemberId?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnUpdatedTeamMemberArgs = {
  companyId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  outcomeId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnUpdatedTeamMemberOutcomeArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnUpdatedUserArgs = {
  cognitoId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['AWSEmail']>;
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnUpsertedCalendarSettingArgs = {
  coachId?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnUpsertedCoachArgs = {
  handle?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnUpsertedCoachApproachArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnUpsertedCoachAudienceExperienceArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnUpsertedCoachCategoryArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnUpsertedCoachEventArgs = {
  coachId?: InputMaybe<Scalars['String']>;
  eventId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnUpsertedCoachReviewArgs = {
  coachId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  teamMemberId?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnUpsertedCoachSpecialismArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnUpsertedCompanyArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnUpsertedCompanyAdminArgs = {
  companyId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnUpsertedSessionArgs = {
  coachCategoryId?: InputMaybe<Scalars['String']>;
  coachEventId?: InputMaybe<Scalars['String']>;
  coachId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  teamMemberId?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnUpsertedTeamMemberArgs = {
  companyId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  outcomeId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnUpsertedTeamMemberOutcomeArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnUpsertedUserArgs = {
  cognitoId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['AWSEmail']>;
  id?: InputMaybe<Scalars['String']>;
};

export type TeamMember = {
  __typename?: 'TeamMember';
  archivedAt?: Maybe<Scalars['AWSDateTime']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['String']>;
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['String'];
  isManager?: Maybe<Scalars['Boolean']>;
  jobTitle?: Maybe<Scalars['String']>;
  onboardingCallJoined?: Maybe<Scalars['Boolean']>;
  onboardingCallRegistered?: Maybe<Scalars['Boolean']>;
  originalImpersonationCompanyId?: Maybe<Scalars['String']>;
  outcome?: Maybe<TeamMemberOutcome>;
  outcomeId?: Maybe<Scalars['String']>;
  reviews?: Maybe<Array<CoachReview>>;
  sessions?: Maybe<Array<Session>>;
  updatedAt: Scalars['AWSDateTime'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};

export type TeamMemberCompanyCreateRelationInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyConnectOrCreateInput>;
  create?: InputMaybe<CompanyCreateInput>;
};

export type TeamMemberCompanyUpdateRelationsInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyConnectOrCreateInput>;
  create?: InputMaybe<CompanyCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CompanyUpdateInput>;
  upsert?: InputMaybe<CompanyUpsertInput>;
};

export type TeamMemberConnectOrCreateInput = {
  create: TeamMemberCreateInput;
  where: TeamMemberWhereUniqueInput;
};

export type TeamMemberCreateInput = {
  archivedAt?: InputMaybe<Scalars['AWSDateTime']>;
  company?: InputMaybe<TeamMemberCompanyCreateRelationInput>;
  isManager?: InputMaybe<Scalars['Boolean']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  onboardingCallJoined?: InputMaybe<Scalars['Boolean']>;
  onboardingCallRegistered?: InputMaybe<Scalars['Boolean']>;
  originalImpersonationCompanyId?: InputMaybe<Scalars['String']>;
  outcome?: InputMaybe<TeamMemberOutcomeCreateRelationInput>;
  reviews?: InputMaybe<TeamMemberReviewsCreateRelationInput>;
  sessions?: InputMaybe<TeamMemberSessionsCreateRelationInput>;
  user?: InputMaybe<TeamMemberUserCreateRelationInput>;
};

export type TeamMemberCreateManyInput = {
  archivedAt?: InputMaybe<Scalars['AWSDateTime']>;
  isManager?: InputMaybe<Scalars['Boolean']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  onboardingCallJoined?: InputMaybe<Scalars['Boolean']>;
  onboardingCallRegistered?: InputMaybe<Scalars['Boolean']>;
  originalImpersonationCompanyId?: InputMaybe<Scalars['String']>;
};

export type TeamMemberDeleteManyInput = {
  where: TeamMemberWhereInput;
};

export type TeamMemberDeleteUniqueInput = {
  where: TeamMemberWhereUniqueInput;
};

export type TeamMemberFilter = {
  every?: InputMaybe<TeamMemberScalarWhereInput>;
  none?: InputMaybe<TeamMemberScalarWhereInput>;
  some?: InputMaybe<TeamMemberScalarWhereInput>;
};

export type TeamMemberOrderByInput = {
  archivedAt?: InputMaybe<OrderByArg>;
  company?: InputMaybe<CompanyOrderByInput>;
  companyId?: InputMaybe<OrderByArg>;
  createdAt?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  isManager?: InputMaybe<OrderByArg>;
  jobTitle?: InputMaybe<OrderByArg>;
  onboardingCallJoined?: InputMaybe<OrderByArg>;
  onboardingCallRegistered?: InputMaybe<OrderByArg>;
  originalImpersonationCompanyId?: InputMaybe<OrderByArg>;
  outcome?: InputMaybe<TeamMemberOutcomeOrderByInput>;
  outcomeId?: InputMaybe<OrderByArg>;
  reviews?: InputMaybe<CoachReviewOrderByInput>;
  sessions?: InputMaybe<SessionOrderByInput>;
  updatedAt?: InputMaybe<OrderByArg>;
  user?: InputMaybe<UserOrderByInput>;
  userId?: InputMaybe<OrderByArg>;
};

export type TeamMemberOutcome = {
  __typename?: 'TeamMemberOutcome';
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['String'];
  label: Scalars['String'];
  teamMembers?: Maybe<Array<TeamMember>>;
  updatedAt: Scalars['AWSDateTime'];
  value: Scalars['String'];
};

export type TeamMemberOutcomeConnectOrCreateInput = {
  create: TeamMemberOutcomeCreateInput;
  where: TeamMemberOutcomeWhereUniqueInput;
};

export type TeamMemberOutcomeCreateInput = {
  label: Scalars['String'];
  teamMembers?: InputMaybe<TeamMemberOutcomeTeamMembersCreateRelationInput>;
  value: Scalars['String'];
};

export type TeamMemberOutcomeCreateManyInput = {
  label: Scalars['String'];
  value: Scalars['String'];
};

export type TeamMemberOutcomeCreateRelationInput = {
  connect?: InputMaybe<TeamMemberOutcomeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamMemberOutcomeConnectOrCreateInput>;
  create?: InputMaybe<TeamMemberOutcomeCreateInput>;
};

export type TeamMemberOutcomeDeleteManyInput = {
  where: TeamMemberOutcomeWhereInput;
};

export type TeamMemberOutcomeDeleteUniqueInput = {
  where: TeamMemberOutcomeWhereUniqueInput;
};

export type TeamMemberOutcomeFilter = {
  every?: InputMaybe<TeamMemberOutcomeScalarWhereInput>;
  none?: InputMaybe<TeamMemberOutcomeScalarWhereInput>;
  some?: InputMaybe<TeamMemberOutcomeScalarWhereInput>;
};

export type TeamMemberOutcomeOrderByInput = {
  createdAt?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  label?: InputMaybe<OrderByArg>;
  teamMembers?: InputMaybe<TeamMemberOrderByInput>;
  updatedAt?: InputMaybe<OrderByArg>;
  value?: InputMaybe<OrderByArg>;
};

export type TeamMemberOutcomeRelationFilter = {
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type TeamMemberOutcomeScalarWhereInput = {
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type TeamMemberOutcomeTeamMembersCreateRelationInput = {
  connect?: InputMaybe<Array<InputMaybe<TeamMemberWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<TeamMemberConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<TeamMemberCreateInput>>>;
};

export type TeamMemberOutcomeTeamMembersUpdateRelationsInput = {
  connect?: InputMaybe<Array<InputMaybe<TeamMemberWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<TeamMemberConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<TeamMemberCreateInput>>>;
  delete?: InputMaybe<Array<InputMaybe<TeamMemberDeleteUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<TeamMemberDeleteManyInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<TeamMemberWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<TeamMemberWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<TeamMemberUpdateUniqueInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<TeamMemberUpdateManyInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<TeamMemberUpsertUniqueInput>>>;
};

export type TeamMemberOutcomeUpdateInput = {
  label?: InputMaybe<Scalars['String']>;
  teamMembers?: InputMaybe<TeamMemberOutcomeTeamMembersUpdateRelationsInput>;
  value?: InputMaybe<Scalars['String']>;
};

export type TeamMemberOutcomeUpdateManyInput = {
  data: TeamMemberOutcomeUpdateInput;
  where: TeamMemberOutcomeWhereInput;
};

export type TeamMemberOutcomeUpdateRelationsInput = {
  connect?: InputMaybe<TeamMemberOutcomeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamMemberOutcomeConnectOrCreateInput>;
  create?: InputMaybe<TeamMemberOutcomeCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<TeamMemberOutcomeUpdateInput>;
  upsert?: InputMaybe<TeamMemberOutcomeUpsertInput>;
};

export type TeamMemberOutcomeUpdateUniqueInput = {
  data: TeamMemberOutcomeUpdateInput;
  where: TeamMemberOutcomeWhereUniqueInput;
};

export type TeamMemberOutcomeUpsertInput = {
  create: TeamMemberOutcomeCreateInput;
  update: TeamMemberOutcomeUpdateInput;
};

export type TeamMemberOutcomeUpsertUniqueInput = {
  create: TeamMemberOutcomeCreateInput;
  update: TeamMemberOutcomeUpdateInput;
  where: TeamMemberOutcomeWhereUniqueInput;
};

export type TeamMemberOutcomeWhereInput = {
  AND?: InputMaybe<Array<InputMaybe<TeamMemberOutcomeWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<TeamMemberOutcomeWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<TeamMemberOutcomeWhereInput>>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  teamMembers?: InputMaybe<TeamMemberFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type TeamMemberOutcomeWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type TeamMemberRelationFilter = {
  archivedAt?: InputMaybe<AwsDateTimeFilter>;
  companyId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isManager?: InputMaybe<BooleanFilter>;
  jobTitle?: InputMaybe<StringFilter>;
  onboardingCallJoined?: InputMaybe<BooleanFilter>;
  onboardingCallRegistered?: InputMaybe<BooleanFilter>;
  originalImpersonationCompanyId?: InputMaybe<StringFilter>;
  outcomeId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type TeamMemberReviewsCreateRelationInput = {
  connect?: InputMaybe<Array<InputMaybe<CoachReviewWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CoachReviewConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<CoachReviewCreateInput>>>;
};

export type TeamMemberReviewsUpdateRelationsInput = {
  connect?: InputMaybe<Array<InputMaybe<CoachReviewWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CoachReviewConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<CoachReviewCreateInput>>>;
  delete?: InputMaybe<Array<InputMaybe<CoachReviewDeleteUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<CoachReviewDeleteManyInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<CoachReviewWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<CoachReviewWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<CoachReviewUpdateUniqueInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<CoachReviewUpdateManyInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<CoachReviewUpsertUniqueInput>>>;
};

export type TeamMemberScalarWhereInput = {
  archivedAt?: InputMaybe<AwsDateTimeFilter>;
  companyId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isManager?: InputMaybe<BooleanFilter>;
  jobTitle?: InputMaybe<StringFilter>;
  onboardingCallJoined?: InputMaybe<BooleanFilter>;
  onboardingCallRegistered?: InputMaybe<BooleanFilter>;
  originalImpersonationCompanyId?: InputMaybe<StringFilter>;
  outcomeId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type TeamMemberSessionsCreateRelationInput = {
  connect?: InputMaybe<Array<InputMaybe<SessionWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SessionConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<SessionCreateInput>>>;
};

export type TeamMemberSessionsUpdateRelationsInput = {
  connect?: InputMaybe<Array<InputMaybe<SessionWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SessionConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<SessionCreateInput>>>;
  delete?: InputMaybe<Array<InputMaybe<SessionDeleteUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<SessionDeleteManyInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<SessionWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<SessionWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<SessionUpdateUniqueInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<SessionUpdateManyInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<SessionUpsertUniqueInput>>>;
};

export type TeamMemberUpdateInput = {
  archivedAt?: InputMaybe<Scalars['AWSDateTime']>;
  company?: InputMaybe<TeamMemberCompanyUpdateRelationsInput>;
  isManager?: InputMaybe<Scalars['Boolean']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  onboardingCallJoined?: InputMaybe<Scalars['Boolean']>;
  onboardingCallRegistered?: InputMaybe<Scalars['Boolean']>;
  originalImpersonationCompanyId?: InputMaybe<Scalars['String']>;
  outcome?: InputMaybe<TeamMemberOutcomeUpdateRelationsInput>;
  reviews?: InputMaybe<TeamMemberReviewsUpdateRelationsInput>;
  sessions?: InputMaybe<TeamMemberSessionsUpdateRelationsInput>;
  user?: InputMaybe<TeamMemberUserUpdateRelationsInput>;
};

export type TeamMemberUpdateManyInput = {
  data: TeamMemberUpdateInput;
  where: TeamMemberWhereInput;
};

export type TeamMemberUpdateUniqueInput = {
  data: TeamMemberUpdateInput;
  where: TeamMemberWhereUniqueInput;
};

export type TeamMemberUpsertInput = {
  create: TeamMemberCreateInput;
  update: TeamMemberUpdateInput;
};

export type TeamMemberUpsertUniqueInput = {
  create: TeamMemberCreateInput;
  update: TeamMemberUpdateInput;
  where: TeamMemberWhereUniqueInput;
};

export type TeamMemberUserCreateRelationInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserConnectOrCreateInput>;
  create?: InputMaybe<UserCreateInput>;
};

export type TeamMemberUserUpdateRelationsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserConnectOrCreateInput>;
  create?: InputMaybe<UserCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserUpdateInput>;
  upsert?: InputMaybe<UserUpsertInput>;
};

export type TeamMemberWhereInput = {
  AND?: InputMaybe<Array<InputMaybe<TeamMemberWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<TeamMemberWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<TeamMemberWhereInput>>>;
  archivedAt?: InputMaybe<AwsDateTimeFilter>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isManager?: InputMaybe<BooleanFilter>;
  jobTitle?: InputMaybe<StringFilter>;
  onboardingCallJoined?: InputMaybe<BooleanFilter>;
  onboardingCallRegistered?: InputMaybe<BooleanFilter>;
  originalImpersonationCompanyId?: InputMaybe<StringFilter>;
  outcome?: InputMaybe<TeamMemberOutcomeRelationFilter>;
  outcomeId?: InputMaybe<StringFilter>;
  reviews?: InputMaybe<CoachReviewFilter>;
  sessions?: InputMaybe<SessionFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type TeamMemberWhereUniqueInput = {
  companyId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  outcomeId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  activationState: ActivationState;
  avatarKey?: Maybe<Scalars['String']>;
  coach?: Maybe<Coach>;
  cognitoId?: Maybe<Scalars['String']>;
  createdAt: Scalars['AWSDateTime'];
  email: Scalars['AWSEmail'];
  firstName: Scalars['String'];
  hubspotContactId?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  intercomUserId?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  isImpersonating: Scalars['Boolean'];
  lastLogin?: Maybe<Scalars['AWSDateTime']>;
  lastName: Scalars['String'];
  role: UserRole;
  teamMember?: Maybe<TeamMember>;
  type: UserType;
  updatedAt: Scalars['AWSDateTime'];
};

export type UserCoachCreateRelationInput = {
  connect?: InputMaybe<Array<InputMaybe<CoachWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CoachConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<CoachCreateInput>>>;
};

export type UserCoachUpdateRelationsInput = {
  connect?: InputMaybe<Array<InputMaybe<CoachWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CoachConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<CoachCreateInput>>>;
  delete?: InputMaybe<Array<InputMaybe<CoachDeleteUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<CoachDeleteManyInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<CoachWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<CoachWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<CoachUpdateUniqueInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<CoachUpdateManyInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<CoachUpsertUniqueInput>>>;
};

export type UserConnectOrCreateInput = {
  create: UserCreateInput;
  where: UserWhereUniqueInput;
};

export type UserCreateInput = {
  activationState: ActivationState;
  avatarKey?: InputMaybe<Scalars['String']>;
  coach?: InputMaybe<UserCoachCreateRelationInput>;
  cognitoId?: InputMaybe<Scalars['String']>;
  email: Scalars['AWSEmail'];
  firstName: Scalars['String'];
  hubspotContactId?: InputMaybe<Scalars['Int']>;
  intercomUserId?: InputMaybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  isImpersonating: Scalars['Boolean'];
  lastLogin?: InputMaybe<Scalars['AWSDateTime']>;
  lastName: Scalars['String'];
  role: UserRole;
  teamMember?: InputMaybe<UserTeamMemberCreateRelationInput>;
  type: UserType;
};

export type UserCreateManyInput = {
  activationState: ActivationState;
  avatarKey?: InputMaybe<Scalars['String']>;
  cognitoId?: InputMaybe<Scalars['String']>;
  email: Scalars['AWSEmail'];
  firstName: Scalars['String'];
  hubspotContactId?: InputMaybe<Scalars['Int']>;
  intercomUserId?: InputMaybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  isImpersonating: Scalars['Boolean'];
  lastLogin?: InputMaybe<Scalars['AWSDateTime']>;
  lastName: Scalars['String'];
  role: UserRole;
  type: UserType;
};

export type UserDeleteManyInput = {
  where: UserWhereInput;
};

export type UserDeleteUniqueInput = {
  where: UserWhereUniqueInput;
};

export type UserFilter = {
  every?: InputMaybe<UserScalarWhereInput>;
  none?: InputMaybe<UserScalarWhereInput>;
  some?: InputMaybe<UserScalarWhereInput>;
};

export type UserOperationInput = {
  hubspotContactId?: InputMaybe<IntOperation>;
};

export type UserOrderByInput = {
  activationState?: InputMaybe<OrderByArg>;
  avatarKey?: InputMaybe<OrderByArg>;
  coach?: InputMaybe<CoachOrderByInput>;
  cognitoId?: InputMaybe<OrderByArg>;
  createdAt?: InputMaybe<OrderByArg>;
  email?: InputMaybe<OrderByArg>;
  firstName?: InputMaybe<OrderByArg>;
  hubspotContactId?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  intercomUserId?: InputMaybe<OrderByArg>;
  isActive?: InputMaybe<OrderByArg>;
  isImpersonating?: InputMaybe<OrderByArg>;
  lastLogin?: InputMaybe<OrderByArg>;
  lastName?: InputMaybe<OrderByArg>;
  role?: InputMaybe<OrderByArg>;
  teamMember?: InputMaybe<TeamMemberOrderByInput>;
  type?: InputMaybe<OrderByArg>;
  updatedAt?: InputMaybe<OrderByArg>;
};

export type UserRelationFilter = {
  activationState?: InputMaybe<ActivationStateEnumFilter>;
  avatarKey?: InputMaybe<StringFilter>;
  cognitoId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  email?: InputMaybe<AwsEmailFilter>;
  firstName?: InputMaybe<StringFilter>;
  hubspotContactId?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  intercomUserId?: InputMaybe<StringFilter>;
  isActive?: InputMaybe<BooleanFilter>;
  isImpersonating?: InputMaybe<BooleanFilter>;
  lastLogin?: InputMaybe<AwsDateTimeFilter>;
  lastName?: InputMaybe<StringFilter>;
  role?: InputMaybe<UserRoleEnumFilter>;
  type?: InputMaybe<UserTypeEnumFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export enum UserRole {
  Admin = 'admin',
  Guest = 'guest',
  Sponsor = 'sponsor',
  Superadmin = 'superadmin',
  TeamMember = 'team_member'
}

export type UserRoleEnumFilter = {
  equals?: InputMaybe<UserRole>;
  in?: InputMaybe<Array<UserRole>>;
  not?: InputMaybe<UserRoleEnumFilter>;
  notIn?: InputMaybe<Array<UserRole>>;
};

export type UserScalarWhereInput = {
  activationState?: InputMaybe<ActivationStateEnumFilter>;
  avatarKey?: InputMaybe<StringFilter>;
  cognitoId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  email?: InputMaybe<AwsEmailFilter>;
  firstName?: InputMaybe<StringFilter>;
  hubspotContactId?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  intercomUserId?: InputMaybe<StringFilter>;
  isActive?: InputMaybe<BooleanFilter>;
  isImpersonating?: InputMaybe<BooleanFilter>;
  lastLogin?: InputMaybe<AwsDateTimeFilter>;
  lastName?: InputMaybe<StringFilter>;
  role?: InputMaybe<UserRoleEnumFilter>;
  type?: InputMaybe<UserTypeEnumFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type UserTeamMemberCreateRelationInput = {
  connect?: InputMaybe<Array<InputMaybe<TeamMemberWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<TeamMemberConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<TeamMemberCreateInput>>>;
};

export type UserTeamMemberUpdateRelationsInput = {
  connect?: InputMaybe<Array<InputMaybe<TeamMemberWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<TeamMemberConnectOrCreateInput>>>;
  create?: InputMaybe<Array<InputMaybe<TeamMemberCreateInput>>>;
  delete?: InputMaybe<Array<InputMaybe<TeamMemberDeleteUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<TeamMemberDeleteManyInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<TeamMemberWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<TeamMemberWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<TeamMemberUpdateUniqueInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<TeamMemberUpdateManyInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<TeamMemberUpsertUniqueInput>>>;
};

export enum UserType {
  Coach = 'coach',
  TeamMember = 'teamMember'
}

export type UserTypeEnumFilter = {
  equals?: InputMaybe<UserType>;
  in?: InputMaybe<Array<UserType>>;
  not?: InputMaybe<UserTypeEnumFilter>;
  notIn?: InputMaybe<Array<UserType>>;
};

export type UserUpdateInput = {
  activationState?: InputMaybe<ActivationState>;
  avatarKey?: InputMaybe<Scalars['String']>;
  coach?: InputMaybe<UserCoachUpdateRelationsInput>;
  cognitoId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['AWSEmail']>;
  firstName?: InputMaybe<Scalars['String']>;
  hubspotContactId?: InputMaybe<Scalars['Int']>;
  intercomUserId?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isImpersonating?: InputMaybe<Scalars['Boolean']>;
  lastLogin?: InputMaybe<Scalars['AWSDateTime']>;
  lastName?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserRole>;
  teamMember?: InputMaybe<UserTeamMemberUpdateRelationsInput>;
  type?: InputMaybe<UserType>;
};

export type UserUpdateManyInput = {
  data: UserUpdateInput;
  where: UserWhereInput;
};

export type UserUpdateUniqueInput = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertInput = {
  create: UserCreateInput;
  update: UserUpdateInput;
};

export type UserUpsertUniqueInput = {
  create: UserCreateInput;
  update: UserUpdateInput;
  where: UserWhereUniqueInput;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<InputMaybe<UserWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<UserWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<UserWhereInput>>>;
  activationState?: InputMaybe<ActivationStateEnumFilter>;
  avatarKey?: InputMaybe<StringFilter>;
  coach?: InputMaybe<CoachFilter>;
  cognitoId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  email?: InputMaybe<AwsEmailFilter>;
  firstName?: InputMaybe<StringFilter>;
  hubspotContactId?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  intercomUserId?: InputMaybe<StringFilter>;
  isActive?: InputMaybe<BooleanFilter>;
  isImpersonating?: InputMaybe<BooleanFilter>;
  lastLogin?: InputMaybe<AwsDateTimeFilter>;
  lastName?: InputMaybe<StringFilter>;
  role?: InputMaybe<UserRoleEnumFilter>;
  teamMember?: InputMaybe<TeamMemberFilter>;
  type?: InputMaybe<UserTypeEnumFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type UserWhereUniqueInput = {
  cognitoId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['AWSEmail']>;
  id?: InputMaybe<Scalars['String']>;
};

export type AdminImpersonateCompanyMutationVariables = Exact<{
  userId: Scalars['String'];
  companyId: Scalars['String'];
}>;


export type AdminImpersonateCompanyMutation = { __typename?: 'Mutation', adminImpersonateCompany: { __typename?: 'ImpersonateCompanyResponse', success: boolean, companyId: string } };

export type CoachAvailabilityQueryVariables = Exact<{
  coachId: Scalars['String'];
}>;


export type CoachAvailabilityQuery = { __typename?: 'Query', coachAvailability?: Array<{ __typename?: 'CoachEvent', id: string, eventId: string, status?: string | null, startsAt?: any | null, endsAt?: any | null, timeZone?: string | null, iCalUID?: string | null, recurringEventId?: string | null } | null> | null };

export type CountCoachesQueryVariables = Exact<{
  where?: InputMaybe<CoachWhereInput>;
  orderBy?: InputMaybe<Array<InputMaybe<CoachOrderByInput>> | InputMaybe<CoachOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
}>;


export type CountCoachesQuery = { __typename?: 'Query', countCoaches?: number | null };

export type CountTeamMembersQueryVariables = Exact<{
  where?: InputMaybe<TeamMemberWhereInput>;
  orderBy?: InputMaybe<Array<InputMaybe<TeamMemberOrderByInput>> | InputMaybe<TeamMemberOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
}>;


export type CountTeamMembersQuery = { __typename?: 'Query', countTeamMembers?: number | null };

export type CreateSessionMutationVariables = Exact<{
  data: SessionCreateInput;
}>;


export type CreateSessionMutation = { __typename?: 'Mutation', createSession?: { __typename?: 'Session', id: string } | null };

export type GetCoachQueryVariables = Exact<{
  where: CoachWhereUniqueInput;
}>;


export type GetCoachQuery = { __typename?: 'Query', getCoach?: { __typename: 'Coach', id: string, displayName?: string | null, handle?: string | null, trainingSchool?: string | null, headline?: string | null, bio?: string | null, coachingSince?: any | null, ycmbUrl?: string | null, createdAt: any, published?: boolean | null, position?: number | null, avatarKey?: string | null, user?: { __typename?: 'User', id: string, email: any, firstName: string, lastName: string, avatarKey?: string | null } | null, categories?: Array<{ __typename?: 'CoachCategory', id: string, label: string, value: string }> | null, specialisms?: Array<{ __typename?: 'CoachSpecialism', id: string, label: string, value: string }> | null, audienceExperiences?: Array<{ __typename?: 'CoachAudienceExperience', id: string, label: string, value: string }> | null, approaches?: Array<{ __typename?: 'CoachApproach', id: string, label: string, value: string }> | null } | null };

export type GetCompanyQueryVariables = Exact<{
  where: CompanyWhereUniqueInput;
}>;


export type GetCompanyQuery = { __typename?: 'Query', getCompany?: { __typename: 'Company', id: string, name: string, accountType: AccountType, logoKey?: string | null, reportUrl?: string | null, createdAt: any, updatedAt: any, companyAdmin?: { __typename: 'CompanyAdmin', id: string, subscriptionActive?: boolean | null, chargebeeSubscriptionId?: string | null, chargebeeCustomerId?: string | null, chargebeeSubscriptionStatus?: string | null, chargebeeTotalSeats: number, chargebeeCancelledAt?: any | null, chargebeeCancelScheduleCreatedAt?: any | null, chargebeeCurrentTermEnd?: any | null, chargebeeCurrentTermStart?: any | null, createdAt: any, updatedAt: any } | null } | null };

export type GetCompanyAdminQueryVariables = Exact<{
  where: CompanyAdminWhereUniqueInput;
}>;


export type GetCompanyAdminQuery = { __typename?: 'Query', getCompanyAdmin?: { __typename: 'CompanyAdmin', id: string, subscriptionActive?: boolean | null, chargebeeSubscriptionId?: string | null, chargebeeCustomerId?: string | null, chargebeeSubscriptionStatus?: string | null, chargebeeTotalSeats: number, chargebeeCancelledAt?: any | null, chargebeeCancelScheduleCreatedAt?: any | null, chargebeeCurrentTermEnd?: any | null, chargebeeCurrentTermStart?: any | null, createdAt: any, updatedAt: any, company?: { __typename: 'Company', id: string, name: string, accountType: AccountType, logoKey?: string | null, reportUrl?: string | null, createdAt: any, updatedAt: any } | null } | null };

export type GetHostedPageMutationVariables = Exact<{
  userId: Scalars['String'];
  itemPriceId: Scalars['String'];
  companyId: Scalars['String'];
}>;


export type GetHostedPageMutation = { __typename?: 'Mutation', getHostedPage: { __typename?: 'HostedPageType', id: string, type: string, url: string, state: string, failureReason?: string | null, passThruContent?: string | null, embed: boolean, createdAt: number, expiresAt: number, updatedAt: number, resourceVersion: string, checkoutInfo?: string | null, object?: string | null } };

export type GetPortalPageMutationVariables = Exact<{
  companyId: Scalars['String'];
}>;


export type GetPortalPageMutation = { __typename?: 'Mutation', getPortalPage: { __typename?: 'PortalPageType', id?: string | null, token?: string | null, accessUrl?: string | null, status?: string | null, createdAt?: number | null, customerId?: string | null } };

export type InviteTeamMembersMutationVariables = Exact<{
  input: InviteTeamMembersInput;
}>;


export type InviteTeamMembersMutation = { __typename?: 'Mutation', inviteTeamMembers: { __typename?: 'InviteTeamMembersResponse', success?: Array<{ __typename?: 'TeamMember', id: string }> | null, failed?: Array<{ __typename?: 'FailedInviteTeamMemberResponse', reason: string, teamMemberEmail: string }> | null } };

export type ListCoachAudienceApproachesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListCoachAudienceApproachesQuery = { __typename?: 'Query', listCoachApproaches?: Array<{ __typename?: 'CoachApproach', id: string, label: string, value: string } | null> | null };

export type ListCoachAudienceExperiencesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListCoachAudienceExperiencesQuery = { __typename?: 'Query', listCoachAudienceExperiences?: Array<{ __typename?: 'CoachAudienceExperience', id: string, label: string, value: string } | null> | null };

export type ListCoachCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListCoachCategoriesQuery = { __typename?: 'Query', listCoachCategories?: Array<{ __typename?: 'CoachCategory', id: string, label: string, value: string } | null> | null };

export type ListCoachSpecialismsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListCoachSpecialismsQuery = { __typename?: 'Query', listCoachSpecialisms?: Array<{ __typename?: 'CoachSpecialism', id: string, label: string, value: string } | null> | null };

export type ListCoachesQueryVariables = Exact<{
  where?: InputMaybe<CoachWhereInput>;
  orderBy?: InputMaybe<Array<InputMaybe<CoachOrderByInput>> | InputMaybe<CoachOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
}>;


export type ListCoachesQuery = { __typename?: 'Query', listCoaches?: Array<{ __typename?: 'Coach', id: string, displayName?: string | null, trainingSchool?: string | null, headline?: string | null, bio?: string | null, coachingSince?: any | null, ycmbUrl?: string | null, createdAt: any, published?: boolean | null, handle?: string | null, position?: number | null, avatarKey?: string | null, user?: { __typename?: 'User', id: string, firstName: string, lastName: string, avatarKey?: string | null } | null, categories?: Array<{ __typename?: 'CoachCategory', id: string, label: string, value: string }> | null, specialisms?: Array<{ __typename?: 'CoachSpecialism', id: string, label: string, value: string }> | null, audienceExperiences?: Array<{ __typename?: 'CoachAudienceExperience', id: string, label: string, value: string }> | null, approaches?: Array<{ __typename?: 'CoachApproach', id: string, label: string, value: string }> | null } | null> | null };

export type ListSessionsQueryVariables = Exact<{
  where?: InputMaybe<SessionWhereInput>;
  orderBy?: InputMaybe<Array<InputMaybe<SessionOrderByInput>> | InputMaybe<SessionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
}>;


export type ListSessionsQuery = { __typename?: 'Query', listSessions?: Array<{ __typename?: 'Session', id: string, startsAt?: any | null, endsAt?: any | null, state: SessionState, cancelledAt?: any | null, coach?: { __typename?: 'Coach', id: string, displayName?: string | null } | null, teamMember?: { __typename?: 'TeamMember', id: string } | null } | null> | null };

export type ListTeamMembersQueryVariables = Exact<{
  where?: InputMaybe<TeamMemberWhereInput>;
  orderBy?: InputMaybe<Array<InputMaybe<TeamMemberOrderByInput>> | InputMaybe<TeamMemberOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
}>;


export type ListTeamMembersQuery = { __typename?: 'Query', listTeamMembers?: Array<{ __typename?: 'TeamMember', id: string, isManager?: boolean | null, jobTitle?: string | null, createdAt: any, updatedAt: any, companyId?: string | null, user?: { __typename?: 'User', id: string, role: UserRole, isImpersonating: boolean, email: any, firstName: string, lastName: string, avatarKey?: string | null, isActive: boolean, activationState: ActivationState, createdAt: any, updatedAt: any } | null } | null> | null };

export type RemoveTeamMemberMutationVariables = Exact<{
  teamMemberId: Scalars['String'];
}>;


export type RemoveTeamMemberMutation = { __typename?: 'Mutation', removeTeamMember: boolean };

export type ResendTeamMemberInviteMutationVariables = Exact<{
  teamMemberId: Scalars['String'];
}>;


export type ResendTeamMemberInviteMutation = { __typename?: 'Mutation', resendTeamMemberInvite: { __typename?: 'TeamMember', id: string } };

export type SearchCoachesQueryVariables = Exact<{
  where?: InputMaybe<CoachWhereInput>;
  availability?: InputMaybe<CoachAvailabilityInput>;
  orderBy?: InputMaybe<Array<InputMaybe<CoachOrderByInput>> | InputMaybe<CoachOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
}>;


export type SearchCoachesQuery = { __typename?: 'Query', searchCoaches?: Array<{ __typename?: 'Coach', id: string, displayName?: string | null, trainingSchool?: string | null, headline?: string | null, bio?: string | null, coachingSince?: any | null, ycmbUrl?: string | null, createdAt: any, published?: boolean | null, handle?: string | null, position?: number | null, avatarKey?: string | null, categories?: Array<{ __typename?: 'CoachCategory', label: string }> | null, specialisms?: Array<{ __typename?: 'CoachSpecialism', label: string }> | null, audienceExperiences?: Array<{ __typename?: 'CoachAudienceExperience', label: string }> | null, approaches?: Array<{ __typename?: 'CoachApproach', label: string }> | null } | null> | null };

export type UpdateCompanyMutationVariables = Exact<{
  where: CompanyWhereUniqueInput;
  data: CompanyUpdateInput;
}>;


export type UpdateCompanyMutation = { __typename?: 'Mutation', updateCompany?: { __typename: 'Company', id: string, name: string, accountType: AccountType, reportUrl?: string | null, logoKey?: string | null, createdAt: any, updatedAt: any } | null };

export type UpdateCompanyAdminMutationVariables = Exact<{
  where: CompanyAdminWhereUniqueInput;
  data: CompanyAdminUpdateInput;
}>;


export type UpdateCompanyAdminMutation = { __typename?: 'Mutation', updateCompanyAdmin?: { __typename: 'CompanyAdmin', id: string, subscriptionActive?: boolean | null } | null };

export type UpdateCompanySponsorMutationVariables = Exact<{
  companyId: Scalars['String'];
  userId: Scalars['String'];
}>;


export type UpdateCompanySponsorMutation = { __typename?: 'Mutation', updateCompanySponsor?: { __typename?: 'Company', id: string } | null };

export type UpdateTeamMemberMutationVariables = Exact<{
  where: TeamMemberWhereUniqueInput;
  data: TeamMemberUpdateInput;
}>;


export type UpdateTeamMemberMutation = { __typename?: 'Mutation', updateTeamMember?: { __typename: 'TeamMember', id: string, isManager?: boolean | null, jobTitle?: string | null, companyId?: string | null, createdAt: any, updatedAt: any, user?: { __typename?: 'User', id: string } | null } | null };

export type UpdateUserMutationVariables = Exact<{
  where: UserWhereUniqueInput;
  data: UserUpdateInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser?: { __typename: 'User', id: string, cognitoId?: string | null, role: UserRole, isImpersonating: boolean, email: any, firstName: string, lastName: string, avatarKey?: string | null, isActive: boolean, createdAt: any, updatedAt: any } | null };

export type WhoAmIQueryVariables = Exact<{
  cognitoId: Scalars['String'];
}>;


export type WhoAmIQuery = { __typename?: 'Query', getUser?: { __typename: 'User', id: string, cognitoId?: string | null, role: UserRole, isImpersonating: boolean, email: any, firstName: string, lastName: string, avatarKey?: string | null, isActive: boolean, activationState: ActivationState, createdAt: any, updatedAt: any, teamMember?: { __typename: 'TeamMember', id: string, jobTitle?: string | null, isManager?: boolean | null, createdAt: any, updatedAt: any, companyId?: string | null } | null } | null };


export const AdminImpersonateCompanyDocument = `
    mutation AdminImpersonateCompany($userId: String!, $companyId: String!) {
  adminImpersonateCompany(userId: $userId, companyId: $companyId) {
    success
    companyId
  }
}
    `;
export const useAdminImpersonateCompanyMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AdminImpersonateCompanyMutation, TError, AdminImpersonateCompanyMutationVariables, TContext>) =>
    useMutation<AdminImpersonateCompanyMutation, TError, AdminImpersonateCompanyMutationVariables, TContext>(
      ['AdminImpersonateCompany'],
      (variables?: AdminImpersonateCompanyMutationVariables) => fetchData<AdminImpersonateCompanyMutation, AdminImpersonateCompanyMutationVariables>(AdminImpersonateCompanyDocument, variables)(),
      options
    );
export const CoachAvailabilityDocument = `
    query CoachAvailability($coachId: String!) {
  coachAvailability(coachId: $coachId) {
    id
    eventId
    status
    startsAt
    endsAt
    timeZone
    iCalUID
    recurringEventId
  }
}
    `;
export const useCoachAvailabilityQuery = <
      TData = CoachAvailabilityQuery,
      TError = unknown
    >(
      variables: CoachAvailabilityQueryVariables,
      options?: UseQueryOptions<CoachAvailabilityQuery, TError, TData>
    ) =>
    useQuery<CoachAvailabilityQuery, TError, TData>(
      ['CoachAvailability', variables],
      fetchData<CoachAvailabilityQuery, CoachAvailabilityQueryVariables>(CoachAvailabilityDocument, variables),
      options
    );
export const CountCoachesDocument = `
    query countCoaches($where: CoachWhereInput, $orderBy: [CoachOrderByInput], $skip: Int, $take: Int) {
  countCoaches(where: $where, orderBy: $orderBy, skip: $skip, take: $take)
}
    `;
export const useCountCoachesQuery = <
      TData = CountCoachesQuery,
      TError = unknown
    >(
      variables?: CountCoachesQueryVariables,
      options?: UseQueryOptions<CountCoachesQuery, TError, TData>
    ) =>
    useQuery<CountCoachesQuery, TError, TData>(
      variables === undefined ? ['countCoaches'] : ['countCoaches', variables],
      fetchData<CountCoachesQuery, CountCoachesQueryVariables>(CountCoachesDocument, variables),
      options
    );
export const CountTeamMembersDocument = `
    query countTeamMembers($where: TeamMemberWhereInput, $orderBy: [TeamMemberOrderByInput], $skip: Int, $take: Int) {
  countTeamMembers(where: $where, orderBy: $orderBy, skip: $skip, take: $take)
}
    `;
export const useCountTeamMembersQuery = <
      TData = CountTeamMembersQuery,
      TError = unknown
    >(
      variables?: CountTeamMembersQueryVariables,
      options?: UseQueryOptions<CountTeamMembersQuery, TError, TData>
    ) =>
    useQuery<CountTeamMembersQuery, TError, TData>(
      variables === undefined ? ['countTeamMembers'] : ['countTeamMembers', variables],
      fetchData<CountTeamMembersQuery, CountTeamMembersQueryVariables>(CountTeamMembersDocument, variables),
      options
    );
export const CreateSessionDocument = `
    mutation CreateSession($data: SessionCreateInput!) {
  createSession(data: $data) {
    id
  }
}
    `;
export const useCreateSessionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateSessionMutation, TError, CreateSessionMutationVariables, TContext>) =>
    useMutation<CreateSessionMutation, TError, CreateSessionMutationVariables, TContext>(
      ['CreateSession'],
      (variables?: CreateSessionMutationVariables) => fetchData<CreateSessionMutation, CreateSessionMutationVariables>(CreateSessionDocument, variables)(),
      options
    );
export const GetCoachDocument = `
    query GetCoach($where: CoachWhereUniqueInput!) {
  getCoach(where: $where) {
    __typename
    id
    displayName
    user {
      id
      email
      firstName
      lastName
    }
    handle
    trainingSchool
    headline
    bio
    coachingSince
    ycmbUrl
    createdAt
    published
    position
    avatarKey
    user {
      id
      firstName
      lastName
      avatarKey
    }
    categories {
      id
      label
      value
    }
    specialisms {
      id
      label
      value
    }
    audienceExperiences {
      id
      label
      value
    }
    approaches {
      id
      label
      value
    }
  }
}
    `;
export const useGetCoachQuery = <
      TData = GetCoachQuery,
      TError = unknown
    >(
      variables: GetCoachQueryVariables,
      options?: UseQueryOptions<GetCoachQuery, TError, TData>
    ) =>
    useQuery<GetCoachQuery, TError, TData>(
      ['GetCoach', variables],
      fetchData<GetCoachQuery, GetCoachQueryVariables>(GetCoachDocument, variables),
      options
    );
export const GetCompanyDocument = `
    query GetCompany($where: CompanyWhereUniqueInput!) {
  getCompany(where: $where) {
    __typename
    id
    name
    accountType
    logoKey
    reportUrl
    createdAt
    updatedAt
    companyAdmin {
      __typename
      id
      subscriptionActive
      chargebeeSubscriptionId
      chargebeeCustomerId
      chargebeeSubscriptionStatus
      chargebeeTotalSeats
      chargebeeCancelledAt
      chargebeeCancelScheduleCreatedAt
      chargebeeCurrentTermEnd
      chargebeeCurrentTermStart
      createdAt
      updatedAt
    }
  }
}
    `;
export const useGetCompanyQuery = <
      TData = GetCompanyQuery,
      TError = unknown
    >(
      variables: GetCompanyQueryVariables,
      options?: UseQueryOptions<GetCompanyQuery, TError, TData>
    ) =>
    useQuery<GetCompanyQuery, TError, TData>(
      ['GetCompany', variables],
      fetchData<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, variables),
      options
    );
export const GetCompanyAdminDocument = `
    query GetCompanyAdmin($where: CompanyAdminWhereUniqueInput!) {
  getCompanyAdmin(where: $where) {
    __typename
    id
    subscriptionActive
    chargebeeSubscriptionId
    chargebeeCustomerId
    chargebeeSubscriptionStatus
    chargebeeTotalSeats
    chargebeeCancelledAt
    chargebeeCancelScheduleCreatedAt
    chargebeeCurrentTermEnd
    chargebeeCurrentTermStart
    createdAt
    updatedAt
    company {
      __typename
      id
      name
      accountType
      logoKey
      reportUrl
      createdAt
      updatedAt
    }
  }
}
    `;
export const useGetCompanyAdminQuery = <
      TData = GetCompanyAdminQuery,
      TError = unknown
    >(
      variables: GetCompanyAdminQueryVariables,
      options?: UseQueryOptions<GetCompanyAdminQuery, TError, TData>
    ) =>
    useQuery<GetCompanyAdminQuery, TError, TData>(
      ['GetCompanyAdmin', variables],
      fetchData<GetCompanyAdminQuery, GetCompanyAdminQueryVariables>(GetCompanyAdminDocument, variables),
      options
    );
export const GetHostedPageDocument = `
    mutation getHostedPage($userId: String!, $itemPriceId: String!, $companyId: String!) {
  getHostedPage(userId: $userId, itemPriceId: $itemPriceId, companyId: $companyId) {
    id
    type
    url
    state
    failureReason
    passThruContent
    embed
    createdAt
    expiresAt
    updatedAt
    resourceVersion
    checkoutInfo
    object
  }
}
    `;
export const useGetHostedPageMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<GetHostedPageMutation, TError, GetHostedPageMutationVariables, TContext>) =>
    useMutation<GetHostedPageMutation, TError, GetHostedPageMutationVariables, TContext>(
      ['getHostedPage'],
      (variables?: GetHostedPageMutationVariables) => fetchData<GetHostedPageMutation, GetHostedPageMutationVariables>(GetHostedPageDocument, variables)(),
      options
    );
export const GetPortalPageDocument = `
    mutation getPortalPage($companyId: String!) {
  getPortalPage(companyId: $companyId) {
    id
    token
    accessUrl
    status
    createdAt
    customerId
  }
}
    `;
export const useGetPortalPageMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<GetPortalPageMutation, TError, GetPortalPageMutationVariables, TContext>) =>
    useMutation<GetPortalPageMutation, TError, GetPortalPageMutationVariables, TContext>(
      ['getPortalPage'],
      (variables?: GetPortalPageMutationVariables) => fetchData<GetPortalPageMutation, GetPortalPageMutationVariables>(GetPortalPageDocument, variables)(),
      options
    );
export const InviteTeamMembersDocument = `
    mutation inviteTeamMembers($input: InviteTeamMembersInput!) {
  inviteTeamMembers(input: $input) {
    success {
      id
    }
    failed {
      reason
      teamMemberEmail
    }
  }
}
    `;
export const useInviteTeamMembersMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<InviteTeamMembersMutation, TError, InviteTeamMembersMutationVariables, TContext>) =>
    useMutation<InviteTeamMembersMutation, TError, InviteTeamMembersMutationVariables, TContext>(
      ['inviteTeamMembers'],
      (variables?: InviteTeamMembersMutationVariables) => fetchData<InviteTeamMembersMutation, InviteTeamMembersMutationVariables>(InviteTeamMembersDocument, variables)(),
      options
    );
export const ListCoachAudienceApproachesDocument = `
    query ListCoachAudienceApproaches {
  listCoachApproaches {
    id
    label
    value
  }
}
    `;
export const useListCoachAudienceApproachesQuery = <
      TData = ListCoachAudienceApproachesQuery,
      TError = unknown
    >(
      variables?: ListCoachAudienceApproachesQueryVariables,
      options?: UseQueryOptions<ListCoachAudienceApproachesQuery, TError, TData>
    ) =>
    useQuery<ListCoachAudienceApproachesQuery, TError, TData>(
      variables === undefined ? ['ListCoachAudienceApproaches'] : ['ListCoachAudienceApproaches', variables],
      fetchData<ListCoachAudienceApproachesQuery, ListCoachAudienceApproachesQueryVariables>(ListCoachAudienceApproachesDocument, variables),
      options
    );
export const ListCoachAudienceExperiencesDocument = `
    query ListCoachAudienceExperiences {
  listCoachAudienceExperiences {
    id
    label
    value
  }
}
    `;
export const useListCoachAudienceExperiencesQuery = <
      TData = ListCoachAudienceExperiencesQuery,
      TError = unknown
    >(
      variables?: ListCoachAudienceExperiencesQueryVariables,
      options?: UseQueryOptions<ListCoachAudienceExperiencesQuery, TError, TData>
    ) =>
    useQuery<ListCoachAudienceExperiencesQuery, TError, TData>(
      variables === undefined ? ['ListCoachAudienceExperiences'] : ['ListCoachAudienceExperiences', variables],
      fetchData<ListCoachAudienceExperiencesQuery, ListCoachAudienceExperiencesQueryVariables>(ListCoachAudienceExperiencesDocument, variables),
      options
    );
export const ListCoachCategoriesDocument = `
    query ListCoachCategories {
  listCoachCategories {
    id
    label
    value
  }
}
    `;
export const useListCoachCategoriesQuery = <
      TData = ListCoachCategoriesQuery,
      TError = unknown
    >(
      variables?: ListCoachCategoriesQueryVariables,
      options?: UseQueryOptions<ListCoachCategoriesQuery, TError, TData>
    ) =>
    useQuery<ListCoachCategoriesQuery, TError, TData>(
      variables === undefined ? ['ListCoachCategories'] : ['ListCoachCategories', variables],
      fetchData<ListCoachCategoriesQuery, ListCoachCategoriesQueryVariables>(ListCoachCategoriesDocument, variables),
      options
    );
export const ListCoachSpecialismsDocument = `
    query ListCoachSpecialisms {
  listCoachSpecialisms {
    id
    label
    value
  }
}
    `;
export const useListCoachSpecialismsQuery = <
      TData = ListCoachSpecialismsQuery,
      TError = unknown
    >(
      variables?: ListCoachSpecialismsQueryVariables,
      options?: UseQueryOptions<ListCoachSpecialismsQuery, TError, TData>
    ) =>
    useQuery<ListCoachSpecialismsQuery, TError, TData>(
      variables === undefined ? ['ListCoachSpecialisms'] : ['ListCoachSpecialisms', variables],
      fetchData<ListCoachSpecialismsQuery, ListCoachSpecialismsQueryVariables>(ListCoachSpecialismsDocument, variables),
      options
    );
export const ListCoachesDocument = `
    query ListCoaches($where: CoachWhereInput, $orderBy: [CoachOrderByInput], $skip: Int, $take: Int) {
  listCoaches(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
    id
    displayName
    trainingSchool
    headline
    bio
    coachingSince
    ycmbUrl
    createdAt
    published
    handle
    position
    avatarKey
    user {
      id
      firstName
      lastName
      avatarKey
    }
    categories {
      id
      label
      value
    }
    specialisms {
      id
      label
      value
    }
    audienceExperiences {
      id
      label
      value
    }
    approaches {
      id
      label
      value
    }
  }
}
    `;
export const useListCoachesQuery = <
      TData = ListCoachesQuery,
      TError = unknown
    >(
      variables?: ListCoachesQueryVariables,
      options?: UseQueryOptions<ListCoachesQuery, TError, TData>
    ) =>
    useQuery<ListCoachesQuery, TError, TData>(
      variables === undefined ? ['ListCoaches'] : ['ListCoaches', variables],
      fetchData<ListCoachesQuery, ListCoachesQueryVariables>(ListCoachesDocument, variables),
      options
    );
export const ListSessionsDocument = `
    query listSessions($where: SessionWhereInput, $orderBy: [SessionOrderByInput], $skip: Int, $take: Int) {
  listSessions(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
    id
    startsAt
    endsAt
    state
    cancelledAt
    coach {
      id
      displayName
    }
    teamMember {
      id
    }
  }
}
    `;
export const useListSessionsQuery = <
      TData = ListSessionsQuery,
      TError = unknown
    >(
      variables?: ListSessionsQueryVariables,
      options?: UseQueryOptions<ListSessionsQuery, TError, TData>
    ) =>
    useQuery<ListSessionsQuery, TError, TData>(
      variables === undefined ? ['listSessions'] : ['listSessions', variables],
      fetchData<ListSessionsQuery, ListSessionsQueryVariables>(ListSessionsDocument, variables),
      options
    );
export const ListTeamMembersDocument = `
    query listTeamMembers($where: TeamMemberWhereInput, $orderBy: [TeamMemberOrderByInput], $skip: Int, $take: Int) {
  listTeamMembers(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
    id
    isManager
    jobTitle
    createdAt
    updatedAt
    companyId
    user {
      id
      role
      isImpersonating
      email
      firstName
      lastName
      avatarKey
      isActive
      activationState
      createdAt
      updatedAt
    }
  }
}
    `;
export const useListTeamMembersQuery = <
      TData = ListTeamMembersQuery,
      TError = unknown
    >(
      variables?: ListTeamMembersQueryVariables,
      options?: UseQueryOptions<ListTeamMembersQuery, TError, TData>
    ) =>
    useQuery<ListTeamMembersQuery, TError, TData>(
      variables === undefined ? ['listTeamMembers'] : ['listTeamMembers', variables],
      fetchData<ListTeamMembersQuery, ListTeamMembersQueryVariables>(ListTeamMembersDocument, variables),
      options
    );
export const RemoveTeamMemberDocument = `
    mutation removeTeamMember($teamMemberId: String!) {
  removeTeamMember(teamMemberId: $teamMemberId)
}
    `;
export const useRemoveTeamMemberMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RemoveTeamMemberMutation, TError, RemoveTeamMemberMutationVariables, TContext>) =>
    useMutation<RemoveTeamMemberMutation, TError, RemoveTeamMemberMutationVariables, TContext>(
      ['removeTeamMember'],
      (variables?: RemoveTeamMemberMutationVariables) => fetchData<RemoveTeamMemberMutation, RemoveTeamMemberMutationVariables>(RemoveTeamMemberDocument, variables)(),
      options
    );
export const ResendTeamMemberInviteDocument = `
    mutation resendTeamMemberInvite($teamMemberId: String!) {
  resendTeamMemberInvite(teamMemberId: $teamMemberId) {
    id
  }
}
    `;
export const useResendTeamMemberInviteMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ResendTeamMemberInviteMutation, TError, ResendTeamMemberInviteMutationVariables, TContext>) =>
    useMutation<ResendTeamMemberInviteMutation, TError, ResendTeamMemberInviteMutationVariables, TContext>(
      ['resendTeamMemberInvite'],
      (variables?: ResendTeamMemberInviteMutationVariables) => fetchData<ResendTeamMemberInviteMutation, ResendTeamMemberInviteMutationVariables>(ResendTeamMemberInviteDocument, variables)(),
      options
    );
export const SearchCoachesDocument = `
    query SearchCoaches($where: CoachWhereInput, $availability: CoachAvailabilityInput, $orderBy: [CoachOrderByInput], $skip: Int, $take: Int) {
  searchCoaches(
    where: $where
    availability: $availability
    orderBy: $orderBy
    skip: $skip
    take: $take
  ) {
    id
    displayName
    trainingSchool
    headline
    bio
    coachingSince
    ycmbUrl
    createdAt
    published
    handle
    position
    avatarKey
    categories {
      label
    }
    specialisms {
      label
    }
    audienceExperiences {
      label
    }
    approaches {
      label
    }
  }
}
    `;
export const useSearchCoachesQuery = <
      TData = SearchCoachesQuery,
      TError = unknown
    >(
      variables?: SearchCoachesQueryVariables,
      options?: UseQueryOptions<SearchCoachesQuery, TError, TData>
    ) =>
    useQuery<SearchCoachesQuery, TError, TData>(
      variables === undefined ? ['SearchCoaches'] : ['SearchCoaches', variables],
      fetchData<SearchCoachesQuery, SearchCoachesQueryVariables>(SearchCoachesDocument, variables),
      options
    );
export const UpdateCompanyDocument = `
    mutation UpdateCompany($where: CompanyWhereUniqueInput!, $data: CompanyUpdateInput!) {
  updateCompany(where: $where, data: $data) {
    __typename
    id
    name
    accountType
    reportUrl
    logoKey
    createdAt
    updatedAt
  }
}
    `;
export const useUpdateCompanyMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateCompanyMutation, TError, UpdateCompanyMutationVariables, TContext>) =>
    useMutation<UpdateCompanyMutation, TError, UpdateCompanyMutationVariables, TContext>(
      ['UpdateCompany'],
      (variables?: UpdateCompanyMutationVariables) => fetchData<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, variables)(),
      options
    );
export const UpdateCompanyAdminDocument = `
    mutation UpdateCompanyAdmin($where: CompanyAdminWhereUniqueInput!, $data: CompanyAdminUpdateInput!) {
  updateCompanyAdmin(where: $where, data: $data) {
    __typename
    id
    subscriptionActive
  }
}
    `;
export const useUpdateCompanyAdminMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateCompanyAdminMutation, TError, UpdateCompanyAdminMutationVariables, TContext>) =>
    useMutation<UpdateCompanyAdminMutation, TError, UpdateCompanyAdminMutationVariables, TContext>(
      ['UpdateCompanyAdmin'],
      (variables?: UpdateCompanyAdminMutationVariables) => fetchData<UpdateCompanyAdminMutation, UpdateCompanyAdminMutationVariables>(UpdateCompanyAdminDocument, variables)(),
      options
    );
export const UpdateCompanySponsorDocument = `
    mutation updateCompanySponsor($companyId: String!, $userId: String!) {
  updateCompanySponsor(companyId: $companyId, userId: $userId) {
    id
  }
}
    `;
export const useUpdateCompanySponsorMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateCompanySponsorMutation, TError, UpdateCompanySponsorMutationVariables, TContext>) =>
    useMutation<UpdateCompanySponsorMutation, TError, UpdateCompanySponsorMutationVariables, TContext>(
      ['updateCompanySponsor'],
      (variables?: UpdateCompanySponsorMutationVariables) => fetchData<UpdateCompanySponsorMutation, UpdateCompanySponsorMutationVariables>(UpdateCompanySponsorDocument, variables)(),
      options
    );
export const UpdateTeamMemberDocument = `
    mutation UpdateTeamMember($where: TeamMemberWhereUniqueInput!, $data: TeamMemberUpdateInput!) {
  updateTeamMember(where: $where, data: $data) {
    __typename
    id
    isManager
    jobTitle
    companyId
    createdAt
    updatedAt
    user {
      id
    }
  }
}
    `;
export const useUpdateTeamMemberMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateTeamMemberMutation, TError, UpdateTeamMemberMutationVariables, TContext>) =>
    useMutation<UpdateTeamMemberMutation, TError, UpdateTeamMemberMutationVariables, TContext>(
      ['UpdateTeamMember'],
      (variables?: UpdateTeamMemberMutationVariables) => fetchData<UpdateTeamMemberMutation, UpdateTeamMemberMutationVariables>(UpdateTeamMemberDocument, variables)(),
      options
    );
export const UpdateUserDocument = `
    mutation UpdateUser($where: UserWhereUniqueInput!, $data: UserUpdateInput!) {
  updateUser(where: $where, data: $data) {
    __typename
    id
    cognitoId
    role
    isImpersonating
    email
    firstName
    lastName
    avatarKey
    isActive
    createdAt
    updatedAt
  }
}
    `;
export const useUpdateUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>) =>
    useMutation<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>(
      ['UpdateUser'],
      (variables?: UpdateUserMutationVariables) => fetchData<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, variables)(),
      options
    );
export const WhoAmIDocument = `
    query WhoAmI($cognitoId: String!) {
  getUser(where: {cognitoId: $cognitoId}) {
    __typename
    id
    cognitoId
    role
    isImpersonating
    email
    firstName
    lastName
    avatarKey
    isActive
    activationState
    createdAt
    updatedAt
    teamMember {
      __typename
      id
      jobTitle
      isManager
      createdAt
      updatedAt
      companyId
    }
  }
}
    `;
export const useWhoAmIQuery = <
      TData = WhoAmIQuery,
      TError = unknown
    >(
      variables: WhoAmIQueryVariables,
      options?: UseQueryOptions<WhoAmIQuery, TError, TData>
    ) =>
    useQuery<WhoAmIQuery, TError, TData>(
      ['WhoAmI', variables],
      fetchData<WhoAmIQuery, WhoAmIQueryVariables>(WhoAmIDocument, variables),
      options
    );