import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUser } from '@/state/user';

import { UserRole } from '@/graphql/types.generated';

export const useRoleRedirect = (roles: UserRole[]) => {
  const user = useUser();

  const navigate = useNavigate();

  const userRole = user.currentUser?.role;

  useEffect(() => {
    if (userRole === UserRole.Superadmin) return;

    if (userRole && !roles.includes(userRole)) {
      return navigate('/');
    }
  }, [userRole, roles]);

  return true;
};
