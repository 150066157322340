import ReactDOM from 'react-dom';
import Auth from '@aws-amplify/auth';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { App } from '@/App';
import { reportWebVitals } from '@/reportWebVitals';

import { awsAuthConfig } from '@/configs/aws';

import '@/styles/global.less';
import 'react-notion/src/styles.css';

if (process.env.REACT_APP_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_ENV,
  });
}

if (window) {
  (window as any).Chargebee.init({
    site: process.env.REACT_APP_CHARGEBEE_SITE,
    publishableKey: process.env.REACT_APP_CHARGEBEE_PUBLISHABLE_KEY,
  });
}

// @ts-ignore
Auth.configure(awsAuthConfig);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
