import { Layout, PageHeader, Row, Col, Typography } from 'antd';

import { PageHeaderBlock } from '@/components/PageHeaderBlock';
import { HtmlMeta } from '@/components/HtmlMeta';
import { ActionTile } from '@/components/ActionTile';
import { ROUTE_PATHS } from '@/configs/routes';
import { useUser } from '@/state/user';
import { theme } from '@/configs/theme';
import { IPageBaseProps } from '@/interfaces';

interface IProps extends IPageBaseProps {}

export const SupportGuides: React.FC<IProps> = () => {
  const user = useUser();

  const sponsorItems = user.currentUser?.isAdmin
    ? [
        {
          id: 1,
          navTitle: 'Support Guides',
          title: `Complete our sponsor checklist.`,
          to: ROUTE_PATHS.SUPPORT_GUIDES.SPONSOR_CHECKLIST.MAIN,
          color: theme.colors.blue,
        },
      ]
    : [];

  const SESSION_NAVIGATION_ITEMS = [
    ...sponsorItems,
    {
      id: 2,
      navTitle: 'Support Guides',
      title: `What is coaching?`,
      href: 'https://midnight-revolve-64c.notion.site/What-is-coaching-877c000ec12645abba3101796bf65513',
      color: theme.colors.blue,
    },
    {
      id: 3,
      navTitle: 'Support Guides',
      title: `How to use More Happi.`,
      to: 'https://midnight-revolve-64c.notion.site/How-to-use-More-Happi-e17d42c0eb094e9abf78344e5bee0a46',
      color: theme.colors.blue,
    },
    {
      id: 4,
      navTitle: 'Support Guides',
      title: `How-to article series.`,
      href: 'https://intercom.help/more-happi/en/collections/3603450-resources',
      color: theme.colors.blue,
    },
    {
      id: 5,
      navTitle: 'Support Guides',
      title: `FAQs.`,
      href: 'https://intercom.help/more-happi/en/collections/2828356-faqs',
      color: theme.colors.blue,
    },
  ];

  const getHeadlineCopy = () => {
    if (
      user?.currentUser?.isAdmin ||
      user?.currentUser?.teamMember?.isManager
    ) {
      return (
        <>
          <Typography.Paragraph>
            Articles and resources to help you and your team get the most out of
            More Happi.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Can’t find what you’re looking for?{' '}
            <a href="mailto:hey@morehappi.com">Let us know.</a>
          </Typography.Paragraph>
        </>
      );
    }

    return (
      <>
        <Typography.Paragraph>
          Articles and resources to help you get the most out of More Happi.
        </Typography.Paragraph>
        <Typography.Paragraph>
          Can’t find what you’re looking for?{' '}
          <a href="mailto:hey@morehappi.com">Let us know</a>
        </Typography.Paragraph>
      </>
    );
  };

  return (
    <Layout>
      <HtmlMeta title="Support Guides" />

      <Layout.Content className="site-layout-content">
        <PageHeader title="Support Guides" ghost className="site-page-header">
          <PageHeaderBlock
            image={{
              src: '/images/Support_Guides.svg',
              alt: 'Book a session',
            }}
          >
            {getHeadlineCopy()}
          </PageHeaderBlock>
        </PageHeader>

        <Row
          className="mh-row-top-md"
          gutter={{ xs: 4, sm: 8, md: 16, lg: 24 }}
        >
          {SESSION_NAVIGATION_ITEMS.map((item: any) => (
            <Col
              className="mh-vertical-spacer"
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={6}
            >
              <ActionTile {...item} />
            </Col>
          ))}
        </Row>
      </Layout.Content>
    </Layout>
  );
};
