import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { Button, Col, Input, Layout, PageHeader, Pagination, Row } from 'antd'
import qs from 'query-string'
import { debounce } from 'lodash/fp'

import { useScrollTo } from '@/hooks'

import { HtmlMeta } from '@/components/HtmlMeta'

import { LoadingSpinner } from '@/components/LoadingSpinner'
import { CategoriesFilter } from '@/components/CoachFilters/Categories'
import { ApproachesFilter } from '@/components/CoachFilters/Approaches'
import { AudienceExperienceFilter } from '@/components/CoachFilters/AudienceExperience'
import { SpecialismsFilter } from '@/components/CoachFilters/Specialisms'

import { CoachCard } from '@/components/CoachCard'

import { CompanyFeaturePermissions } from '@/state/company'

import { usePermissionRedirect } from '@/hooks/usePermissionRedirect'

import { Mixpanel } from '@/utils/mixpanel'

import { IPageBaseProps } from '@/interfaces'

import { OrderByArg, useCountCoachesQuery, useSearchCoachesQuery } from '@/graphql/types.generated'

import styles from './styles.module.less'
import { app } from '@/configs/app.config'

interface IProps extends IPageBaseProps {
}

const allowableFilters = [
  'categories',
  'audienceExperiences',
  'specialisms',
  'approaches'
]

const getWhereCoachQuery = (filters: any): any => {
  const withFilters = Object.entries(filters).reduce(
    (where, [filterType, filterValue]: any) => {
      if (!allowableFilters.includes(filterType)) return where

      return {
        ...where,
        [filterType]: {
          some: {
            value: {
              in: Array.isArray(filterValue) ? filterValue:[filterValue]
            }
          }
        }
      }
    },
    {}
  )

  return {
    ...withFilters
  }
}

export const Coaches: React.FC<IProps> = () => {
  const location = useLocation()

  const query = qs.parse(location.search)

  useScrollTo()

  usePermissionRedirect(CompanyFeaturePermissions.coachDirectory)

  const [searchParams, setSearchParams] = useSearchParams()
  const [filters, setFilters] = useState(getWhereCoachQuery(query))
  const [searchQuery, setSearchQuery] = useState('')

  const [coachesPerPage, setCoachesPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(Number(query.page) || 1)

  const updateQuery = (q: string) => setSearchParams(q)

  const onResetFilters = () => {
    setSearchParams({})
    setFilters({})
    setSearchQuery('')
    setCurrentPage(1)
    updateQuery(qs.stringify({ page: 1 }))
  }

  const getSkip = () => {
    if (currentPage === 1) return 0
    if (currentPage === 2) return coachesPerPage

    return coachesPerPage * (currentPage - 1)
  }

  const whereVariables = {
    where: {
      published: {
        equals: true
      },
      ...filters,
      ...(searchQuery && { displayName: { contains: String(searchQuery) } })
    }
  }

  const searchCoachQuery = useSearchCoachesQuery({
    take: coachesPerPage,
    skip: getSkip(),
    orderBy: {
      position: OrderByArg.Asc
    },
    ...whereVariables
  })

  const countCoachQuery = useCountCoachesQuery({
    take: app.PAGINATION_COUNT_TAKE_DEFAULT,
    skip: 0,
    orderBy: {
      position: OrderByArg.Asc
    },
    ...whereVariables
  })

  const onUpdateFilters = () => {
    const updatedFilters = getWhereCoachQuery(qs.parse(location.search))

    setFilters(updatedFilters)
    searchCoachQuery.refetch()
  }

  useEffect(() => {
    onUpdateFilters()
  }, [searchQuery, searchParams, searchCoachQuery.data, setCurrentPage])

  const onSearch = debounce(400, (search: string) => {
    setSearchQuery(search)

    updateQuery(
      qs.stringify({
        ...query,
        page: currentPage,
        search
      })
    )

    Mixpanel.track('Coach Searched', {
      search
    })

    return searchCoachQuery.refetch()
  })

  const onChangeFilter = (filter: string) => (value: string) => {
    Mixpanel.track('Coach Filter Changed', {
      filter,
      value
    })

    return updateQuery(
      qs.stringify({
        ...query,
        page: currentPage,
        search: searchQuery,
        [filter]: value
      })
    )
  }

  const onChangePage = (page: number, pageSize: number) => {
    setCoachesPerPage(pageSize)
    setCurrentPage(page)

    return updateQuery(
      qs.stringify({
        ...query,
        search: searchQuery,
        page
      })
    )
  }

  const getSelectedFilters = (filterType: string) => {
    if (!filters[filterType]) return

    const selectedFilters = filters[filterType]

    return selectedFilters?.some?.value?.in
  }

  return (
    <Layout>
      <HtmlMeta title='Coaches' />

      <Layout.Content className='site-layout-content'>
        <PageHeader title='Find a coach' ghost className='site-page-header'>
          <Row gutter={12}>
            <Col xs={24} lg={6} className='filter-col'>
              <CategoriesFilter
                onChange={onChangeFilter('categories')}
                selected={getSelectedFilters('categories')}
              />
            </Col>
            <Col xs={24} lg={6} className='filter-col'>
              <AudienceExperienceFilter
                onChange={onChangeFilter('audienceExperiences')}
                selected={getSelectedFilters('audienceExperiences')}
              />
            </Col>
            <Col xs={24} lg={6} className='filter-col'>
              <SpecialismsFilter
                onChange={onChangeFilter('specialisms')}
                selected={getSelectedFilters('specialisms')}
              />
            </Col>
            <Col xs={24} lg={6} className='filter-col'>
              <ApproachesFilter
                onChange={onChangeFilter('approaches')}
                selected={getSelectedFilters('approaches')}
              />
            </Col>

            <Col span={24} sm={18} md={20} className='filter-col'>
              <Input.Search
                onChange={(e) => onSearch(e.target.value)}
                placeholder='Search for coaches'
                inputMode='search'
              />
            </Col>
            <Col span={24} sm={6} md={4} className='filter-col'>
              <Button block type='default' onClick={onResetFilters}>
                Reset all filters
              </Button>
            </Col>
          </Row>
        </PageHeader>

        <div className={styles['coach-results-container']}>
          {searchCoachQuery.isLoading || countCoachQuery.isLoading ? (
            <LoadingSpinner />
          ):(
            <div>
              {searchCoachQuery.data?.searchCoaches?.map((coach: any) => (
                <CoachCard key={coach?.id} coach={coach} />
              ))}
              <div className={styles['coach-results-pagination']}>
                <Pagination
                  defaultCurrent={currentPage}
                  defaultPageSize={coachesPerPage}
                  total={countCoachQuery.data?.countCoaches || 0}
                  onChange={onChangePage}
                  simple
                  showQuickJumper
                />
              </div>
            </div>
          )}
        </div>
      </Layout.Content>
    </Layout>
  )
}
