import { Layout, PageHeader } from 'antd';
import { useEffect } from 'react';

import { useNavigate } from 'react-router';

import { HtmlMeta } from '@/components/HtmlMeta';

import { isMobile } from 'react-device-detect';

import { openInNewTab } from '@/utils/helpers/app';

import { IPageBaseProps } from '@/interfaces';

interface IProps extends IPageBaseProps {}

export const SponsorChecklistTwo: React.FC<IProps> = () => {
  const navigate = useNavigate();

  useEffect(() => {
    openInNewTab(
      'https://midnight-revolve-64c.notion.site/2-Invite-your-team-to-the-platform-ca16dc3e117e41e1950c2be1818ca240',
    );

    if (isMobile) return;

    navigate(-1);
  }, []);

  return (
    <Layout>
      <HtmlMeta title="Onboarding Checklist - Invite your team to the platform" />
    </Layout>
  );
};
