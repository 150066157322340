import { Layout, PageHeader, Row, Col, Typography, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

import { HtmlMeta } from '@/components/HtmlMeta';
import { openInNewTab } from '@/utils/helpers/app';
import { Mixpanel } from '@/utils/mixpanel';
import { IPageBaseProps } from '@/interfaces';
import { ROUTE_PATHS } from '@/configs/routes';
import { BackIcon } from '@/icons';

interface IProps extends IPageBaseProps {}

export const OnboardingCallTeamMembers: React.FC<IProps> = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <HtmlMeta title="Live Onboarding Call" />
      <Layout.Content className="site-layout-content thin">
        <PageHeader
          title="Attend a live onboarding call"
          ghost
          className="site-page-header"
          onBack={() =>
            navigate(ROUTE_PATHS.SUPPORT_GUIDES.SPONSOR_CHECKLIST.MAIN)
          }
          backIcon={<BackIcon style={{ fontSize: '40px' }} />}
        />
        <Typography.Title level={4}>What’s this all about?</Typography.Title>

        <Typography.Paragraph>
          Whether you’re brand new to coaching, or already a super fan, we
          encourage everyone to join our onboarding sessions so that you know
          exactly what More Happi coaching is and how it can help you thrive.
          Sign up to the next available date today!
        </Typography.Paragraph>

        <Typography.Title level={4}>Intro to coaching</Typography.Title>

        <Typography.Title level={5}>What:</Typography.Title>

        <Typography.Paragraph>
          An introduction to More Happi and to coaching. Learn what it is (and
          what it isn’t), so that you can get the most out of coaching in your
          day-to-day. We’ll run through some use-cases and a handy tool to help
          you prepare for a session.
        </Typography.Paragraph>

        <Typography.Title level={5}>When:</Typography.Title>

        <Typography.Paragraph>
          Wednesdays, 1pm (twice a month)
        </Typography.Paragraph>

        <Typography.Title level={5}>How long:</Typography.Title>

        <Typography.Paragraph>40 minutes</Typography.Paragraph>

        <Typography.Title level={5}>Who is it for:</Typography.Title>

        <Typography.Paragraph>
          Anyone new to More Happi (or those in need of a recap)
        </Typography.Paragraph>

        <Button
          type="primary"
          size="large"
          onClick={() => {
            Mixpanel.track('Onboarding Call Team Members Registration Clicked');
            openInNewTab(
              'https://us06web.zoom.us/webinar/register/WN__TR97F5YQ1iMcceqCCKxaQ',
            );
          }}
        >
          Register Now
        </Button>
      </Layout.Content>
    </Layout>
  );
};
