import { useEffect, useState } from 'react';

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    // @ts-ignore
    setIsMobile(window?.innerWidth < 821);

    const updateMedia = () => {
      // @ts-ignore
      setIsMobile(window?.innerWidth < 821);
    };

    window?.addEventListener('resize', updateMedia);

    return () => window?.removeEventListener('resize', updateMedia);
  }, []);

  return isMobile;
};
