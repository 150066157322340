import { useNavigate } from 'react-router'
import { CalendarOutlined } from '@ant-design/icons'
import { decode } from 'html-entities'
import { addDays, isBefore } from 'date-fns'

import { Button } from 'antd'

import { CoachAvatar } from '../CoachAvatar'
import { CoachBio } from '../CoachBio'

import styles from './styles.module.less'

interface Props {
  coach: any;
}

interface CoachAvailability {
  eventsInThreeDays: boolean;
  eventsInNextWeek: boolean;
}

const getCoachAvailability = (events: any) => {
  const eventsInThreeDays =
    events?.filter((e: any) =>
      isBefore(new Date(e.startsAt), addDays(new Date(), 3))
    ).length >= 1

  const eventsInNextWeek =
    events?.filter((e: any) =>
      isBefore(new Date(e.startsAt), addDays(new Date(), 7))
    ).length >= 1

  return {
    eventsInThreeDays,
    eventsInNextWeek
  }
}

export const CoachCard = ({ coach }: Props) => {
  const navigate = useNavigate()

  // const availability: CoachAvailability = getCoachAvailability(coach?.events)

  return (
    <div className={styles['coach-card-container']}>
      <div className={styles['coach-image-container']}>
        <CoachAvatar
          imageKey={coach?.avatarKey || coach?.user?.avatarKey}
          displayName={coach?.displayName}
        />
        <div className={styles['coach-cta']}>
          <Button
            type='default'
            onClick={() => navigate(`/coach/${coach.handle}`)}
            icon={<CalendarOutlined />}
          >
            See availability & book
          </Button>
        </div>
      </div>
      <div>
        {/* {availability.eventsInNextWeek && (
          <Tag color="green" style={{ marginBottom: 8 }}>
            Available in next 7 days
          </Tag>
        )} */}
        <h2>{coach?.displayName}</h2>
        <h3>{decode(coach?.headline)}</h3>
        <div className={styles['coach-bio']}>
          <CoachBio bio={coach.bio} />
        </div>
        <div className={styles['coach-tags-container']}>
          <div className={styles['coach-tags-container']}>
            <h5 className={styles['coach-tag-header']}>Experience</h5>
            <span className={styles['coach-tag']}>
              Trained at {coach?.trainingSchool}
            </span>
          </div>
          <h5 className={styles['coach-tag-header']}>Specialisms</h5>
          <div className={styles['coach-tag-container']}>
            {coach?.specialisms?.map((specialism: { label: string }) => (
              <span className={styles['coach-tag']} key={specialism.label}>
                {specialism.label}
              </span>
            ))}
          </div>
          <h5 className={styles['coach-tag-header']}>Approach</h5>
          <div className={styles['coach-tag-container']}>
            {coach?.approaches?.map((approache: { label: string }) => (
              <span className={styles['coach-tag']} key={approache.label}>
                {approache.label}
              </span>
            ))}
          </div>
        </div>
        <div className={styles['coach-cta-mobile']}>
          <Button
            type='default'
            onClick={() => navigate(`/coach/${coach.handle}`)}
            icon={<CalendarOutlined />}
          >
            See availability & book
          </Button>
        </div>
      </div>
    </div>
  )
}
