import { FC } from 'react';
import { CurrentCompany } from '@/state/company';
import { Divider, Table, Typography } from 'antd';
import { pricingTierTableColumns } from '@/utils/table_data/pricingTierTableInfo';
import { getTablePricingData } from '@/utils/subscription';

const { Title, Text } = Typography;

export const PricingTable: FC<{ company: CurrentCompany }> = ({ company }) => (
  <>
    <Divider />

    <Text>
      You will be charged monthly for the number of humans you have added (plus
      VAT). You can add and remove members of your team as people leave or join.
      This will be reflected on your invoice, which is emailed directly to the
      company admin.
    </Text>

    <Table
      className="mt-xl"
      columns={pricingTierTableColumns}
      dataSource={getTablePricingData(company)}
      pagination={false}
    />

    <Divider />
  </>
);
